import * as feedbackApi from '@/api/feedback'

const feedback = {
  state: {
  },
  mutations: {
  },
  actions: {
    // Feedback
    getFeedbackData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        feedbackApi.getFeedbackData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    storeFeedback ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        feedbackApi.storeFeedback(createData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    updateFeedback ({ commit }, updateData) {
      return new Promise((resolve, reject) => {
        feedbackApi.updateFeedback(updateData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    storeFeedbackReply ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        feedbackApi.storeFeedbackReply(createData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    getFeedbackReplyData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        feedbackApi.getFeedbackReplyData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    // Feedback End
    // Operation
    getOperationData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        feedbackApi.getOperationData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    storeOperation ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        feedbackApi.storeOperation(createData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    updateOperation ({ commit }, updateData) {
      return new Promise((resolve, reject) => {
        feedbackApi.updateOperation(updateData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    // Operation End
    // Repair
    getRepairData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        feedbackApi.getRepairData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    storeRepair ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        feedbackApi.storeRepair(createData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    updateRepair ({ commit }, updateData) {
      return new Promise((resolve, reject) => {
        feedbackApi.updateRepair(updateData).then((response) => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    // Repair End

    getFeedbackCount ({ commit }) {
      return new Promise((resolve, reject) => {
        feedbackApi.getFeedbackCount().then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default feedback
