import * as planApi from '@/api/billingPlan'

const billingPlan = {
  state: {

  },
  mutations: {

  },
  actions: {
    getBPID ({ commit }) {
      return new Promise((resolve, reject) => {
        planApi.getBPID().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storePlan ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        planApi.storePlan(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updatePlan ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        planApi.updatePlan(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroyPlan ({ commit }, BPID) {
      return new Promise((resolve, reject) => {
        planApi.destroyPlan(BPID).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default billingPlan
