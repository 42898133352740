import * as adminApi from '@/api/admin'
import * as authApi from '@/utils/auth'

const admin = {
  state: {
    token: authApi.getToken(),
    PID: '',
    roles: '',
    auID: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_PID: (state, PID) => {
      state.PID = PID
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_AUID: (state, auID) => {
      state.auID = auID
    }
  },

  actions: {
    // 用户名登录
    LoginByUsername ({ commit }, userInfo) {
      const username = userInfo.username.trim()
      return new Promise((resolve, reject) => {
        adminApi.loginByUsername(username, userInfo.password).then(response => {
          if (response.code === 2001) {
            commit('SET_TOKEN', response.data[0].sessionID)
            commit('SET_ROLES', response.data[0].roles)
            commit('SET_PID', response.data[0].PID)
            commit('SET_AUID', response.data[0].auID)
            authApi.setToken(response.data[0].sessionID)
            resolve()
          } else {
            reject(response.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetUserRoles ({ commit, state }) {
      return new Promise((resolve, reject) => {
        adminApi.getUserRoles().then(response => {
          if (!response.data) { // 由于mockjs 不支持自定义状态码只能这样hack
            let e = 'error'
            reject(e)
          }
          const data = response.data[0]
          if (data.roles > 0) { // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles)
            commit('SET_PID', data.PID)
            commit('SET_AUID', data.auID)
          } else {
            let e = 'getInfo: roles must be a non-null array !'
            reject(e)
          }

          // commit('SET_NAME', data.name)
          // commit('SET_AVATAR', data.avatar)
          // commit('SET_INTRODUCTION', data.introduction)
          // commit('SET_VENDOR', data.vendor)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut ({ commit, state }) {
      return new Promise((resolve, reject) => {
        adminApi.logout(state.token).then((response) => {
          if (response.code === 2000) {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', 0)
            commit('SET_PID', '')
            commit('SET_AUID', '')
            authApi.removeToken()
            resolve(response)
          } else {
            reject(response.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', 0)
        commit('SET_PID', '')
        commit('SET_AUID', '')
        authApi.removeToken()
        resolve()
      })
    },

    // 动态修改权限
    /*
    ChangeRoles ({ commit, dispatch }, role) {
      return new Promise(resolve => {
        commit('SET_TOKEN', role)
        setToken(role)
        getUserInfo(role).then(response => {
          const data = response.data
          commit('SET_ROLES', data.roles)
          commit('SET_NAME', data.name)
          commit('SET_AVATAR', data.avatar)
          commit('SET_INTRODUCTION', data.introduction)
          // dispatch('GenerateRoutes', data) // 动态修改权限后 重绘侧边菜单
          resolve()
        })
      })
    }
    */

    getAdminUserByPID ({ commit }, pid) {
      return new Promise((resolve, reject) => {
        adminApi.getAdminUserByPID(pid).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getGroupPermissions ({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi.getGroupPermissions().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storeAdminUser ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        adminApi.storeAdminUser(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateAdminUser ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        adminApi.updateAdminUser(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroyAdminUser ({ commit }, auID) {
      return new Promise((resolve, reject) => {
        adminApi.destroyAdminUser(auID).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    getPersonal ({ commit }) {
      return new Promise((resolve, reject) => {
        adminApi.getPersonal().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    updatePersonal ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        adminApi.updatePersonal(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default admin
