<template>
  <AppHeaderDropdown right no-caret>
    <template slot="header">
      <img src="img/avatars/6.png" class="img-avatar" alt="admin@chainmeans.com" />
    </template>\
    <template slot="dropdown">
      <b-dropdown-item to="/personal">
        <i class="fa fa-user-circle-o" />
        {{$t('nav_personal')}}
      </b-dropdown-item>
      <b-dropdown-item @click="logout()">
        <i class="fa fa-sign-out" />
        {{$t('logout')}}
      </b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { HeaderDropdown as AppHeaderDropdown } from "@coreui/vue";
export default {
  name: "DefaultHeaderDropdownAccnt",
  components: {
    AppHeaderDropdown
  },
  data: function() {
    return {};
  },
  methods: {
    logout() {
      this.$store
        .dispatch("LogOut", this)
        .then(() => {
          this.$router.push({ path: this.redirect || "/login" });
        })
        .catch(e => {
          alert(e);
        });
    }
  }
};
</script>
