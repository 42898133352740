<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="fa fa-cog" style="margin-right: .5rem;"></i>
            <strong>一般用戶管理</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="10" md="11" class="text-center">
              <b-form-input
              v-model="userSearch"
              type="text"
              trim
              placeholder="- 暱稱、真實姓名、帳號、聯絡電話、E-Mail"></b-form-input>
            </b-col>
            <b-col cols="4" sm="2" md="1" class="text-center">
              <b-button class="action-btn" variant="info" @click="getUserData()">搜尋</b-button>
            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="permission" slot-scope="props">
                <p v-if="props.row.permission === 1">手機驗證</p>
                <p v-if="props.row.permission === 2">實名驗證</p>
                <p v-if="props.row.permission === 3">付款方式驗證</p>
                <p v-if="props.row.permission === 4">月費會員</p>
              </div>
              <div slot="userType" slot-scope="props">
                <p v-if="props.row.userType === 'none'">一般</p>
                <p v-if="props.row.userType === 'ncku'">成大</p>
              </div>
              <div slot="registrationType" slot-scope="props">
                <p v-if="props.row.registrationType === 'phone'">手機註冊</p>
                <p v-if="props.row.registrationType === 'ncku'">成大註冊</p>
              </div>
              <div slot="idCardReview" slot-scope="props">
                <p v-if="props.row.idCardReview === 0">未驗證</p>
                <b v-if="props.row.idCardReview === 1" class="text-warning">審核中</b>
                <p v-if="props.row.idCardReview === 2" class="text-success">已驗證</p>
              </div>
              <div slot="userEnabled" slot-scope="props">
                <p v-if="props.row.userEnabled === 0">停用</p>
                <p v-if="props.row.userEnabled === 1">啟用</p>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" @click="userInfo(props.row)" variant="outline-primary">詳細資料</b-button>
                <b-button class="action-btn" @click="idCardCheck(props.row)" variant="outline-success" v-if="roles <= 2 && props.row.idCardReview === 1">實名認證</b-button>
                <b-button class="action-btn" variant="outline-success" v-if="roles <= 2 && props.row.idCardReview !== 1" disabled>實名認證</b-button>
                <b-button class="action-btn" @click="editUser(props.row)" variant="outline-warning" v-if="roles <= 2">編輯</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="editUserModal" title="編輯用戶" header-bg-variant="warning" ok-only no-stacking>
      <b-form-group
        label="帳號"
        label-for="username"
        :label-cols="3">
        <b-input-group>
          <b-form-input id="username" type="text" v-model="editData.username" trim placeholder="- Username" :disabled="!usernameEdit"></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="toggleEdit('username')">
              <i class="fa fa-pencil"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="密碼"
        label-for="password"
        :label-cols="3">
        <b-input-group>
          <b-form-input id="password" type="text" v-model="editData.password" trim placeholder="- 點擊右方按鈕隨機生成密碼。" disabled></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="random_password()">
              <i class="fa fa-undo"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        label="暱稱"
        label-for="displayName"
        :label-cols="3">
        <b-form-input id="displayName" type="text" v-model="editData.displayName" trim placeholder="- DisplayName"></b-form-input>
      </b-form-group>
      <b-form-group
        label="E-Mail"
        label-for="email"
        :label-cols="3">
        <b-form-input id="email" type="email" v-model="editData.email" trim placeholder="- E-Mail"></b-form-input>
      </b-form-group>
      <b-form-group
        label="聯絡電話"
        label-for="phone"
        :label-cols="3">
        <b-form-input id="phone" type="text" v-model="editData.phone" trim placeholder="- Phone"></b-form-input>
      </b-form-group>
      <b-form-group
        label="生日"
        label-for="birthday"
        :label-cols="3">
        <b-form-input id="birthday" type="date" v-model="editData.birthday" trim placeholder="- Birthday"></b-form-input>
      </b-form-group>
      <b-form-group
        label="性別"
        label-for="sex"
        :label-cols="3">
        <b-form-select id="sex" v-model="editData.sex" trim :options="sexOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="用戶類型"
        label-for="userType"
        :label-cols="3">
        <b-form-select id="userType" v-model="editData.userType" trim :options="userTypeOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="狀態"
        label-for="userEnabled"
        :label-cols="3">
        <b-form-select id="userEnabled" v-model="editData.userEnabled" trim :options="userEnabledOptions"></b-form-select>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editUserModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="updateUser()">確定</b-button>
    </b-modal>

    <b-modal v-model="userInfoModal" title="詳細資料" header-bg-variant="primary" size="lg" hide-footer on-stacking>
      <b-card class="card-accent-info" >
        <div slot="header">
          <i class='fa fa-info-circle'></i> 詳細資料
        </div>
        <b-row>
          <b-col cols="12" sm="6">
            <p>帳號 : {{ userInfoData.username }}</p>
            <p>E-Mail : {{ userInfoData.email }}</p>
            <p>性別 :
              <font v-if="userInfoData.sex === 1">男</font>
              <font v-if="userInfoData.sex === 0">女</font>
            </p>
            <p>用戶類型 :
              <font v-if="userInfoData.userType === 'none'">一般</font>
              <font v-if="userInfoData.userType === 'ncku'">成大</font>
            </p>
            <p>實名認證 :
              <font v-if="userInfoData.idCardReview === 0">未驗證</font>
              <font v-if="userInfoData.idCardReview === 1" class="text-warning">審核中</font>
              <font v-if="userInfoData.idCardReview === 2" class="text-success">已驗證</font>
            </p>
            <p>權限 :
              <font v-if="userInfoData.permission === 1">手機驗證</font>
              <font v-if="userInfoData.permission === 2">實名驗證</font>
              <font v-if="userInfoData.permission === 3">付款方式驗證</font>
              <font v-if="userInfoData.permission === 4">月費會員</font>
            </p>
          </b-col>
          <b-col cols="12" sm="6">
            <p>暱稱 : {{ userInfoData.displayName }}</p>
            <p>聯絡電話 : {{ userInfoData.phone }}</p>
            <p>生日 : {{ userInfoData.birthday }}</p>
            <p>註冊類型 :
              <font v-if="userInfoData.registrationType === 'phone'">手機註冊</font>
              <font v-if="userInfoData.registrationType === 'ncku'">成大註冊</font>
            </p>
            <p>真實姓名 : {{ userInfoData.realName }}</p>
            <p>狀態 :
              <font v-if="userInfoData.userEnabled === 0">停用</font>
              <font v-if="userInfoData.userEnabled === 1">啟用</font>
            </p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <loading
      :show="show"
      :label="label"
      :overlay="overlay">
      <GridLoader slot="loading-spinner"
                  color="#f5a623"
                  :size=10
                  style="display:inline-block;"
                  >
      </GridLoader>
    </loading>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from 'vue-full-loading'

export default {
  name: 'user',
  components: {
    loading
  },
  data: function () {
    return {
      show: false,
      label: 'Loading...',
      overlay: true,
      roles: this.$store.getters.roles,
      userSearch: '',
      editUserModal: false,
      usernameEdit: false,
      editData: {},
      sexOptions: [
        { value: null, text: '請選擇...' },
        { value: 1, text: '男' },
        { value: 0, text: '女' }
      ],
      userTypeOptions: [
        { value: 'none', text: '一般' },
        { value: 'ncku', text: '成大' }
      ],
      userEnabledOptions: [
        { value: 1, text: '啟用' },
        { value: 0, text: '停用' }
      ],
      userInfoModal: false,
      userInfoData: {},
      columns: [ 'username', 'email', 'phone', 'displayName', 'realName', 'permission', 'userType', 'registrationType', 'idCardReview', 'userEnabled', 'action' ],
      data: [],
      options: {
        headings: {
          'username': '帳號',
          'email': 'E-Mail',
          'phone': '聯絡電話',
          'displayName': '暱稱',
          'realName': '真實姓名',
          'permission': '權限',
          'userType': '用戶類型',
          'registrationType': '註冊類型',
          'idCardReview': '實名認證',
          'userEnabled': '狀態',
          'action': '動作'
        },
        sortable: [ 'username', 'email', 'phone', 'displayName', 'realName' ],
        filterable: [ 'username', 'email', 'phone', 'displayName', 'realName' ],
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        }
      }
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    getUserData () {
      this.$store.dispatch('getUserData', { userSearch: this.userSearch }).then(res => {
        this.data = []
        for (let i in res) {
          res[i].birthday = this.$moment(res[i].birthday).format('YYYY-MM-DD')
          this.data.push(res[i])
        }
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    editUser (prop) {
      this.editData = { UID: prop.UID, username: prop.username, password: '', userType: prop.userType, phone: prop.phone, email: prop.email, displayName: prop.displayName, sex: prop.sex, birthday: prop.birthday, userEnabled: prop.userEnabled }
      this.usernameEdit = false
      this.editUserModal = true
    },
    updateUser () {
      this.$store.dispatch('updateUser', this.editData).then(res => {
        this.editUserModal = false
        this.getUserData()
        this.$swal('Success', '用戶更新成功', 'success')
      }).catch(e => {
        this.$swal('Error', e, 'error')
      })
    },
    userInfo (prop) {
      this.userInfoData = prop
      this.userInfoModal = true
    },
    idCardCheck (prop) {
      this.$swal('Warning', '未開放', 'warning')
    },
    toggleEdit (e) {
      if (e === 'username') this.usernameEdit = !this.usernameEdit
    },
    random_password () {
      let random = Math.floor(Math.random() * 9) + 1 // random 1~9
      for (var i = 0; i < 5; i++) {
        random = random.toString() + Math.floor(Math.random() * 10).toString() // random 0~9
      }
      this.editData.password = random
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
