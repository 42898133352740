import * as providerApi from '@/api/provider'

const provider = {
  state: {

  },
  mutations: {

  },
  actions: {
    getProviderInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        providerApi.getProviderInfo().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storeProvider ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        providerApi.storeProvider(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateProvider ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        providerApi.updateProvider(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroyProvider ({ commit }, PID) {
      return new Promise((resolve, reject) => {
        providerApi.destroyProvider(PID).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default provider
