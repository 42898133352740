import * as lockApi from '@/api/lock'

const lock = {
  state: {

  },
  mutations: {

  },
  actions: {
    getLockData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        lockApi.getLockData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateLock ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        lockApi.updateLock(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    importExcelLock ({ commit }, importExcelData) {
      return new Promise((resolve, reject) => {
        lockApi.importExcelLock(importExcelData).then(response => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default lock
