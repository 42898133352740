import i18n from '@/lang/i18n'

export default {
  items: [
    {
      name: i18n.t('dashboard'),
      url: '/dashboard',
      icon: 'fa fa-tachometer',
      roles: [1, 2, 3, 4]
    },
    {
      title: true,
      name: i18n.t('nav_warning'),
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_warning'),
      url: '/warning/system',
      icon: 'fa fa-exclamation-circle',
      roles: [1, 2, 3]
    },
    {
      title: true,
      name: i18n.t('nav_maintain'),
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_station'),
      url: '/manager/station',
      icon: 'fa fa-cog',
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_space'),
      url: '/manager/space',
      icon: 'fa fa-cog',
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_lock'),
      url: '/manager/lock',
      icon: 'fa fa-cog',
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_bike'),
      url: '/manager/bike',
      icon: 'fa fa-cog',
      roles: [1, 2, 3]
    },
    // {
    //   name: '一般用戶管理',
    //   url: '/manager/user',
    //   icon: 'fa fa-cog',
    //   roles: [1]
    // },
    // {
    //   title: true,
    //   name: '財務管理',
    //   class: '',
    //   wrapper: {
    //     element: '',
    //     attributes: {}
    //   },
    //   roles: [1, 2, 3]
    // },
    // {
    //   name: '訂單查詢',
    //   url: '/finance/orderSearch',
    //   icon: 'fa fa-search',
    //   roles: [1, 2, 3]
    // },
    // {
    //   name: '計費方式',
    //   url: '/finance/billingPlan',
    //   icon: 'fa fa-calculator',
    //   roles: [1]
    // },
    {
      title: true,
      name: i18n.t('nav_maintain'),
      class: '',
      wrapper: {
        element: '',
        attributes: {}
      },
      roles: [1, 2, 3]
    },
    {
      name: i18n.t('nav_maintain'),
      url: '/maintain/maintain',
      icon: 'fa fa-comments-o',
      roles: [1, 2, 3]
    }
  ]
}
