import request from '@/utils/request'

// Feedback
export function getFeedbackData (searchData) {
  return request({
    url: '/feedback/feedbackData',
    method: 'get',
    params: searchData
  })
}

export function storeFeedback (createData) {
  const data = {
    createData
  }
  return request({
    url: '/feedback/storeFeedback',
    method: 'post',
    data
  })
}

export function updateFeedback (updateData) {
  const data = {
    updateData
  }
  return request({
    url: '/feedback/updateFeedback',
    method: 'post',
    data
  })
}

export function storeFeedbackReply (createData) {
  const data = {
    createData
  }
  return request({
    url: '/feedback/storeFeedbackReply',
    method: 'post',
    data
  })
}

export function getFeedbackReplyData (searchData) {
  return request({
    url: '/feedback/feedbackReplyData',
    method: 'get',
    params: searchData
  })
}
// Feedback End
// Operation
export function getOperationData (searchData) {
  return request({
    url: '/feedback/operationData',
    method: 'get',
    params: searchData
  })
}

export function storeOperation (createData) {
  const data = {
    createData
  }
  return request({
    url: '/feedback/storeOperation',
    method: 'post',
    data
  })
}

export function updateOperation (updateData) {
  const data = {
    updateData
  }
  return request({
    url: '/feedback/updateOperation',
    method: 'post',
    data
  })
}
// Operation End
// Repair
export function getRepairData (searchData) {
  return request({
    url: '/feedback/repairData',
    method: 'get',
    params: searchData
  })
}

export function storeRepair (createData) {
  const data = {
    createData
  }
  return request({
    url: '/feedback/storeRepair',
    method: 'post',
    data
  })
}

export function updateRepair (updateData) {
  const data = {
    updateData
  }
  return request({
    url: '/feedback/updateRepair',
    method: 'post',
    data
  })
}
// Repair End

export function getFeedbackCount () {
  return request({
    url: '/feedback/feedbackCount',
    method: 'get'
  })
}
