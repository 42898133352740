import request from '@/utils/request'

export function getBPID () {
  return request({
    url: '/billingPlan/BPID',
    method: 'get'
  })
}

export function storePlan (createData) {
  const data = {
    createData
  }
  return request({
    url: '/billingPlan/storePlan',
    method: 'post',
    data
  })
}

export function updatePlan (editData) {
  const data = {
    editData
  }
  return request({
    url: '/billingPlan/updatePlan',
    method: 'post',
    data
  })
}

export function destroyPlan (BPID) {
  const data = {
    BPID
  }
  return request({
    url: '/billingPlan/destroyPlan',
    method: 'post',
    data
  })
}
