import request from '@/utils/request'

export function getProviderInfo () {
  return request({
    url: '/provider/providerInfo',
    method: 'get'
  })
}

export function storeProvider (createData) {
  const data = {
    createData
  }
  return request({
    url: '/provider/storeProvider',
    method: 'post',
    data
  })
}

export function updateProvider (editData) {
  const data = {
    editData
  }
  return request({
    url: '/provider/updateProvider',
    method: 'post',
    data
  })
}

export function destroyProvider (PID) {
  const data = {
    PID
  }
  return request({
    url: '/provider/destroyProvider',
    method: 'post',
    data
  })
}
