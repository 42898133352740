export default {
  nav_dashboard: '系統儀表板',
  nav_provider: '合作廠商管理',
  nav_admin: '後台用戶管理',
  nav_personal: '個人資料',
  nav_chartList: '圖表一覽',
  nav_stationList: '站點一覽',
  nav_spaceList: '車位一覽',
  nav_bikeList: '單車一覽',
  nav_station: '站點管理',
  nav_space: '車位管理',
  nav_lock: '鎖樁管理',
  nav_bike: '單車管理',
  nav_maintain: '客服維運',
  nav_warning: '系統警示',
  nav_customerServiceManagement: '客服管理',
  nav_maintainManagement: '維運管理',
  nav_repair: '維修管理',

  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',

  jan: '1 月',
  feb: '2 月',
  mar: '3 月',
  apr: '4 月',
  may: '5 月',
  jun: '6 月',
  jul: '7 月',
  aug: '8 月',
  sep: '9 月',
  oct: '10 月',
  nov: '11 月',
  dec: '12 月',





  dashboard: '儀表板',
  createAdmin: '新增用戶',
  rootPermission: '根權限',
  adminPermission: '管理權限',
  maintainPermission: '維運權限',
  clientPermission: '客戶權限',
  providerPermission: '廠商權限',
  edit: '編輯',
  delete: '刪除',
  displayName: '暱稱',
  username: '帳號',
  password: '密碼',
  createPasswordMsg: '- 點擊右方按鈕隨機生成密碼。',
  phone: '聯絡電話',
  company: '公司',
  role: '權限',
  cancel: '取消',
  ok: '確定',
  editUser: '編輯用戶',
  deleteUser: '刪除用戶',
  maintainStaff: '維運人員',
  serviceStaff: '維修人員',
  deleteUserMsg: '您確定要刪除用戶',
  select: '請選擇....',
  action: '動作',
  required: '必填欄位不得為空',
  createUsersSuccess: '用戶新增成功',
  editUsersSuccess: '用戶編輯成功',
  removeUsersSuccess: '用戶刪除成功',
  updateUsersSuccess: '用戶更新成功',
  changeAvatar: '變更個人圖片',
  changePassword: '變更密碼',
  passwordDoubleCheck: '確認密碼',
  clear: '清除',
  passwordWrong: '密碼不正確',
  updateSuccess: '更新成功',
  stationCount: '運行站點',
  todaypriceSum: '本日收益',
  todayturnoverCount: '本日週轉數',
  feedbackCount: '未處理回饋單',
  turnoverAnalysis: '週轉分析',
  turnoverCount: "週轉數",
  priceSum: "收益",
  feedbackForm: '回饋單',
  stationDistributed: '站點分佈',
  workingNormal: '正常運作',
  noBike: '無可租借車輛',
  parkingFull: '已停滿',
  stationMaintain: '站點維護',
  turnoverLow: '週轉率偏低',
  turnoverNormal: '週轉率正常',
  turnoverHigh: '週轉率偏高',
  ratingNormal: '評價正常',
  ratingLow: '評價偏低',
  saturationHotspot: '飽和率熱點',
  turnoverHotspot: '週轉熱點',
  bikeLowRatingHotSpot: '低評價單車熱點',
  totalAmount: '總量',
  available: '可用',
  maintain: '維護',
  bikeLowRating: '低評價單車',
  turnoverRate: '週轉率',
  profit: '收益(TWD)',
  billingPlan: '計費方式',
  new: '新增',
  enabled: '啟用',
  disabled: '停用',
  calculate: '試算',
  createPlan: '新增計費方式',
  formulaMsg: '公式規範 : count = 使用次數, useTime = 使用時間(秒)',
  planName: '計費方式名稱',
  planContent: '計費方式內容',
  algorithm: '計費公式',
  welcome: '歡迎回來',
  loginAccount: '登入您的帳號',
  login: '登入',
  stationWarning: '站點警示',
  returnCount: '還車數',
  normalCount: '正常運作',
  noBikeCount: '無可租借車輛',
  lockFullCount: '已停滿',
  idling: '閒置中',
  stationMaintain: '站點維護',
  bikeWarning: '單車警示',
  renting: '租用中',
  idle: '閒置',
  bike: '單車',
  wheelchair: '輪椅',
  dataInfo: '詳細資料',
  vendorName: '廠商名稱',
  stationName: '站點名稱',
  spaceName: '車位名稱',
  spaceEnabled: '車位啟用狀態',
  spaceEnabledTime: '車位啟用時間',
  lockPileID: '鎖樁ID',
  lockPileAddress: '鎖樁MAC',
  lockPileStatus: '鎖樁狀態',
  bikeLocked: '鎖車',
  bikeUnlocked: '無鎖車',
  deviceBattery: '設備電量',
  bikeType: '車輛類型',
  lockPileUpdateAt: '鎖樁最後更新時間',
  allProvider: '全部廠商',
  allStation: '全部站點',
  providerName: "歸屬廠商",
  stationStatus: "站點狀態",
  warnAt: '警報時間',
  tagID: '標籤ID',
  type: '類型',
  status: '狀態',
  rate: '評價',
  rateCount: '評價數',
  useCount: '使用數',
  bikelocate: '停放站點',
  search: '搜尋',
  editBike: '編輯單車',
  bikeTagID: '單車標籤ID',
  providerList: '廠商對照表',
  excelDownload: 'Excel範例下載',

  excelSpecification: 'Excel規範',
  importExcel: '匯入Excel',
  confirmDataMsg: '請確定您匯入的資料。',
  noProvider: '無歸屬廠商',
  PID: "廠商ID (PID)",
  noSpace: '無停放車位',
  noStation: '無停放站點',
  lastSearchAt: '最後搜尋於 ',
  total: '，共有 ',
  records: ' 項結果',
  searchTime: '搜尋時間：',
  second: '秒',
  searchInfoErrorMsg: '無資料或是發生錯誤，請重新選定條件後搜尋。',
  bikeUpdateSuccess: '單車更新成功',
  excelFileError: '上傳檔案格式不正確，請上傳xls或xlsx格式',
  importExceldata: '匯入資料筆數',
  importExcelSuccess: '成功筆數',
  importExcelFail: '失敗筆數',
  editLockPile: '編輯鎖樁',
  noBindspace: '無綁定車位',
  noBindStation: '無綁定站點',
  lockPileUpdateSuccess: '鎖樁更新成功',
  createSpace: '新增車位',
  editSpace: '編輯車位',
  bindLockPileID: '綁定鎖樁ID',
  nobind: '- 未綁定',
  bindList: '鎖樁清單',
  noBindList: '未綁定清單',
  areyouSureDeleteSpaceMsg: '您確定要刪除車位',
  deleteSpace: '刪除車位',
  makeSureUnbindAlready: '請確定已將該車位之鎖樁解除綁定。',
  selectProvider: '請選擇廠商....',
  selectStation: '請選擇站點....',
  createSpaceSuccess: '車位新增成功',
  updatespaceSuccess: '車位更新成功',
  deleteSpaceSuccess: '車位刪除成功',
  createStaion: '新增站點',
  locate: '打點',
  provider: '廠商',
  stationLocation: "站點地址",
  isEnabled: '是否啟用',
  editStation: '編輯站點',
  deleteStation: '刪除站點',
  areyouSureDeleteStationMsg: '您確定要刪除站點',
  makeSureClearAlreadyMsg: '請確定已先將該站點之車位全部清除。',

  locateSetting: '打點設定',
  locateOld: '舊座標',
  locateNew: '新座標',
  totalLock: "站點總車鎖數",
  stationLock: "站點車鎖數(可用/使用中/維護)",
  totalBikes: "站點總單車數",
  lastUpdateAt: "最後更新時間",
  createStaionSuccess: '站點新增成功',
  updateStationSuccess: '站點更新成功',
  deleteStationSuccess: '站點刪除成功',
  stationLocateSuccess: '站點打點成功',
  createFeedback: '新增回饋單',
  feedbackFormType: '回饋單類型',
  issueType: '問題類型',
  content: '內容',
  feedbackType: '回饋類型',
  lockId: '車鎖ID',
  bikeId: '單車ID',
  handler: '處理人員',
  reply: '回覆',
  getOrder: '接單',
  maintainOrder: '維運單',
  repairOrder: '維修單',
  end: '結束',

  updateCheck: '確認動作',
  areYouSure: '您確定要',
  thisFeedbackForm: '此回饋單嗎？',
  replyData: '回覆資料',
  replyContent: '回覆內容',
  allFeedbackType: '全部回饋類型',
  opinion: '意見',
  question: '問題',
  addManually: '手動新增',
  allissueType: '全部問題類型',
  createMaintainForm: '新增維運單',
  questionLevel: '問題等級',
  maintainStation: '維運站點',
  maintainSpace: '維運車位',
  feedbackID: '回饋單關聯',
  obsolete: '作廢',
  thisMaintainForm: '此維運單嗎？',
  yes: '是',
  no: '否',
  createRepairForm: '新增維修單',
  repairStation: '維修站點',
  repairSpace: '維修車位',
  maintainOrderID: '維運單關聯',
  deviceExchange: '設備更換',
  thisRepairForm: '此維修單嗎？',
  cantParking: '無法停車',
  cantGetting: '無法取車',
  mobilePayment: '行動支付',
  appIssue: 'APP問題',
  other: '其他',
  broken: '外觀損壞',
  allStatus: '全部狀態',
  unprocessed: '未處理',
  processing: '處理中',
  replyed: '已回覆',
  createAt: "新增日期",
  allQuestionLevel: "全部問題等級",

  repairIssueTypeMsg1: '機構件損壞',
  repairIssueTypeMsg2: '藍芽通訊損壞',
  repairIssueTypeMsg3: 'NBIoT通訊損壞',
  repairIssueTypeMsg4: '設備電量不足',
  repairIssueTypeMsg5: 'APP問題',
  repairIssueTypeMsg6: '後台資料問題',
  repairIssueTypeMsg7: '使用者手機問題',
  repairIssueTypeMsg8: '使用者操作不當',

  repairLevel1: '使用優化需求',
  repairLevel2: '設備僅外觀損壞，不影響使用',
  repairLevel3: '設備損壞，但短時間內仍可正常運作',
  repairLevel4: '設備損壞，不直接影響user權利',
  repairLevel5: '設備損壞，已影響user權利',

  confirmOnSite: '已現場確認',
  repaired: '已維修',
  connected: '已連線',
  unconnected: '未連線',
  createSuccess: '新增成功',
  replySuccess: '回覆成功',

  createProvider: '新增廠商',
  editProvider: '編輯廠商',
  deleteProvider: '刪除廠商',
  deleteProviderMsg: '您確定要刪除廠商 ',
  sureProviderClearMsg: '請確定已先將該廠商之站點全部清除。',

  createProviderSuccess: '廠商新增成功',
  updateProviderSuccess: '廠商更新成功',
  deleteProviderSuccess: '廠商刪除成功',
  logout: '登出',
  locateWrong: '請輸入正確經緯度格式',
  inputLocate: '輸入座標'
}
