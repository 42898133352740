import * as spaceApi from '@/api/space'

const space = {
  state: {

  },
  mutations: {

  },
  actions: {
    getSpaceByLSID ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        spaceApi.getSpaceByLSID(searchData).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getBindList ({ commit }) {
      return new Promise((resolve, reject) => {
        spaceApi.getBindList().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storeSpace ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        spaceApi.storeSpace(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateSpace ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        spaceApi.updateSpace(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroySpace ({ commit }, LSSID) {
      return new Promise((resolve, reject) => {
        spaceApi.destroySpace(LSSID).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDailySpaceTurnoverCount ({ commit }, LSSID) {
      return new Promise((resolve, reject) => {
        spaceApi.getDailySpaceTurnoverCount(LSSID).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default space
