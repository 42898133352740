import request from '@/utils/request'

export function getUserData (searchData) {
  return request({
    url: '/user/userData',
    method: 'get',
    params: searchData
  })
}

export function updateUser (editData) {
  const data = {
    editData
  }
  return request({
    url: '/user/updateUser',
    method: 'post',
    data
  })
}
