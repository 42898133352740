import Vue from 'vue'
import Vuex from 'vuex'
import getters from '@/store/getters'
import admin from '@/store/modules/admin'
import device from '@/store/modules/device'
import space from '@/store/modules/space'
import lock from '@/store/modules/lock'
import billingPlan from '@/store/modules/billingPlan'
import provider from '@/store/modules/provider'
import lockStation from '@/store/modules/lockStation'
import order from '@/store/modules/order'
import bike from '@/store/modules/bike'
import warn from '@/store/modules/warn'
import user from '@/store/modules/user'
import feedback from '@/store/modules/feedback'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin,
    device,
    space,
    lock,
    billingPlan,
    provider,
    lockStation,
    order,
    bike,
    warn,
    user,
    feedback
  },
  getters
})
