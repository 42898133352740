import * as deviceApi from '@/api/device'

const device = {
  state: {

  },
  mutations: {

  },
  actions: {
    getGeneralData ({ commit, state }) {
      return new Promise((resolve, reject) => {
        deviceApi.getGeneralData().then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getDeviceErrorListSearch ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getDeviceErrorListSearch(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getDeviceInfoListSearch ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getDeviceInfoListSearch(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getDeviceInfoSearch ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getDeviceInfoSearch(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getDeviceSettingInfo ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getDeviceSettingInfo(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getInitData ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getInitData(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getNotSetDevice ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getNotSetDevice().then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDeviceMonitorList ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getDeviceMonitorList(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getNotMonitorList ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getNotMonitorList().then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setDeviceEnable ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setEnable(condition.isEnable, condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setDeviceField ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setField(condition.field, condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    setDeviceType ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setDeviceType(condition.deviceType, condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    setInitData ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setInitData(condition.seqID, condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setDeviceMode ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setDeviceMode(condition.deviceMode, condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setMonitor ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setMonitor(condition.devid, condition.monitorReason).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setFixed ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setFixed(condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    setEndMonitor ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.setEndMonitor(condition.devid).then((response) => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getNotBindingDeviceList ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getNotBindingDeviceList(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getRawDataSearchById ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getRawDataSearchById(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getMonitorDataSearchById ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getMonitorDataSearchById(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getErrorCodeDataById ({ commit, state }, condition) {
      return new Promise((resolve, reject) => {
        deviceApi.getErrorCodeDataById(condition).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }
  }
}

export default device
