<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i
              class="fa fa-map-marker bg-primary p-3 font-2xl mr-3 float-left text-center"
              style="width: 60px;"
            ></i>
            <div class="h5 text-primary mb-0 mt-2">{{mainData.stationCount}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{$t('stationCount')}}</div>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i class="fa fa-money bg-info p-3 font-2xl mr-3 float-left text-center" style="width: 60px;"></i>
            <div class="h5 text-info mb-0 mt-2">${{mainData.priceSum}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">本日收益</div>
          </b-card-body>
        </b-card>
      </b-col>-->
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i
              class="fa fa-refresh bg-success p-3 font-2xl mr-3 float-left text-center"
              style="width: 60px;"
            ></i>
            <div class="h5 text-success mb-0 mt-2">{{mainData.turnoverCount}}</div>
            <div
              class="text-muted text-uppercase font-weight-bold font-xs"
            >{{$t('todayturnoverCount')}}</div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" sm="6" lg="3">
        <b-card :no-body="true">
          <b-card-body class="p-3 clearfix">
            <i
              class="fa fa-comments-o bg-warning p-3 font-2xl mr-3 float-left text-center"
              style="width: 60px;"
            ></i>
            <div class="h5 text-warning mb-0 mt-2">{{mainData.feedbackCount}}</div>
            <div class="text-muted text-uppercase font-weight-bold font-xs">{{$t('feedbackCount')}}</div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-row>
      <b-col cols="12" sm="12" md="12">
        <b-card>
          <b-card-title>週轉分析</b-card-title>
          <div class="small text-muted">{{ $moment().format('DD MMM YYYY') }}</div>
          <div ref="chartWrap">
            <ve-line :data="chartData" :extend="extend" :settings="chartSettings" ref="chart"></ve-line>
          </div>
        </b-card>
      </b-col>
    </b-row>-->
    <b-row>
      <b-col cols="12" sm="12" md="12">
        <b-card>
          <b-row>
            <b-col cols="12" sm="12" class="d-flex justify-content-between align-items-center">
              <div class="d-flex justify-content-start align-items-center">
                <h4 id="station" class="card-title mb-0">{{$t('stationDistributed')}}</h4>
                <GmapAutocomplete
                  @place_changed="setPlace"
                  class="form-control"
                  style="width: 400px; margin-left: 0.5rem;"
                ></GmapAutocomplete>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <div v-if="mapSelect === 'stationStatus'">
                  <img src="img/parking/marker-blue.png" style="max-width: 25%; height: auto;" />
                  {{$t('workingNormal')}}
                  <img
                    src="img/parking/marker-red.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('noBike')}}
                  <img
                    src="img/parking/marker-orange.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('parkingFull')}}
                  <img
                    src="img/parking/marker-purple.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('stationMaintain')}}
                </div>
                <div v-if="mapSelect === 'turnoverCount'">
                  <img src="img/parking/marker-blue.png" style="max-width: 25%; height: auto;" />
                  {{$t('turnoverLow')}}
                  <img
                    src="img/parking/marker-orange.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('turnoverNormal')}}
                  <img
                    src="img/parking/marker-red.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('turnoverHigh')}}
                </div>
                <div v-if="mapSelect === 'bikeRate'">
                  <img src="img/parking/marker-blue.png" style="max-width: 25%; height: auto;" />
                  {{$t('ratingNormal')}}
                  <img
                    src="img/parking/marker-red.png"
                    style="max-width: 25%; height: auto;"
                  />
                  {{$t('ratingLow')}}
                </div>
                <b-form-select
                  v-model="mapSelect"
                  :options="mapSelectOptions"
                  style="width: 150px; margin-left: 0.5rem;"
                ></b-form-select>
              </div>
            </b-col>
          </b-row>
          <div style="margin-top: 15px;">
            <GmapMap :center="center" :zoom="zoom" style="width: 100%; height: 400px;">
              <div v-if="mapSelect === 'stationStatus'">
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('normal')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-blue.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('noBike')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-red.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('lockFull')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-orange.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('maintain')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-purple.png"
                  @click="toggleInfoWindow(m)"
                />
              </div>
              <div v-if="mapSelect === 'turnoverCount'">
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter(0)"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-blue.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter(2)"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-red.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter(1)"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-orange.png"
                  @click="toggleInfoWindow(m)"
                />
              </div>
              <div v-if="mapSelect === 'bikeRate'">
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('rate')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-blue.png"
                  @click="toggleInfoWindow(m)"
                />
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in mapMarkersFilter('rateWarn')"
                  :position="m.position"
                  :clickable="true"
                  :draggable="false"
                  :visible="true"
                  icon="img/parking/marker-red.png"
                  @click="toggleInfoWindow(m)"
                />
              </div>
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
              >
                <div v-html="infoContent"></div>
              </gmap-info-window>
            </GmapMap>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
</style>

<script>
import elementResizeDetectorMaker from "element-resize-detector";

export default {
  name: "dashboard",
  components: {},
  data: function() {
    return {
      mainData: {
        stationCount: 0,
        priceSum: 0,
        turnoverCount: 0,
        feedbackCount: 0
      },
      // extend: {
      //   'xAxis.0.axisLabel.rotate': 25
      // },
      // chartSettings: {
      //   labelMap: {
      //     turnoverCount: '週轉數',
      //     priceSum: '收益'
      //   }
      // },
      // chartData: {
      //   columns: ['date', 'turnoverCount', 'priceSum'],
      //   rows: []
      // },
      mapSelect: "stationStatus",
      mapSelectOptions: [
        { value: "stationStatus", text: this.$t("saturationHotspot") },
        { value: "turnoverCount", text: this.$t("turnoverHotspot") },
        { value: "bikeRate", text: this.$t("bikeLowRatingHotSpot") }
      ],
      bikeData: [],
      center: { lat: 23.854648, lng: 120.937847 },
      zoom: 7,
      position: null,
      mapMarkers: [],
      infoWinOpen: false,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      currentLSID: null
    };
  },
  computed: {},
  mounted() {
    this.getBikeData();
    this.getStationByPID();
    // this.getOrderPriceSum()
    this.getFeedbackCount();
    // this.getOrderLineChartData()
    // v-chart RWD
    const contall = this.$refs.chartWrap;
    const chart = this.$refs.chart;
    const erd = elementResizeDetectorMaker({
      strategy: "scroll"
    });
    erd.listenTo(contall, function() {
      chart.echarts.resize();
    });
  },
  methods: {
    setPlace(e) {
      if (e) {
        this.center = {
          lat: e.geometry.location.lat(),
          lng: e.geometry.location.lng()
        };
        this.zoom = 17;
      }
    },
    getOrderPriceSum() {
      this.$store
        .dispatch("getOrderPriceSum")
        .then(res => {
          this.mainData.turnoverCount = res[0].turnoverCount;
          this.mainData.priceSum = res[0].priceSum;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getFeedbackCount() {
      this.$store
        .dispatch("getFeedbackCount")
        .then(res => {
          this.mainData.feedbackCount = res[0].feedbackCount;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getOrderLineChartData() {
      this.$store
        .dispatch("getOrderLineChartData")
        .then(res => {
          this.chartData.rows = res;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getBikeData() {
      this.bikeData = [];
      this.$store.dispatch("getBikeData").then(res => {
        for (let i in res) {
          this.bikeData.push({
            LSID: res[i].LSID,
            bikeTagId: res[i].bikeTagId,
            bikeRate: res[i].bikeRate
          });
        }
      });
    },
    getStationByPID() {
      this.$store
        .dispatch("getStationByPID")
        .then(res => {
          let bikeRateContent;
          let bikeRate = [];
          this.mapMarkers = [];
          this.mainData.stationCount = 0;
          for (let i in res) {
            bikeRateContent = "";
            bikeRate = this.bikeDataFilter(res[i].LSID);
            this.mainData.stationCount += 1;
            for (let x in bikeRate) {
              bikeRateContent =
                "<br>" + bikeRate[x].bikeTagId + ": " + bikeRate[x].bikeRate;
            }
            this.mapMarkers.push({
              position: {
                lat: parseFloat(res[i].lat),
                lng: parseFloat(res[i].lng)
              },
              LSID: res[i].LSID,
              stationName: res[i].stationName,
              stationStatus: res[i].stationStatus,
              turnoverCount: res[i].turnoverCount,
              statusContent:
                res[i].stationStatus === "normal"
                  ? this.$t("workingNormal")
                  : res[i].stationStatus === "noBike"
                  ? this.$t("noBike")
                  : res[i].stationStatus === "lockFull"
                  ? this.$t("parkingFull")
                  : this.$t("stationMaintain"),
              stationLock:
                this.$t("totalAmount") +
                ": " +
                res[i].totalLock +
                " / " +
                this.$t("available") +
                ": " +
                res[i].vehicleLock +
                " / " +
                this.$t("maintain") +
                ": " +
                res[i].maintainLock,
              bikeRateContent:
                bikeRateContent.length > 0
                  ? "<br><br>" +
                    this.$t("bikeLowRating") +
                    ": " +
                    bikeRateContent
                  : ""
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    toggleInfoWindow(marker) {
      this.infoWindowPos = marker.position;
      this.infoContent =
        marker.stationName +
        "<br>" +
        marker.statusContent +
        "<br>" +
        marker.stationLock +
        "<br>" +
        this.$t("turnoverRate") +
        ": " +
        marker.turnoverCount +
        marker.bikeRateContent;

      // check if its the same marker that was selected if yes toggle
      if (this.currentLSID === marker.LSID) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.infoWinOpen = true;
        this.currentLSID = marker.LSID;
      }
    },
    mapMarkersFilter(c) {
      return this.mapMarkers.filter(function(item, index, array) {
        if (c !== 2 && c !== 1 && c !== 0 && c !== "rate" && c !== "rateWarn") {
          return item.stationStatus === c;
        } else if (c === "rate" || c === "rateWarn") {
          if (c === "rateWarn") {
            return item.bikeRateContent.length > 0;
          } else {
            return item.bikeRateContent.length === 0;
          }
        } else {
          if (c === 2) {
            return item.turnoverCount > 25;
          } else if (c === 1) {
            return item.turnoverCount >= 10 && item.turnoverCount <= 25;
          } else {
            return item.turnoverCount < 10;
          }
        }
      });
    },
    bikeDataFilter(LSID) {
      return this.bikeData.filter(function(item, index, array) {
        return item.LSID === LSID && item.bikeRate <= 3;
      });
    }
  },
  beforeDestroy() {}
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
