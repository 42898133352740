<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-bar-chart" style="margin-right: .5rem;"></i>
            <strong>圖表一覽</strong>
          </div>
          <b-row>
            <b-col cols="12" sm="6" md="6" class="text-center" v-if="roles === 1">
              <b-form-select
              v-model="providerSearch"
              :options="providerOptions"
              @change="getChartData()"></b-form-select>
            </b-col>
            <b-col cols="12" sm="6" md="6" class="text-center" v-if="roles === 1">
              <b-form-select
              v-model="beforeTimeSearch"
              :options="beforeTimeOptions"
              @change="getChartData()"></b-form-select>
            </b-col>
            <b-col cols="12" sm="12" md="12" class="text-center" v-if="roles > 1">
              <b-form-select
              v-model="beforeTimeSearch"
              :options="beforeTimeOptions"
              @change="getChartData()"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4" md="4">
        <b-card>
          <b-card-title>單車狀態</b-card-title>
          <div class="small text-muted">{{ $moment().format('HH:mm DD MMM YYYY') }}</div>
          <div ref="chartWrap1">
            <ve-pie :data="bikeStatusChartData" ref="chart1"></ve-pie>
          </div>
        </b-card>
      </b-col>
      <b-col sm="8" md="8">
        <b-card>
          <b-card-title>週轉分析</b-card-title>
          <div class="small text-muted">{{ $moment().format('HH:mm DD MMM YYYY') }}</div>
          <div ref="chartWrap2">
            <ve-line :data="turnoverChartData" :extend="extend" :settings="turnoverChartSettings" ref="chart2"></ve-line>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4" md="4">
        <b-card>
          <b-card-title>飽和狀態</b-card-title>
          <div class="small text-muted">{{ $moment().format('HH:mm DD MMM YYYY') }}</div>
          <div ref="chartWrap3">
            <ve-pie :data="stationStatusChartData" ref="chart3"></ve-pie>
          </div>
        </b-card>
      </b-col>
      <b-col sm="8" md="8">
        <b-card>
          <b-card-title>飽和分析</b-card-title>
          <div class="small text-muted">{{ $moment().format('HH:mm DD MMM YYYY') }}</div>
          <div ref="chartWrap4">
            <ve-histogram :data="loadRateChartData" :extend="extend" :settings="loadRateChartSettings" ref="chart4"></ve-histogram>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <loading
      :show="show"
      :label="label"
      :overlay="overlay">
      <GridLoader slot="loading-spinner"
                  color="#f5a623"
                  :size=10
                  style="display:inline-block;"
                  >
      </GridLoader>
    </loading>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from 'vue-full-loading'
import elementResizeDetectorMaker from 'element-resize-detector'

export default {
  name: 'bikeList',
  components: {
    loading
  },
  data: function () {
    return {
      show: false,
      label: 'Loading...',
      overlay: true,
      roles: this.$store.getters.roles,
      providerSearch: '',
      providerOptions: [{ value: '', text: '全部廠商' }],
      beforeTimeSearch: 6,
      beforeTimeOptions: [
        { value: 1, text: '1小時前' },
        { value: 2, text: '2小時前' },
        { value: 3, text: '3小時前' },
        { value: 4, text: '4小時前' },
        { value: 5, text: '5小時前' },
        { value: 6, text: '6小時前' },
        { value: 7, text: '7小時前' },
        { value: 8, text: '8小時前' },
        { value: 9, text: '9小時前' },
        { value: 10, text: '10小時前' },
        { value: 11, text: '11小時前' },
        { value: 12, text: '12小時前' },
        { value: 13, text: '13小時前' },
        { value: 14, text: '14小時前' },
        { value: 15, text: '15小時前' },
        { value: 16, text: '16小時前' },
        { value: 17, text: '17小時前' },
        { value: 18, text: '18小時前' },
        { value: 19, text: '19小時前' },
        { value: 20, text: '20小時前' },
        { value: 21, text: '21小時前' },
        { value: 22, text: '22小時前' },
        { value: 23, text: '23小時前' },
        { value: 24, text: '24小時前' }
      ],
      bikeStatusChartData: {
        columns: ['bikeStatus', 'count'],
        rows: []
      },
      extend: {
        'xAxis.0.axisLabel.rotate': 25
      },
      turnoverChartSettings: {
        labelMap: {
          startCount: '租車數',
          endCount: '還車數'
        }
      },
      turnoverChartData: {
        columns: ['createAt', 'startCount', 'endCount'],
        rows: []
      },
      stationStatusChartData: {
        columns: ['stationStatus', 'count'],
        rows: []
      },
      loadRateChartSettings: {
        labelMap: {
          normalCount: '正常運作',
          noBikeCount: '無可租借車輛',
          lockFullCount: '已停滿'
        }
      },
      loadRateChartData: {
        columns: ['createAt', 'normalCount', 'noBikeCount', 'lockFullCount'],
        rows: []
      }
    }
  },
  computed: {
  },
  mounted: function () {
    this.getProviderInfo()
    this.getChartData()
    // v-chart RWD
    const contall1 = this.$refs.chartWrap1
    const chart1 = this.$refs.chart1
    const erd1 = elementResizeDetectorMaker({
      strategy: 'scroll'
    })
    erd1.listenTo(contall1, function () {
      chart1.echarts.resize()
    })
    const contall2 = this.$refs.chartWrap2
    const chart2 = this.$refs.chart2
    const erd2 = elementResizeDetectorMaker({
      strategy: 'scroll'
    })
    erd2.listenTo(contall2, function () {
      chart2.echarts.resize()
    })
    const contall3 = this.$refs.chartWrap3
    const chart3 = this.$refs.chart3
    const erd3 = elementResizeDetectorMaker({
      strategy: 'scroll'
    })
    erd3.listenTo(contall3, function () {
      chart3.echarts.resize()
    })
    const contall4 = this.$refs.chartWrap4
    const chart4 = this.$refs.chart4
    const erd4 = elementResizeDetectorMaker({
      strategy: 'scroll'
    })
    erd4.listenTo(contall4, function () {
      chart4.echarts.resize()
    })
  },
  methods: {
    getProviderInfo () {
      if (this.roles === 1) {
        this.$store.dispatch('getProviderInfo').then(res => {
          this.providerOptions = [{ value: '', text: '全部廠商' }]
          this.createProviderOptions = [{ value: '', text: '請選擇廠商....', disabled: true }]
          for (let i in res) {
            this.providerOptions.push({
              value: res[i].PID,
              text: res[i].providerName
            })
            this.createProviderOptions.push({
              value: res[i].PID,
              text: res[i].providerName
            })
          }
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    getChartData () {
      this.getBikeStatusCount()
      this.getDailyStationTurnoverCountByPID()
      this.getStationStatusCount()
      this.getDailyStationLoadRateByPID()
    },
    getBikeStatusCount () {
      this.$store.dispatch('getBikeStatusCount', { PID: this.providerSearch }).then(res => {
        this.bikeStatusChartData.rows = []
        for (let i in res) {
          switch (res[i].bikeStatus) {
            case 'idle':
              res[i].bikeStatus = '閒置中'
              break
            case 'renting':
              res[i].bikeStatus = '租用中'
              break
            case 'disable':
              res[i].bikeStatus = '停用'
              break
            default:
          }
        }
        this.bikeStatusChartData.rows = res
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    },
    getDailyStationTurnoverCountByPID () {
      this.$store.dispatch('getDailyStationTurnoverCountByPID', { PID: this.providerSearch, beforeTime: this.beforeTimeSearch }).then(res => {
        this.turnoverChartData.rows = []
        for (let i in res) {
          res[i].createAt = this.$moment(res[i].createAt).format('YYYY-MM-DD HH:mm:ss')
        }
        this.turnoverChartData.rows = res
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    },
    getStationStatusCount () {
      this.$store.dispatch('getStationStatusCount', { PID: this.providerSearch }).then(res => {
        this.stationStatusChartData.rows = []
        for (let i in res) {
          res[i].stationStatus = (res[i].stationStatus === 'normal' ? '正常運作' : res[i].stationStatus === 'noBike' ? '無可租借車輛' : res[i].stationStatus === 'lockFull' ? '已停滿' : '站點維護')
        }
        this.stationStatusChartData.rows = res
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    },
    getDailyStationLoadRateByPID () {
      this.$store.dispatch('getDailyStationLoadRateByPID', { PID: this.providerSearch, beforeTime: this.beforeTimeSearch }).then(res => {
        this.loadRateChartData.rows = []
        for (let i in res) {
          res[i].createAt = this.$moment(res[i].createAt).format('YYYY-MM-DD HH:mm:ss')
        }
        this.loadRateChartData.rows = res
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
