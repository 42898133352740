<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col md="12">
        <b-card-group columns>
          <b-card>
            <b-row>
              <b-col>
                <div class="alignCenter">
                  <b-img
                    id="circleImg"
                    v-bind="mainProps"
                    rounded="circle"
                    alt="Circle image"
                    style="width: 30%;"
                  ></b-img>
                  <br />
                  <b-button variant="outline-primary" size="sm" disabled>{{$t('changeAvatar')}}</b-button>
                </div>
                <hr />
                <b-col md="10" class="marginAuto">
                  <b-form-group :label="$t('displayName')" label-for="displayName" :label-cols="4">
                    <b-input-group>
                      <b-form-input
                        id="displayName"
                        v-model="personal.displayName"
                        type="text"
                        placeholder="Name"
                        :plaintext="displayNameEdit"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="toggleEdit('displayName')"
                          v-show="displayNameEdit"
                        >
                          <i class="icon-pencil"></i>
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          @click="updatePersonal('displayName')"
                          v-show="!displayNameEdit"
                        >
                          <i class="icon-check"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group :label="$t('phone')" label-for="phone" :label-cols="4">
                    <b-input-group>
                      <b-form-input
                        id="phone"
                        v-model="personal.phone"
                        type="text"
                        placeholder="Phone"
                        :plaintext="phoneEdit"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="toggleEdit('phone')"
                          v-show="phoneEdit"
                        >
                          <i class="icon-pencil"></i>
                        </b-button>
                        <b-button
                          variant="outline-primary"
                          @click="updatePersonal('phone')"
                          v-show="!phoneEdit"
                        >
                          <i class="icon-check"></i>
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group :label="$t('username')" label-for="username" :label-cols="4">
                    <b-input-group>
                      <b-form-input
                        id="username"
                        v-model="personal.username"
                        type="text"
                        placeholder="Username"
                        plaintext
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group :label="$t('company')" label-for="providerName" :label-cols="4">
                    <b-input-group>
                      <b-form-input
                        id="providerName"
                        v-model="personal.providerName"
                        type="text"
                        placeholder="Company"
                        plaintext
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                  <b-form-group :label="$t('role')" label-for="permission" :label-cols="4">
                    <b-input-group>
                      <b-form-input
                        id="permission"
                        v-if="personal.roles === 1"
                        :value="$t('rootPermission')"
                        type="text"
                        placeholder="Permission"
                        plaintext
                      ></b-form-input>
                      <b-form-input
                        id="permission"
                        v-if="personal.roles === 2"
                        :value="$t('adminPermission')"
                        type="text"
                        placeholder="Permission"
                        plaintext
                      ></b-form-input>
                      <b-form-input
                        id="permission"
                        v-if="personal.roles === 3"
                        :value="$t('maintainPermission')"
                        type="text"
                        placeholder="Permission"
                        plaintext
                      ></b-form-input>
                      <b-form-input
                        id="permission"
                        v-if="personal.roles === 4"
                        :value="$t('providerPermission')"
                        type="text"
                        placeholder="Permission"
                        plaintext
                      ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <b-row>
              <b-col md="11" class="marginAuto">
                <b-form-group :label="$t('changePassword')" label-for="password" :label-cols="4">
                  <b-form-input
                    id="password"
                    type="password"
                    placeholder="new password"
                    v-model="password.password"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  :label="$t('passwordDoubleCheck')"
                  label-for="comfirmPassword"
                  :label-cols="4"
                >
                  <b-form-input
                    id="comfirmPassword"
                    type="password"
                    placeholder="confirm new password"
                    v-model="password.confirmPassword"
                  ></b-form-input>
                </b-form-group>
                <hr />
                <b-button
                  variant="primary"
                  @click="updatePersonal('password')"
                  class="action-btn"
                >{{$t('ok')}}</b-button>
                <b-button variant="secondary" @click="clearResetPassword()">{{$t('clear')}}</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
.marginAuto {
  margin: auto;
}
.alignCenter {
  text-align: center;
}
#userForm {
  margin-top: 1rem;
}
#circleImg {
  margin: 0.5rem auto;
}
#cancelPassword {
  margin-left: 0.5rem;
}
</style>

<script>
import loading from "vue-full-loading";

export default {
  name: "personal",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      mainProps: { src: "img/avatars/6.png", class: "m1" },
      displayNameEdit: true,
      phoneEdit: true,
      personal: {
        displayName: "",
        username: "",
        providerName: "",
        roles: "",
        phone: ""
      },
      password: { password: "", confirmPassword: "" }
    };
  },
  computed: {},
  mounted() {
    this.getPersonal();
  },
  methods: {
    getPersonal() {
      this.$store
        .dispatch("getPersonal")
        .then(res => {
          this.personal = res[0];
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    toggleEdit(e) {
      if (e === "displayName") this.displayNameEdit = !this.displayNameEdit;
      if (e === "phone") this.phoneEdit = !this.phoneEdit;
    },
    updatePersonal(label) {
      let data = {};
      if (label === "displayName") data.displayName = this.personal.displayName;
      if (label === "phone") data.phone = this.personal.phone;
      if (label === "password") {
        if (
          this.password.password.length > 0 &&
          this.password.password === this.password.confirmPassword
        ) {
          data.password = this.password.password;
        } else {
          this.$swal("Warning", this.$t("passwordWrong"), "warning");
          return;
        }
      }
      this.$store
        .dispatch("updatePersonal", data)
        .then(res => {
          if (label === "displayName")
            this.displayNameEdit = !this.displayNameEdit;
          if (label === "phone") this.phoneEdit = !this.phoneEdit;
          if (label === "password") this.clearResetPassword();
          this.$swal("Success", this.$t("updateSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    clearResetPassword() {
      this.password = { password: "", confirmPassword: "" };
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
