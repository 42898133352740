import 'core-js/es6/promise'
import 'core-js/es6/string'
import 'core-js/es7/array'

import Vue from 'vue'
import VueMoment from 'vue-moment'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/permission'
import BootstrapVue from 'bootstrap-vue'
import { ServerTable, ClientTable } from 'vue-tables-2'
import * as VueGoogleMaps from 'vue2-google-maps'
import VCharts from 'v-charts'
import VueProgressBar from 'vue-progressbar'
import { VueSpinners } from '@saeris/vue-spinners'
import VueSwal from 'vue-swal'
import i18n from '@/lang/i18n'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(VueSpinners)
Vue.use(ClientTable, {}, false, 'bootstrap4', 'default') // Client table
Vue.use(ServerTable, {}, false, 'bootstrap4', 'default') // Server table
Vue.use(VueMoment, {})
Vue.use(VCharts)
Vue.use(VueSwal)
Vue.use(VueProgressBar, {
  color: '#ff9a00',
  failedColor: '#ff0000',
  thickness: '3px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyAFjb4exlKudJ5sXJ2v2DRIn_i26HpcJV0',
    key: 'AIzaSyAZ1L198049D5eRTvsAl2biSgauenYnT4s',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  }

  /// / If you intend to programmatically custom event listener code
  /// / (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  /// / instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  /// / you might need to turn this on.
  // autobindAllEvents: false,

  /// / If you want to manually install components, e.g.
  /// / import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  /// / Vue.component('GmapMarker', GmapMarker)
  /// / then disable the following:
  // installComponents: true,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
