<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_warning')}}</strong>
          </div>
          <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
          <strong>{{$t('stationWarning')}}</strong>
          <hr />
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="stationStatus" slot-scope="props">
                <font v-if="props.row.stationStatus === 'lockFull'" class="text-danger">
                  <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
                  {{$t('lockFullCount')}}
                </font>
                <font v-if="props.row.stationStatus === 'noBike'" class="text-danger">
                  <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
                  {{$t('noBikeCount')}}
                </font>
                <font v-if="props.row.stationStatus === 'maintain'" class="text-danger">
                  <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
                  {{$t('stationMaintain')}}
                </font>
                <font v-if="props.row.stationStatus === 'normal'">{{$t('normalCount')}}</font>
              </div>
              <div slot="turnoverCount" slot-scope="props">
                <font v-if="props.row.turnoverCount <= 5" class="text-danger">
                  <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
                  {{props.row.turnoverCount}}
                </font>
                <font v-if="props.row.turnoverCount > 5">{{props.row.turnoverCount}}</font>
              </div>
              <!-- <div slot="warnAt" slot-scope="props">
                <font class="text-danger">{{props.row.warnAt}}</font>
              </div>-->
              <!-- <div slot="action" class="text-center" slot-scope="props">
                <b-button v-if="props.row.LPID !== null" class="action-btn" @click="lockInfo(props.row)" variant="outline-primary">詳細資料</b-button>
                <b-button v-if="props.row.LPID === null" class="action-btn" variant="outline-primary" disabled="true">詳細資料</b-button>
              </div>-->
            </v-client-table>
          </div>
          <hr />
          <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
          <strong>{{$t('bikeWarning')}}</strong>
          <hr />
          <div>
            <v-client-table :columns="bikeColumns" :data="bikeData" :options="bikeOptions">
              <div slot="bikeStatus" slot-scope="props">
                <font v-if="props.row.bikeStatus === 'renting'">{{$t('renting')}}</font>
                <font v-if="props.row.bikeStatus === 'idle'">{{$t('idle')}}</font>
                <font v-if="props.row.bikeStatus === 'disable'">{{$t('disabled')}}</font>
              </div>
              <div slot="bikeType" slot-scope="props">
                <font v-if="props.row.bikeType === 'bike'">{{$t('bike')}}</font>
                <font v-if="props.row.bikeType === 'wheelchair'">{{$t('wheelchair')}}</font>
              </div>
              <div slot="bikeRate" slot-scope="props">
                <font class="text-danger">
                  <i class="fa fa-exclamation-circle" style="margin-right: .5rem;"></i>
                  {{props.row.bikeRate}}
                </font>
              </div>
              <!-- <div slot="action" class="text-center" slot-scope="props">
                <b-button v-if="props.row.LPID !== null" class="action-btn" @click="lockInfo(props.row)" variant="outline-primary">詳細資料</b-button>
                <b-button v-if="props.row.LPID === null" class="action-btn" variant="outline-primary" disabled="true">詳細資料</b-button>
              </div>-->
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="lockInfoModal"
      :title="$t('dataInfo')"
      header-bg-variant="primary"
      size="lg"
      hide-footer
      on-stacking
    >
      <b-card class="card-accent-info">
        <div slot="header">
          <i class="fa fa-info"></i>
          {{$t('dataInfo')}}
        </div>
        <b-row>
          <b-col cols="12" sm="6">
            <p>{{$t('vendorName')}} : {{ lockInfoData.providerName }}</p>
            <p>{{$t('stationName')}} : {{ lockInfoData.stationName }}</p>
            <p>{{$t('spaceName')}} : {{ lockInfoData.spaceName }}</p>
            <p v-if="lockInfoData.spaceEnabled === 1">{{$t('spaceEnabled')}} : {{$t('enabled')}}</p>
            <p v-if="lockInfoData.spaceEnabled === 0">{{$t('spaceEnabled')}} : {{$t('disabled')}}</p>
            <p>{{$t('spaceEnabledTime')}} : {{ lockInfoData.enabledAt }}</p>
          </b-col>
          <b-col cols="12" sm="6">
            <p>{{$t('lockPileID')}} : {{ lockInfoData.lockId }}</p>
            <p>{{$t('lockPileAddress')}} : {{ lockInfoData.lockAddress }}</p>
            <p>
              {{$t('lockPileStatus')}} :
              <font
                v-if="lockInfoData.lockStatus === 'locked'"
              >{{$t('bikeLocked')}}</font>
              <font v-if="lockInfoData.lockStatus === 'unlocked'">{{$t('bikeUnlocked')}}</font>
              <font v-if="lockInfoData.lockStatus === 'maintain'">{{$t('maintain')}}</font>
            </p>
            <p>{{$t('deviceBattery')}} : {{ lockInfoData.lockBattery }}</p>
            <p>{{$t('bikeType')}} : {{ lockInfoData.bikeType }}</p>
            <p>{{$t('lockPileUpdateAt')}} : {{ lockInfoData.updateAt }}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";

export default {
  name: "warningSystem",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      roles: this.$store.getters.roles,
      providerSearch: "",
      providerOptions: [{ value: "", text: this.$t("allProvider") }],
      stationSearch: "",
      stationOptions: [{ value: "", text: this.$t("allStation") }],
      lockInfoModal: false,
      lockInfoData: {},
      columns: [
        "providerName",
        "stationName",
        "stationStatus",
        "turnoverCount",
        "warnAt"
      ],
      data: [],
      options: {
        headings: {
          providerName: this.$t("providerName"),
          stationName: this.$t("stationName"),
          stationStatus: this.$t("stationStatus"),
          turnoverCount: this.$t("turnoverRate"),
          warnAt: this.$t("warnAt")
        },
        sortable: [
          "providerName",
          "stationName",
          "stationStatus",
          "turnoverCount",
          "warnAt"
        ],
        filterable: [
          "providerName",
          "stationName",
          "stationStatus",
          "turnoverCount",
          "warnAt"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      },
      bikeColumns: [
        "providerName",
        "bikeTagId",
        "bikeType",
        "bikeStatus",
        "bikeRate",
        "bikeRateCount",
        "usageCount",
        "stationName"
      ],
      bikeData: [],
      bikeOptions: {
        headings: {
          providerName: this.$t("providerName"),
          bikeTagId: this.$t("tagID"),
          bikeType: this.$t("type"),
          bikeStatus: this.$t("status"),
          bikeRate: this.$t("rate"),
          bikeRateCount: this.$t("rateCount"),
          usageCount: this.$t("useCount"),
          stationName: this.$t("bikelocate") /*,
          'action': '動作'*/
        },
        sortable: [
          "providerName",
          "bikeTagId",
          "bikeType",
          "bikeStatus",
          "bikeRate",
          "bikeRateCount",
          "usageCount",
          "stationName"
        ],
        filterable: [
          "providerName",
          "bikeTagId",
          "bikeType",
          "bikeStatus",
          "bikeRate",
          "bikeRateCount",
          "usageCount",
          "stationName"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      }
    };
  },
  computed: {},
  mounted: function() {
    this.getWarnList();
  },
  methods: {
    getWarnList() {
      this.show = true;
      this.$store
        .dispatch("getWarnList")
        .then(res => {
          let station = res.station;
          let bike = res.bike;
          this.data = [];
          for (let i in station) {
            station[i].warnAt = station[i].warnAt
              ? this.$moment(station[i].warnAt).format("YYYY-MM-DD HH:mm:ss")
              : this.$moment().format("YYYY-MM-DD 00:00:00");
          }
          this.data = station;
          this.bikeData = bike;
          this.show = false;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
          this.show = false;
        });
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
