import request from '@/utils/request'

export function getLockData (searchData) {
  return request({
    url: '/lock/lockData', // 原 searchLock
    method: 'get',
    params: searchData
  })
}

export function updateLock (editData) {
  const data = {
    editData
  }
  return request({
    url: '/lock/updateLock',
    method: 'post',
    data
  })
}

export function importExcelLock (importExcelData) {
  const data = {
    importExcelData
  }
  return request({
    url: '/lock/importExcelLock',
    method: 'post',
    data
  })
}
