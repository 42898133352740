import request from '@/utils/request'

export function getStationByPID (PID) {
  return request({
    url: '/lockStation/StationByPID',
    method: 'get',
    params: PID
  })
}

export function storeStation (createData) {
  const data = {
    createData
  }
  return request({
    url: '/lockStation/storeStation',
    method: 'post',
    data
  })
}

export function updateStation (editData) {
  const data = {
    editData
  }
  return request({
    url: '/lockStation/updateStation',
    method: 'post',
    data
  })
}

export function destroyStation (LSID) {
  const data = {
    LSID
  }
  return request({
    url: '/lockStation/destroyStation',
    method: 'post',
    data
  })
}

export function setStationLocation (locationData) {
  const data = {
    locationData
  }
  return request({
    url: '/lockStation/setStationLocation',
    method: 'post',
    data
  })
}

export function getDailyStationTurnoverCount (LSID) {
  return request({
    url: '/lockStation/dailyStationTurnoverCount',
    method: 'get',
    params: LSID
  })
}

export function getDailyStationLoadRate (LSID) {
  return request({
    url: '/lockStation/dailyStationLoadRate',
    method: 'get',
    params: LSID
  })
}

export function getDailyStationTurnoverCountByPID (PID) {
  return request({
    url: '/lockStation/dailyStationTurnoverCountByPID',
    method: 'get',
    params: PID
  })
}

export function getStationStatusCount (PID) {
  return request({
    url: '/lockStation/stationStatusCount',
    method: 'get',
    params: PID
  })
}

export function getDailyStationLoadRateByPID (PID) {
  return request({
    url: '/lockStation/dailyStationLoadRateByPID',
    method: 'get',
    params: PID
  })
}
