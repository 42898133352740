<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-cog" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_bike')}}</strong>
          </div>
          <b-row>
            <b-col cols="4" sm="5" md="6" class="text-center" v-if="roles === 1">
              <b-form-select v-model="providerSearch" :options="providerOptions"></b-form-select>
            </b-col>
            <b-col cols="4" sm="5" md="5" class="text-center" v-if="roles === 1">
              <b-form-input v-model="bikeTagIdSearch" type="text" trim placeholder="- bikeTagId"></b-form-input>
            </b-col>
            <b-col cols="8" sm="10" md="11" class="text-center" v-if="roles !== 1">
              <b-form-input v-model="bikeTagIdSearch" type="text" trim placeholder="- bikeTagId"></b-form-input>
            </b-col>
            <b-col cols="4" sm="2" md="1" class="text-center">
              <b-button class="action-btn" variant="info" @click="getBikeData()">{{$t('search')}}</b-button>
            </b-col>
          </b-row>
          <hr />
          <!-- <b-row v-if="roles === 1">
            <b-col cols="6" sm="7" class="d-flex align-items-center"></b-col>
            <b-col cols="6" sm="5" class="d-flex justify-content-end">
              <b-form-file v-model="importExcelFile" browse-text="匯入Excel" style="width: 250px;" @change="importExcel"></b-form-file>
              <b-button variant="outline-primary" style="margin-left: .5rem;" @click="excelDemoModal=true">Excel規範</b-button>
            </b-col>
          </b-row>
          <hr v-if="roles === 1">-->
          <div>
            <font class="text-muted">{{ searchInfoBar }}</font>
          </div>
          <hr />
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <!-- <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" @click="editBike(props.row)" variant="outline-warning" v-if="roles === 1">編輯</b-button>
              </div>-->
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="editBikeModal" :title="$t('editBike')" header-bg-variant="warning" ok-only>
      <b-form-group :label="$t('bikeTagID')" label-for="bikeTagId" :label-cols="3">
        <b-form-input id="bikeTagId" type="text" v-model="editData.bikeTagId" trim plaintext></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('providerName')" label-for="PID" :label-cols="3">
        <b-form-select id="PID" v-model="editData.PID" trim :options="editProviderOptions"></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editBikeModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="updateBike()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      :title="$t('excelSpecification')"
      header-bg-variant="primary"
      v-model="excelDemoModal"
      hide-footer
    >
      <div class="d-flex justify-content-between">
        <h5>{{$t('providerList')}}</h5>
        <a
          href="ExcelDemo/excelDemoBike.xlsx"
          target="_blank"
          class="text-decoration-none"
        >{{$t('excelDownload')}}</a>
      </div>
      <hr />
      <v-client-table :columns="pColumns" :data="pData" :options="pOptions"></v-client-table>
    </b-modal>

    <b-modal :title="$t('importExcel')" header-bg-variant="success" v-model="importExcelModal">
      <h6>{{$t('confirmDataMsg')}}</h6>
      <hr />
      <v-client-table
        :columns="importExcelColumns"
        :data="importExcelData"
        :options="importExcelOptions"
      ></v-client-table>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="importExcelCheck()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";
import xlsx from "xlsx";

export default {
  name: "bike",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      roles: this.$store.getters.roles,
      searchInfoBar: "",
      providerSearch: "",
      providerOptions: [{ value: "", text: this.$t("allProvider") }],
      bikeTagIdSearch: "",
      editProviderOptions: [{ value: null, text: this.$t("noProvider") }],
      editBikeModal: false,
      editData: {},
      excelDemoModal: false,
      importExcelModal: false,
      importExcelFile: "",
      columns: ["bikeTagId", "providerName", "stationName", "spaceName"],
      data: [],
      options: {
        headings: {
          bikeTagId: this.$t("bikeTagID"),
          providerName: this.$t("vendorName"),
          stationName: this.$t("stationName"),
          spaceName: this.$t("spaceName")
        },
        sortable: ["bikeTagId", "providerName", "stationName", "spaceName"],
        filterable: ["bikeTagId", "providerName", "stationName", "spaceName"],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      },
      pColumns: ["PID", "providerName"],
      pData: [],
      pOptions: {
        headings: {
          PID: this.$t("PID"),
          providerName: this.$t("vendorName")
        },
        sortable: ["PID", "providerName"],
        filterable: ["PID", "providerName"],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      },
      importExcelColumns: ["bikeTagId", "PID"],
      importExcelData: [],
      importExcelOptions: {
        headings: {
          bikeTagId: this.$t("bikeTagID"),
          PID: this.$t("PID")
        },
        sortable: ["bikeTagId", "PID"],
        filterable: ["bikeTagId", "PID"],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      }
    };
  },
  computed: {},
  mounted: function() {
    this.getProviderInfo();
  },
  methods: {
    getProviderInfo() {
      if (this.roles === 1) {
        this.$store
          .dispatch("getProviderInfo")
          .then(res => {
            this.providerOptions = [
              { value: "", text: this.$t("allProvider") },
              { value: "null", text: this.$t("noProvider") }
            ];
            this.editProviderOptions = [
              { value: null, text: this.$t("noProvider") }
            ];
            for (let i in res) {
              this.providerOptions.push({
                value: res[i].PID,
                text: res[i].providerName
              });
              this.editProviderOptions.push({
                value: res[i].PID,
                text: res[i].providerName
              });
              this.pData.push({
                PID: res[i].PID,
                providerName: res[i].providerName
              });
            }
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    getBikeData() {
      let startTime = this.$moment();
      this.show = true;
      this.data = [];
      this.$store
        .dispatch("getBikeData", {
          PID: this.providerSearch,
          bikeTagId: this.bikeTagIdSearch
        })
        .then(res => {
          for (let i in res) {
            if (res[i].spaceName === null) {
              res[i].spaceName = this.$t("noSpace");
            }
            if (res[i].stationName === null) {
              res[i].stationName = this.$t("noStation");
            }
            if (res[i].providerName === null) {
              res[i].providerName = this.$t("noProvider");
            }
            this.data.push(res[i]);
          }
          this.searchInfoBar =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.data.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
          this.show = false;
        })
        .catch(e => {
          this.searchInfoBar = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
          this.show = false;
        });
    },
    editBike(prop) {
      this.editData = prop;
      this.editBikeModal = true;
    },
    updateBike() {
      this.$store
        .dispatch("updateBike", {
          BID: this.editData.BID,
          PID: this.editData.PID
        })
        .then(res => {
          this.editBikeModal = false;
          this.getBikeData();
          this.$swal("Success", this.$t("bikeUpdateSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    importExcel(e) {
      this.importExcelData = [];
      const files = e.target.files;
      if (!files.length > 0) {
        return false;
      } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$swal("Error", this.$t("excelFileError"), "error");
        return false;
      }
      const fileReader = new FileReader();
      fileReader.onload = ev => {
        try {
          const data = ev.target.result;
          const workbook = xlsx.read(data, {
            type: "binary"
          });
          const wsname = workbook.SheetNames[0];
          const ws = xlsx.utils.sheet_to_json(workbook.Sheets[wsname]);

          this.importExcelData = ws;
          this.importExcelModal = true;
        } catch (e) {
          console.log(e);
          return false;
        }
      };
      fileReader.readAsBinaryString(files[0]);
    },
    importExcelCheck() {
      this.$store
        .dispatch("importExcelBike", this.importExcelData)
        .then(res => {
          this.importExcelFile = "";
          this.importExcelModal = false;
          this.$swal(
            "Success",
            this.$t("importExceldata") +
              ": " +
              this.importExcelData.length +
              ", " +
              this.$t("importExcelSuccess") +
              ": " +
              res.importExcelSuccessLength +
              ", " +
              this.$t("importExcelFail") +
              ": " +
              res.importExcelFailLength,
            "success"
          );
          this.importExcelData = [];
          this.getBikeData();
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
