<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-list-ul" style="margin-right: .5rem;"></i>
            <strong>車位一覽</strong>
          </div>
          <b-row>
            <b-col cols="4" sm="5" md="6" class="text-center" v-if="roles === 1">
              <b-form-select
              v-model="providerSearch"
              :options="providerOptions"
              @change="getStationByPID(providerSearch)"></b-form-select>
            </b-col>
            <b-col cols="4" sm="5" md="5" class="text-center" v-if="roles === 1">
              <b-form-select
              v-model="stationSearch"
              :options="stationOptions"></b-form-select>
            </b-col>
            <b-col cols="8" sm="10" md="11" class="text-center" v-if="roles > 1">
              <b-form-select
              v-model="stationSearch"
              :options="stationOptions"></b-form-select>
            </b-col>
            <b-col cols="4" sm="2" md="1" class="text-center">
              <b-button class="action-btn" variant="info" @click="getSpaceByLSID()">搜尋</b-button>
            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="lockStatus" slot-scope="props">
                <p v-if="props.row.lockStatus === 'locked'">鎖車</p>
                <p v-if="props.row.lockStatus === 'unlocked'">無鎖車</p>
                <p v-if="props.row.lockStatus === 'maintain'">維護</p>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" @click="spaceInfo(props.row)" variant="outline-primary">詳細資料</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="spaceInfoModal" title="詳細資料" header-bg-variant="primary" size="xl" hide-footer on-stacking>
      <b-card>
        <b-card-title>週轉分析</b-card-title>
        <div class="small text-muted">{{ $moment().format('DD MMM YYYY') }}</div>
        <div ref="chartWrap">
          <ve-line :data="chartData" :extend="extend" :settings="chartSettings" ref="chart" :judge-width="true"></ve-line>
        </div>
      </b-card>
    </b-modal>

    <loading
      :show="show"
      :label="label"
      :overlay="overlay">
      <GridLoader slot="loading-spinner"
                  color="#f5a623"
                  :size=10
                  style="display:inline-block;"
                  >
      </GridLoader>
    </loading>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from 'vue-full-loading'

export default {
  name: 'spaceList',
  components: {
    loading
  },
  data: function () {
    return {
      show: false,
      label: 'Loading...',
      overlay: true,
      roles: this.$store.getters.roles,
      providerSearch: '',
      providerOptions: [{ value: '', text: '全部廠商' }],
      stationSearch: '',
      stationOptions: [{ value: '', text: '全部站點' }],
      spaceInfoModal: false,
      extend: {
        'xAxis.0.axisLabel.rotate': 25
      },
      chartSettings: {
        labelMap: {
          startCount: '租車數',
          endCount: '還車數'
        }
      },
      chartData: {
        columns: ['createAt', 'startCount', 'endCount'],
        rows: []
      },
      columns: [ 'stationName', 'spaceName', 'lockId', 'lockStatus', 'usageCount', 'turnoverCount', 'repairCount', 'reportCount', 'action' ],
      data: [],
      options: {
        headings: {
          'stationName': '站點名稱',
          'spaceName': '車位名稱',
          'lockId': '車鎖ID',
          'lockStatus': '車鎖狀態',
          'usageCount': '使用數',
          'turnoverCount': '週轉率',
          'repairCount': '維修數',
          'reportCount': '客訴數',
          'action': '動作'
        },
        sortable: [ 'stationName', 'spaceName', 'lockId', 'lockStatus', 'usageCount', 'turnoverCount', 'repairCount', 'reportCount' ],
        filterable: [ 'stationName', 'spaceName', 'lockId', 'lockStatus', 'usageCount', 'turnoverCount', 'repairCount', 'reportCount' ],
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        }
      }
    }
  },
  computed: {
  },
  mounted: function () {
    this.getProviderInfo()
  },
  methods: {
    getProviderInfo () {
      if (this.roles > 1) {
        this.getStationByPID(this.$store.getters.PID)
      } else {
        this.$store.dispatch('getProviderInfo').then(res => {
          this.providerOptions = [{ value: '', text: '全部廠商' }]
          this.createProviderOptions = [{ value: '', text: '請選擇廠商....', disabled: true }]
          for (let i in res) {
            this.providerOptions.push({
              value: res[i].PID,
              text: res[i].providerName
            })
            this.createProviderOptions.push({
              value: res[i].PID,
              text: res[i].providerName
            })
          }
        }).catch(e => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    getStationByPID (p) {
      if (p.length > 0) {
        this.$store.dispatch('getStationByPID', { PID: p }).then((res) => {
          this.stationOptions = [{ value: '', text: '全部站點' }]
          for (let i in res) {
            this.stationOptions.push({
              value: res[i].LSID,
              text: res[i].stationName
            })
          }
        }).catch((e) => {
          this.$swal('Error', e, 'error')
        })
      }
    },
    getSpaceByLSID () {
      this.show = true
      this.$store.dispatch('getSpaceByLSID', { PID: this.providerSearch, LSID: this.stationSearch }).then((res) => {
        this.data = []
        this.data = res
        this.show = false
      }).catch((e) => {
        this.$swal('Error', e, 'error')
        this.show = false
      })
    },
    spaceInfo (prop) {
      this.$store.dispatch('getDailySpaceTurnoverCount', { LSSID: prop.LSSID }).then(res => {
        this.chartData.rows = []
        for (let i in res) {
          res[i].createAt = this.$moment(res[i].createAt).format('YYYY-MM-DD HH:mm:ss')
          this.chartData.rows.push(res[i])
        }
        this.spaceInfoModal = true
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
