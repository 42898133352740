<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-calculator" style="margin-right: .5rem;"></i>
            <strong>{{$t('billingPlan')}}</strong>
          </div>
          <b-row>
            <b-col cols="10" sm="10" md="10" class="text-center"></b-col>
            <b-col cols="2" sm="2" md="2" class="text-center">
              <b-button
                class="action-btn"
                variant="primary"
                @click="createPlan()"
                v-if="roles <= 2"
              >{{$t('new')}}</b-button>
            </b-col>
          </b-row>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="billingPlanEnabled" slot-scope="props">
                <p v-if="props.row.billingPlanEnabled === 1">{{$t('enabled')}}</p>
                <p v-if="props.row.billingPlanEnabled === 0">{{$t('disabled')}}</p>
              </div>
              <div slot="action" slot-scope="props" class="text-center">
                <b-button
                  class="action-btn"
                  @click="planCalc(props.row)"
                  variant="outline-primary"
                >{{$t('calculate')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="editPlan(props.row)"
                  variant="outline-warning"
                  v-if="roles <= 2"
                >{{$t('edit')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="deletePlan(props.row)"
                  variant="outline-danger"
                  v-if="roles <= 2"
                >{{$t('delete')}}</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="createPlanModal"
      :title="$t('createPlan')"
      header-bg-variant="primary"
      ok-only
    >
      <b-alert show variant="primary">{{$t('formulaMsg')}}</b-alert>
      <b-form-group
        :label="$t('planName')"
        label-for="planName"
        :label-cols="3"
        :invalid-feedback="invalidPlanName"
        :state="statePlanName"
      >
        <b-form-input
          id="planName"
          type="text"
          v-model="createData.planName"
          :state="statePlanName"
          trim
          placeholder="- planName"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('planContent')"
        label-for="planContent"
        :label-cols="3"
        :invalid-feedback="invalidPlanContent"
        :state="statePlanContent"
      >
        <b-form-input
          id="planContent"
          type="text"
          v-model="createData.planContent"
          :state="statePlanContent"
          trim
          placeholder="- planContent"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('algorithm')"
        label-for="algorithm"
        :label-cols="3"
        :invalid-feedback="invalidAlgorithm"
        :state="stateAlgorithm"
      >
        <b-form-input
          id="algorithm"
          type="text"
          v-model="createData.algorithm"
          :state="stateAlgorithm"
          trim
          placeholder="- algorithm"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="是否啟用"
        label-for="billingPlanEnabled"
        :label-cols="3"
        :state="stateBillingPlanEnabled"
      >
        <b-form-select
          id="billingPlanEnabled"
          type="number"
          v-model="createData.billingPlanEnabled"
          :state="stateBillingPlanEnabled"
          trim
          :options="billingPlanEnabledOptions"
          placeholder="- billingPlanEnabled"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="createPlanModal=false"
      >取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="storePlan()">確定</b-button>
    </b-modal>

    <b-modal v-model="editPlanModal" title="編輯計費方式" header-bg-variant="warning" ok-only>
      <b-alert show variant="primary">公式規範 : count = 使用次數, useTime = 使用時間</b-alert>
      <b-form-group :label="$t('planName')" label-for="planName" :label-cols="3">
        <b-form-input id="planName" type="text" v-model="editData.planName" trim></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('planContent')" label-for="planContent" :label-cols="3">
        <b-form-input id="planContent" type="text" v-model="editData.planContent" trim></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('algorithm')" label-for="algorithm" :label-cols="3">
        <b-form-input id="algorithm" type="text" v-model="editData.algorithm" trim></b-form-input>
      </b-form-group>
      <b-form-group label="是否啟用" label-for="billingPlanEnabled" :label-cols="3">
        <b-form-select
          id="billingPlanEnabled"
          type="number"
          v-model="editData.billingPlanEnabled"
          trim
          :options="billingPlanEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editPlanModal=false"
      >取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="updatePlan()">確定</b-button>
    </b-modal>

    <b-modal
      v-model="deletePlanModal"
      title="刪除計費方式"
      header-bg-variant="danger"
      ok-only
      on-stacking
    >
      <h6>您確定要刪除車位 {{ deleteData.planName }} 嗎？</h6>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="deletePlanModal=false"
      >取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="danger" @click="destroyPlan()">確定</b-button>
    </b-modal>

    <b-modal v-model="planCalcModal" title="計費試算" header-bg-variant="primary" hide-footer ok-only>
      <b-alert show variant="primary">公式規範 : count = 使用次數, useTime = 使用時間</b-alert>
      <b-form-group
        v-if="calcData.countIsEnabled === true"
        label="使用次數"
        label-for="count"
        :label-cols="3"
      >
        <b-form-input id="count" type="number" v-model="calcData.count" trim @input="calc()"></b-form-input>
      </b-form-group>
      <b-form-group
        v-if="calcData.useTimeIsEnabled === true"
        label="使用時間"
        label-for="useTime"
        :label-cols="3"
      >
        <b-form-input
          id="useTime"
          type="number"
          step="0.01"
          v-model="calcData.useTime"
          trim
          @input="calc()"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="試算結果" label-for="result" :label-cols="3">
        <b-form-input
          id="result"
          type="number"
          step="0.01"
          v-model="calcData.result"
          trim
          plaintext
        ></b-form-input>
      </b-form-group>
    </b-modal>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
</style>

<script>
export default {
  name: "financialTrial",
  components: {},
  data: function() {
    return {
      roles: this.$store.getters.roles,
      billingPlanEnabledOptions: [
        { value: 1, text: "啟用" },
        { value: 0, text: "停用" }
      ],
      createPlanModal: false,
      createData: {
        planName: "",
        planContent: "",
        algorithm: "",
        billingPlanEnabled: 1
      },
      editPlanModal: false,
      editData: {},
      deletePlanModal: false,
      deleteData: {},
      planCalcModal: false,
      calcData: {
        count: 0,
        useTime: 0,
        countIsEnabled: false,
        useTimeIsEnabled: false,
        result: 0,
        algorithm: ""
      },
      columns: [
        "planName",
        "planContent",
        "algorithm",
        "billingPlanEnabled",
        "action"
      ],
      data: [],
      options: {
        headings: {
          planName: "計費方式名稱",
          planContent: "計費方式內容",
          algorithm: "計費公式",
          billingPlanEnabled: "是否啟用",
          action: "動作"
        },
        sortable: [
          "planName",
          "planContent",
          "algorithm",
          "billingPlanEnabled"
        ],
        filterable: [
          "planName",
          "planContent",
          "algorithm",
          "billingPlanEnabled"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      }
    };
  },
  computed: {
    statePlanName() {
      return this.createData.planName.length > 0;
    },
    invalidPlanName() {
      return this.createData.planName.length > 0 ? "" : "必填欄位不得為空";
    },
    statePlanContent() {
      return this.createData.planContent.length > 0;
    },
    invalidPlanContent() {
      return this.createData.planContent.length > 0 ? "" : "必填欄位不得為空";
    },
    stateAlgorithm() {
      return this.createData.algorithm.length > 0;
    },
    invalidAlgorithm() {
      return this.createData.algorithm.length > 0 ? "" : "必填欄位不得為空";
    },
    stateBillingPlanEnabled() {
      return this.createData.spaceEnabled !== null;
    }
  },
  mounted: function() {
    this.getBPID();
  },
  methods: {
    getBPID() {
      this.$store
        .dispatch("getBPID")
        .then(res => {
          this.data = res;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    createPlan() {
      this.createData = {
        planName: "",
        planContent: "",
        algorithm: "",
        billingPlanEnabled: 1
      };
      this.createPlanModal = true;
    },
    storePlan() {
      this.$store
        .dispatch("storePlan", this.createData)
        .then(res => {
          this.createPlanModal = false;
          this.getBPID();
          this.$swal("Success", "計費方式新增成功", "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    editPlan(prop) {
      this.editData = prop;
      this.editPlanModal = true;
    },
    updatePlan() {
      this.$store
        .dispatch("updatePlan", this.editData)
        .then(res => {
          this.editPlanModal = false;
          this.getBPID();
          this.$swal("Success", "計費方式更新成功", "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    deletePlan(prop) {
      this.deleteData = prop;
      this.deletePlanModal = true;
    },
    destroyPlan() {
      this.$store
        .dispatch("destroyPlan", this.deleteData.BPID)
        .then(res => {
          this.deletePlanModal = false;
          this.getBPID();
          this.$swal("Success", "計費方式刪除成功", "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    planCalc(prop) {
      this.calcData = {
        count: 0,
        useTime: 0,
        countIsEnabled: false,
        useTimeIsEnabled: false,
        result: 0,
        algorithm: ""
      };
      let count = RegExp(/count/);
      let useTime = RegExp(/useTime/);
      this.calcData.algorithm = prop.algorithm;
      this.calcData.countIsEnabled = count.test(this.calcData.algorithm);
      this.calcData.useTimeIsEnabled = useTime.test(this.calcData.algorithm);
      this.planCalcModal = true;
    },
    calc() {
      let count = this.calcData.count;
      let useTime = this.calcData.useTime;
      this.calcData.result = eval(this.calcData.algorithm);
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
