<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-handshake-o" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_provider')}}</strong>
          </div>
          <!-- <b-row>
            <b-col cols="8" sm="9" md="10" class="text-center">
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-button variant="primary" @click="createProvider()">新增廠商</b-button>
            </b-col>
          </b-row>
          <hr>-->
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="providerEnabled" slot-scope="props">
                <font v-if="props.row.providerEnabled === 1">{{$t('enabled')}}</font>
                <font v-if="props.row.providerEnabled === 0">{{$t('disabled')}}</font>
              </div>
              <!-- <div slot="action" class="text-center" slot-scope="props">
                <b-button class="action-btn" @click="editProvider(props.row)" variant="outline-warning">編輯</b-button>
                <b-button class="action-btn" @click="deleteProvider(props.row)" variant="outline-danger">刪除</b-button>
              </div>-->
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="createProviderModal"
      :title="$t('createProvider')"
      header-bg-variant="primary"
      ok-only
      no-stacking
    >
      <b-form-group
        :label="$t('vendorName')"
        label-for="providerName"
        :label-cols="3"
        :invalid-feedback="invalidProviderName"
        :state="stateProviderName"
      >
        <b-form-input
          id="providerName"
          type="text"
          v-model="createData.providerName"
          :state="stateProviderName"
          trim
          placeholder="- providerName"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('status')"
        label-for="providerEnabled"
        :label-cols="3"
        :state="stateProviderEnabled"
      >
        <b-form-select
          id="providerEnabled"
          v-model="createData.providerEnabled"
          :state="stateProviderEnabled"
          trim
          :options="providerEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="createProviderModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="storeProvider()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="editProviderModal"
      :title="$t('editProvider')"
      header-bg-variant="warning"
      ok-only
      no-stacking
    >
      <b-form-group :label="$t('vendorName')" label-for="providerName" :label-cols="3">
        <b-form-input
          id="providerName"
          type="text"
          v-model="editData.providerName"
          trim
          placeholder="- providerName"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('status')" label-for="providerEnabled" :label-cols="3">
        <b-form-select
          id="providerEnabled"
          v-model="editData.providerEnabled"
          trim
          :options="providerEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editProviderModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="updateProvider()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="deleteProviderModal"
      :title="$t('deleteProvider')"
      header-bg-variant="danger"
      ok-only
      on-stacking
    >
      <h6>{{$t('deleteProviderMsg')}} {{ deleteData.providerName }} ？</h6>
      <p>{{$t('sureProviderClearMsg')}}</p>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="deleteProviderModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="danger"
        @click="destroyProvider()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";

export default {
  name: "vendor",
  components: {
    loading
  },
  data: function() {
    return {
      show: true,
      label: "Loading...",
      overlay: true,
      providerEnabledOptions: [
        { value: 1, text: this.$t("enabled") },
        { value: 0, text: this.$t("disabled") }
      ],
      createProviderModal: false,
      createData: { providerName: "", providerEnabled: 1 },
      editProviderModal: false,
      editData: {},
      deleteProviderModal: false,
      deleteData: {},
      columns: ["providerName", "providerEnabled"],
      data: [],
      options: {
        headings: {
          providerName: this.$t("vendorName"),
          providerEnabled: this.$t("status")
        },
        sortable: ["providerName", "providerEnabled"],
        filterable: ["providerName", "providerEnabled"]
      }
    };
  },
  computed: {
    stateProviderName() {
      return this.createData.providerName.length > 0;
    },
    invalidProviderName() {
      return this.createData.providerName.length > 0 ? "" : this.$t("required");
    },
    stateProviderEnabled() {
      return this.createData.providerEnabled.length !== null;
    }
  },
  mounted() {
    this.getProviderInfo();
  },
  methods: {
    getProviderInfo() {
      this.$store
        .dispatch("getProviderInfo")
        .then(res => {
          this.data = [];
          for (let i in res) {
            this.data.push({
              PID: res[i].PID,
              providerName: res[i].providerName,
              providerEnabled: res[i].providerEnabled
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    createProvider() {
      this.createData = { providerName: "", providerEnabled: 1 };
      this.createProviderModal = true;
    },
    storeProvider() {
      this.$store
        .dispatch("storeProvider", this.createData)
        .then(res => {
          this.createProviderModal = false;
          this.getProviderInfo();
          this.$swal("Suucess", this.$t("createProviderSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    editProvider(prop) {
      this.editData = prop;
      this.editProviderModal = true;
    },
    updateProvider() {
      this.$store
        .dispatch("updateProvider", this.editData)
        .then(res => {
          this.editProviderModal = false;
          this.getProviderInfo();
          this.$swal("Suucess", this.$t("updateProviderSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    deleteProvider(prop) {
      this.deleteData = prop;
      this.deleteProviderModal = true;
    },
    destroyProvider() {
      this.$store
        .dispatch("destroyProvider", this.deleteData.PID)
        .then(res => {
          this.deleteProviderModal = false;
          this.getProviderInfo();
          this.$swal("Suucess", this.$t("deleteProviderSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
