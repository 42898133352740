import request from '@/utils/request'

export function getBikeByLSID (searchData) {
  return request({
    url: '/bike/bikeByLSID',
    method: 'get',
    params: searchData
  })
}

export function getBikeData (searchData) {
  return request({
    url: '/bike/bikeData',
    method: 'get',
    params: searchData
  })
}

export function updateBike (editData) {
  const data = {
    editData
  }
  return request({
    url: '/bike/updateBike',
    method: 'post',
    data
  })
}

export function importExcelBike (importExcelData) {
  const data = {
    importExcelData
  }
  return request({
    url: '/bike/importExcelBike',
    method: 'post',
    data
  })
}

export function getDailyBikeUsageCount (BID) {
  return request({
    url: '/bike/dailyBikeUsageCount',
    method: 'get',
    params: BID
  })
}

export function getBikeStatusCount (PID) {
  return request({
    url: '/bike/bikeStatusCount',
    method: 'get',
    params: PID
  })
}
