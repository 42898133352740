import * as lockStationApi from '@/api/lockStation'

const lockStation = {
  state: {

  },
  mutations: {

  },
  actions: {
    getStationByPID ({ commit, state }, PID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getStationByPID(PID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    storeStation ({ commit }, createData) {
      return new Promise((resolve, reject) => {
        lockStationApi.storeStation(createData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateStation ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        lockStationApi.updateStation(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    destroyStation ({ commit }, LSID) {
      return new Promise((resolve, reject) => {
        lockStationApi.destroyStation(LSID).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    setStationLocation ({ commit }, locationData) {
      return new Promise((resolve, reject) => {
        lockStationApi.setStationLocation(locationData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDailyStationTurnoverCount ({ commit }, LSID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getDailyStationTurnoverCount(LSID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDailyStationLoadRate ({ commit }, LSID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getDailyStationLoadRate(LSID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDailyStationTurnoverCountByPID ({ commit }, PID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getDailyStationTurnoverCountByPID(PID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getStationStatusCount ({ commit }, PID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getStationStatusCount(PID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getDailyStationLoadRateByPID ({ commit }, PID) {
      return new Promise((resolve, reject) => {
        lockStationApi.getDailyStationLoadRateByPID(PID).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default lockStation
