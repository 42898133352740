<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-cog" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_station')}}</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="9" md="10" class="text-center">
              <b-form-select v-model="providerSearch" :options="providerOptions" v-if="roles === 1"></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-button
                class="action-btn"
                variant="info"
                @click="getStationByPID()"
              >{{$t('search')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                @click="createStation()"
                v-if="roles <= 2"
              >{{$t('createStaion')}}</b-button>
            </b-col>
          </b-row>
          <hr />
          <div>
            <font class="text-muted">{{ searchInfoBar }}</font>
          </div>
          <hr />
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="stationStatus" slot-scope="props">
                <p v-if="props.row.stationStatus === 'noBike'">{{$t('noBike')}}</p>
                <p v-if="props.row.stationStatus === 'lockFull'">{{$t('lockFullCount')}}</p>
                <p v-if="props.row.stationStatus === 'normal'">{{$t('workingNormal')}}</p>
                <p v-if="props.row.stationStatus === 'maintain'">{{$t('stationMaintain')}}</p>
              </div>
              <div
                slot="stationLock"
                slot-scope="props"
              >{{ props.row.vehicleLock }} / {{ props.row.totalLock - props.row.vehicleLock }} / {{ props.row.maintainLock }}</div>
              <div slot="stationEnabled" slot-scope="props">
                <p v-if="props.row.stationEnabled === 0">{{$t('disabled')}}</p>
                <p v-if="props.row.stationEnabled === 1">{{$t('enabled')}}</p>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button
                  class="action-btn"
                  @click="location(props.row)"
                  variant="outline-success"
                  v-if="roles <= 2"
                >{{$t('locate')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="editStation(props.row)"
                  variant="outline-warning"
                  v-if="roles <= 2"
                >{{$t('edit')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="deleteStation(props.row)"
                  variant="outline-danger"
                  v-if="roles <= 2"
                >{{$t('delete')}}</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="createStationModal"
      :title="$t('createStaion')"
      header-bg-variant="primary"
      ok-only
      no-stacking
    >
      <b-form-group
        :label="$t('provider')"
        label-for="PID"
        :label-cols="3"
        :invalid-feedback="invalidPID"
        :state="statePID"
        v-if="roles === 1"
      >
        <b-form-select
          id="PID"
          v-model="createData.PID"
          :state="statePID"
          trim
          :options="providerOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="$t('stationName')"
        label-for="stationName"
        :label-cols="3"
        :invalid-feedback="invalidStationName"
        :state="stateStationName"
      >
        <b-form-input
          id="stationName"
          type="text"
          v-model="createData.stationName"
          :state="stateStationName"
          trim
          placeholder="- stationName"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('stationLocation')"
        label-for="stationLocation"
        :label-cols="3"
        :invalid-feedback="invalidStationLocation"
        :state="stateStationLocation"
      >
        <b-form-input
          id="stationLocation"
          type="text"
          v-model="createData.stationLocation"
          :state="stateStationLocation"
          trim
          placeholder="- stationLocation"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('isEnabled')"
        label-for="stationEnabled"
        :label-cols="3"
        :state="stateStationEnabled"
      >
        <b-form-select
          id="stationEnabled"
          v-model="createData.stationEnabled"
          :state="stateStationEnabled"
          trim
          :options="stationEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="createStationModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="storeStation()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="editStationModal"
      :title="$t('editStation')"
      header-bg-variant="warning"
      ok-only
      no-stacking
    >
      <b-form-group :label="$t('provider')" label-for="PID" :label-cols="3" v-if="roles === 1">
        <b-form-select id="PID" v-model="editData.PID" trim :options="providerOptions"></b-form-select>
      </b-form-group>
      <b-form-group :label="$t('stationName')" label-for="stationName" :label-cols="3">
        <b-form-input
          id="stationName"
          type="text"
          v-model="editData.stationName"
          trim
          placeholder="- stationName"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('stationLocation')" label-for="stationLocation" :label-cols="3">
        <b-form-input
          id="stationLocation"
          type="text"
          v-model="editData.stationLocation"
          trim
          placeholder="- stationLocation"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('isEnabled')" label-for="stationEnabled" :label-cols="3">
        <b-form-select
          id="stationEnabled"
          v-model="editData.stationEnabled"
          trim
          :options="stationEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editStationModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="updateStation()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="deleteStationModal"
      :title="$t('deleteStation')"
      header-bg-variant="danger"
      ok-only
      on-stacking
    >
      <h6>{{$t('areyouSureDeleteStationMsg')}} {{ deleteData.stationName }} ？</h6>
      <p>{{$t('makeSureClearAlreadyMsg')}}</p>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="deleteStationModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="danger"
        @click="destroyStation()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="locationModal"
      :title="$t('locateSetting')"
      header-bg-variant="success"
      size="lg"
    >
      <GmapMap
        class="map setLocationMap"
        :center="locationData.center"
        :zoom="locationData.zoom"
        @click="markLocation"
        style="width: 100%; height: 400px;"
      >
        <GmapMarker
          :position="locationData.oldMark"
          icon="https://maps.google.com/mapfiles/ms/micons/blue.png"
        />
        <GmapMarker
          :position="locationData.newMark"
          icon="https://maps.google.com/mapfiles/ms/micons/red-dot.png"
        />
      </GmapMap>
      <b-form-group :label="$t('stationName')" label-for="locationData.stationName" :label-cols="3">
        <b-form-input
          id="stationName"
          type="text"
          v-model="locationData.stationName"
          trim
          placeholder="- stationName"
          plaintext
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('inputLocate')"
        label-for="locationText"
        :label-cols="3"
        :state="stateLocation"
        :invalid-feedback="invalidLocation"
      >
        <b-form-input
          id="locationText"
          type="text"
          v-model="locationData.locationText"
          trim
          placeholder="- Latitude and Longitude"
          @change="checkLocationText"
          :state="stateLocation"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('locateOld')" label-for="oldLocation" :label-cols="3">
        <b-form-input
          id="oldLocation"
          type="text"
          v-model="locationData.oldLocation"
          trim
          placeholder="- oldLocation"
          plaintext
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('locateNew')" label-for="newLocation" :label-cols="3">
        <b-form-input
          id="newLocation"
          type="text"
          v-model="locationData.newLocation"
          trim
          placeholder="- newLocation"
          plaintext
        ></b-form-input>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="locationModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="setStationLocation()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";

export default {
  name: "station",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      roles: this.$store.getters.roles,
      searchInfoBar: "",
      providerSearch: "",
      providerOptions: [],
      stationEnabledOptions: [
        { value: 1, text: this.$t("enabled") },
        { value: 0, text: this.$t("disabled") }
      ],
      createStationModal: false,
      createData: {
        PID: "",
        stationName: "",
        stationLocation: "",
        stationEnabled: 1
      },
      editStationModal: false,
      editData: {},
      deleteStationModal: false,
      deleteData: {},
      locationModal: false,
      locationData: {
        zoom: 7,
        center: { lat: 23.854648, lng: 120.937847 },
        LSID: "",
        stationName: "",
        oldLocation: "",
        newLocation: "",
        locationText: "",
        oldMark: null,
        newMark: null
      },
      columns: [
        "stationName",
        "stationLocation",
        "stationStatus",
        "totalLock",
        "stationLock",
        "totalBikes",
        "stationEnabled",
        "stationUpdateAt",
        "action"
      ],
      data: [],
      options: {
        headings: {
          stationName: this.$t("stationName"),
          stationLocation: this.$t("stationLocation"),
          stationStatus: this.$t("stationStatus"),
          totalLock: this.$t("totalLock"),
          stationLock: this.$t("stationLock"),
          totalBikes: this.$t("totalBikes"),
          stationEnabled: this.$t("isEnabled"),
          stationUpdateAt: this.$t("lastUpdateAt"),
          action: this.$t("action")
        },
        sortable: [
          "stationName",
          "stationLocation",
          "stationStatus",
          "totalLock",
          "stationLock",
          "totalBikes",
          "stationEnabled",
          "stationUpdateAt"
        ],
        filterable: [
          "stationName",
          "stationLocation",
          "stationStatus",
          "totalLock",
          "stationLock",
          "totalBikes",
          "stationEnabled"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      }
    };
  },
  computed: {
    statePID() {
      return this.createData.PID.length > 0;
    },
    invalidPID() {
      return this.createData.PID.length > 0 ? "" : this.$t("required");
    },
    stateStationName() {
      return this.createData.stationName.length > 0;
    },
    invalidStationName() {
      return this.createData.stationName.length > 0 ? "" : this.$t("required");
    },
    stateStationLocation() {
      return this.createData.stationLocation.length > 0;
    },
    invalidStationLocation() {
      return this.createData.stationLocation.length > 0
        ? ""
        : this.$t("required");
    },
    stateStationEnabled() {
      return this.createData.stationEnabled.length !== null;
    },
    stateLocation() {
      let latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/;
      let lngreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/;
      let locateText = this.locationData.locationText
        .replace(/\s/g, "")
        .split(",");
      return latreg.test(locateText[0]) && lngreg.test(locateText[1]);
    },
    invalidLocation() {
      let latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/;
      let lngreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/;
      let locateText = this.locationData.locationText
        .replace(/\s/g, "")
        .split(",");
      return latreg.test(locateText[0]) && lngreg.test(locateText[1])
        ? ""
        : this.$t("locateWrong");
    }
  },
  mounted: function() {
    this.getProviderInfo();
  },
  methods: {
    getProviderInfo() {
      this.$store
        .dispatch("getProviderInfo")
        .then(res => {
          this.providerOptions = [{ value: "", text: this.$t("allProvider") }];
          for (let i in res) {
            this.providerOptions.push({
              value: res[i].PID,
              text: res[i].providerName
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getStationByPID() {
      let startTime = this.$moment();
      this.show = true;
      this.$store
        .dispatch("getStationByPID", {
          PID: this.roles === 1 ? this.providerSearch : this.$store.getters.PID
        })
        .then(res => {
          this.data = [];
          for (let i in res) {
            res[i].stationUpdateAt = this.$moment(
              res[i].stationUpdateAt,
              "X"
            ).format("YYYY-MM-DD HH:mm:ss");
            this.data.push(res[i]);
          }
          this.searchInfoBar =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.data.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
          this.show = false;
        })
        .catch(e => {
          this.searchInfoBar = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
          this.show = false;
        });
    },
    createStation() {
      this.createData = {
        PID: this.$store.getters.PID,
        stationName: "",
        stationLocation: "",
        stationEnabled: 1
      };
      this.createStationModal = true;
    },
    storeStation() {
      if (
        this.statePID === true &&
        this.stateStationName === true &&
        this.stateStationLocation === true &&
        this.stateStationEnabled === true
      ) {
        this.$store
          .dispatch("storeStation", this.createData)
          .then(res => {
            this.createStationModal = false;
            this.getStationByPID();
            this.$swal("Success", this.$t("createStaionSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    editStation(prop) {
      this.editData = {
        LSID: prop.LSID,
        PID: prop.PID,
        stationName: prop.stationName,
        stationLocation: prop.stationLocation,
        stationEnabled: prop.stationEnabled
      };
      this.editStationModal = true;
    },
    updateStation() {
      this.$store
        .dispatch("updateStation", this.editData)
        .then(res => {
          this.editStationModal = false;
          this.getStationByPID();
          this.$swal("Success", $t("updateStationSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    deleteStation(prop) {
      this.deleteData = prop;
      this.deleteStationModal = true;
    },
    destroyStation() {
      this.$store
        .dispatch("destroyStation", this.deleteData.LSID)
        .then(res => {
          this.deleteStationModal = false;
          this.getStationByPID();
          this.$swal("Success", this.$t("deleteStationSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    location(prop) {
      this.locationData = {
        zoom: 7,
        center: { lat: 23.854648, lng: 120.937847 },
        LSID: "",
        stationName: "",
        oldLocation: "",
        newLocation: "",
        locationText: "",
        oldMark: null,
        newMark: null
      };
      this.locationData.stationName = prop.stationName;
      this.locationData.LSID = prop.LSID;
      if (
        prop.lat !== null &&
        prop.lng !== null &&
        prop.lat !== "" &&
        prop.lng !== ""
      ) {
        this.locationData.center = { lat: prop.lat, lng: prop.lng };
        this.locationData.zoom = 14;
        this.locationData.oldMark = this.locationData.center;
        this.locationData.oldLocation = prop.lat + ", " + prop.lng;
      }
      this.locationModal = true;
    },
    markLocation(e) {
      // console.log(e);
      // this.locationData.center = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      this.locationData.newMark = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.locationData.newLocation = e.latLng.lat() + ", " + e.latLng.lng();
    },
    setStationLocation() {
      if (this.stateLocation === true) {
        this.$store
          .dispatch("setStationLocation", {
            LSID: this.locationData.LSID,
            lat: this.locationData.newMark.lat,
            lng: this.locationData.newMark.lng
          })
          .then(res => {
            this.locationModal = false;
            this.getStationByPID();
            this.$swal("Success", this.$t("stationLocateSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    checkLocationText() {
      if (this.stateLocation === true) {
        let locateText = this.locationData.locationText
          .replace(/\s/g, "")
          .split(",");

        let lat = Number(locateText[0]);
        let lng = Number(locateText[1]);
        this.locationData.center = { lat: lat, lng: lng };
        this.locationData.zoom = 17;
        this.locationData.newLocation = lat + ", " + lng;
        this.locationData.newMark = {
          lat: lat,
          lng: lng
        };
      }
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
