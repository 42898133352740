import request from '@/utils/request'

export function getOrderData (searchData) {
  return request({
    url: '/order/orderData',
    method: 'get',
    params: searchData
  })
}

export function updateOrder (updateData) {
  const data = {
    updateData
  }
  return request({
    url: '/order/updateOrder',
    method: 'post',
    data
  })
}

export function getOrderPriceSum () {
  return request({
    url: '/order/orderPriceSum',
    method: 'get'
  })
}

export function getOrderLineChartData () {
  return request({
    url: '/order/orderLineChartData',
    method: 'get'
  })
}
