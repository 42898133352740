import * as userApi from '@/api/user'

const user = {
  state: {

  },
  mutations: {

  },
  actions: {
    getUserData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        userApi.getUserData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateUser ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        userApi.updateUser(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default user
