<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="4">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.prevent="login">
                  <h1>{{$t('welcome')}}</h1>
                  <p class="text-muted">{{$t('loginAccount')}}</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      v-model="username"
                      class="form-control"
                      placeholder="Username"
                      autocomplete="username email"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      v-model="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button type="submit" variant="primary" class="px-4">{{$t('login')}}</b-button>
                    </b-col>
                    <!--
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>
                    -->
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <!--
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <b-button variant="primary" class="active mt-3">Register Now!</b-button>
                </div>
              </b-card-body>
            </b-card>
            -->
          </b-card-group>
        </b-col>
      </b-row>
      <loading :show="show" :label="label" :overlay="overlay">
        <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
      </loading>
    </div>
  </div>
</template>

<script>
import loading from "vue-full-loading";

export default {
  name: "Login",
  components: {
    loading
  },
  data() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      username: "",
      password: ""
    };
  },
  methods: {
    login() {
      this.show = true;
      this.$store
        .dispatch("LoginByUsername", this)
        .then(() => {
          this.show = false;
          this.$router.push({ path: this.redirect || "/" });
        })
        .catch(e => {
          this.show = false;
          alert(e);
        });
    }
  }
};
</script>
