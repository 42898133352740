import * as bikeApi from '@/api/bike'

const bike = {
  state: {

  },
  mutations: {

  },
  actions: {
    getBikeByLSID ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        bikeApi.getBikeByLSID(searchData).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getBikeData ({ commit }, searchData) {
      return new Promise((resolve, reject) => {
        bikeApi.getBikeData(searchData).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    updateBike ({ commit }, editData) {
      return new Promise((resolve, reject) => {
        bikeApi.updateBike(editData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },
    importExcelBike ({ commit }, importExcelData) {
      return new Promise((resolve, reject) => {
        bikeApi.importExcelBike(importExcelData).then(response => {
          if (response.code === 2000) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getDailyBikeUsageCount ({ commit }, BID) {
      return new Promise((resolve, reject) => {
        bikeApi.getDailyBikeUsageCount(BID).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },
    getBikeStatusCount ({ commit }, PID) {
      return new Promise((resolve, reject) => {
        bikeApi.getBikeStatusCount(PID).then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default bike
