import request from '@/utils/request'

export function getGeneralData () {
  return request({
    url: '/device/generalData',
    method: 'get'
  })
}

export function getDeviceErrorListSearch (condition) {
  return request({
    url: '/device/errorList',
    method: 'get',
    params: condition
  })
}

export function getDeviceInfoListSearch (condition) {
  return request({
    url: '/device/infoList',
    method: 'get',
    params: condition
  })
}

export function getDeviceInfoSearch (condition) {
  return request({
    url: '/device/info',
    method: 'get',
    params: condition
  })
}

export function getDeviceSettingInfo (condition) {
  return request({
    url: '/device/setting',
    method: 'get',
    params: condition
  })
}

export function getDeviceMonitorList (condition) {
  return request({
    url: '/device/monitorList',
    method: 'get',
    params: condition
  })
}

export function getNotMonitorList (condition) {
  return request({
    url: '/device/notMonitorList',
    method: 'get',
    params: condition
  })
}

export function getInitData (devid) {
  return request({
    url: '/device/getInitData',
    method: 'get',
    params: devid
  })
}

export function getNotSetDevice () {
  return request({
    url: '/device/getNotSetDevice',
    method: 'get'
  })
}

export function getNotBindingDeviceList (condition) {
  return request({
    url: '/device/getNotBindingDeviceList',
    method: 'get',
    params: condition
  })
}

export function setEnable (isEnable, devid) {
  const data = {
    deviceEnable: isEnable,
    devid: devid
  }
  return request({
    url: '/device/setEnable',
    method: 'post',
    data
  })
}

export function setField (field, devid) {
  const data = {
    deviceField: field,
    devid: devid
  }
  return request({
    url: '/device/setField',
    method: 'post',
    data
  })
}

export function setDeviceType (deviceType, devid) {
  const data = {
    deviceType: deviceType,
    devid: devid
  }
  return request({
    url: '/device/setDeviceType',
    method: 'post',
    data
  })
}

export function setInitData (seqID, devid) {
  const data = {
    seqID: seqID,
    devid: devid
  }
  return request({
    url: '/device/setInitData',
    method: 'post',
    data
  })
}

export function setDeviceMode (deviceMode, devid) {
  const data = {
    deviceMode: deviceMode,
    devid: devid
  }
  return request({
    url: '/device/setDeviceMode',
    method: 'post',
    data
  })
}

export function setMonitor (devid, monitorReason) {
  const data = {
    devid: devid,
    monitorReason: monitorReason
  }
  return request({
    url: '/device/setMonitor',
    method: 'post',
    data
  })
}

export function setFixed (devid) {
  const data = {
    devid: devid
  }
  return request({
    url: '/device/setFixed',
    method: 'post',
    data
  })
}

export function setEndMonitor (devid) {
  const data = {
    devid: devid
  }
  return request({
    url: '/device/setEndMonitor',
    method: 'post',
    data
  })
}

export function getRawDataSearchById (condition) {
  return request({
    url: '/device/getRawDataSearchById',
    method: 'get',
    params: condition
  })
}

export function getMonitorDataSearchById (condition) {
  return request({
    url: '/device/getMonitorDataSearchById',
    method: 'get',
    params: condition
  })
}

export function getErrorCodeDataById (condition) {
  return request({
    url: '/device/getErrorCodeDataById',
    method: 'get',
    params: condition
  })
}
