<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-users" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_admin')}}</strong>
          </div>
          <b-row>
            <b-col cols="8" sm="9" md="10" class="text-center">
              <b-form-select
                v-model="providerSearch"
                :options="providerOptions"
                @change="getAdminUserByPID()"
                v-if="roles === 1"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-button variant="primary" @click="createAdminUser()">{{$t('createAdmin')}}</b-button>
            </b-col>
          </b-row>
          <hr />
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="roles" slot-scope="props">
                <font v-if="props.row.roles === 1">{{$t('rootPermission')}}</font>
                <font v-if="props.row.roles === 2">{{$t('adminPermission')}}</font>
                <font v-if="props.row.roles === 3">{{$t('maintainPermission')}}</font>
                <font v-if="props.row.roles === 4">{{$t('clientPermission')}}</font>
                <font v-if="props.row.roles === 11">{{$t('maintainStaff')}}</font>
                <font v-if="props.row.roles === 12">{{$t('serviceStaff')}}</font>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button
                  class="action-btn"
                  @click="editAdminUser(props.row)"
                  variant="outline-warning"
                >{{$t('edit')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="deleteAdminUser(props.row)"
                  variant="outline-danger"
                >{{$t('delete')}}</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="createUserModal"
      :title="$t('createAdmin')"
      header-bg-variant="primary"
      ok-only
      no-stacking
    >
      <b-form-group
        :label="$t('displayName')"
        label-for="displayName"
        :label-cols="3"
        :invalid-feedback="invalidDisplayName"
        :state="stateDisplayName"
      >
        <b-form-input
          id="displayName"
          type="text"
          v-model="createData.displayName"
          :state="stateDisplayName"
          trim
          placeholder="- DisplayName"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('username')"
        label-for="username"
        :label-cols="3"
        :invalid-feedback="invalidUsername"
        :state="stateUsername"
      >
        <b-form-input
          id="username"
          type="text"
          v-model="createData.username"
          :state="stateUsername"
          trim
          placeholder="- Username"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('password')"
        label-for="password"
        :label-cols="3"
        :invalid-feedback="invalidPassword"
        :state="statePassword"
      >
        <b-input-group>
          <b-form-input
            id="password"
            type="text"
            v-model="createData.password"
            :state="statePassword"
            trim
            :placeholder="$t('createPasswordMsg')"
            disabled
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="random_password()">
              <i class="fa fa-undo"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group
        :label="$t('phone')"
        label-for="phone"
        :label-cols="3"
        :invalid-feedback="invalidPhone"
        :state="statePhone"
      >
        <b-form-input
          id="phone"
          type="text"
          v-model="createData.phone"
          :state="statePhone"
          trim
          placeholder="- Phone"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('company')"
        label-for="pID"
        :label-cols="3"
        :invalid-feedback="invalidProvider"
        :state="stateProvider"
        v-if="roles === 1"
      >
        <b-form-select
          id="pID"
          v-model="createData.pID"
          :state="stateProvider"
          trim
          :options="providerOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="$t('role')"
        label-for="roles"
        :label-cols="3"
        :invalid-feedback="invalidRoles"
        :state="stateRoles"
      >
        <b-form-select
          id="roles"
          v-model="createData.roles"
          :state="stateRoles"
          trim
          :options="rolesOptions"
        ></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="createUserModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="storeAdminUser()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="editUserModal"
      :title="$t('editUser')"
      header-bg-variant="warning"
      ok-only
      no-stacking
    >
      <b-form-group :label="$t('displayName')" label-for="displayName" :label-cols="3">
        <b-form-input
          id="displayName"
          type="text"
          v-model="editData.displayName"
          trim
          placeholder="- DisplayName"
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('username')" label-for="username" :label-cols="3">
        <b-form-input
          id="username"
          type="text"
          v-model="editData.username"
          trim
          placeholder="- Username"
          disabled
        ></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('password')" label-for="password" :label-cols="3">
        <b-input-group>
          <b-form-input
            id="password"
            type="text"
            v-model="editData.password"
            trim
            :placeholder="$t('createPasswordMsg')"
            disabled
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="outline-primary" @click="random_password()">
              <i class="fa fa-undo"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('phone')" label-for="phone" :label-cols="3">
        <b-form-input id="phone" type="text" v-model="editData.phone" trim placeholder="- Phone"></b-form-input>
      </b-form-group>
      <b-form-group :label="$t('company')" label-for="pID" :label-cols="3" v-if="roles === 1">
        <b-form-select id="pID" v-model="editData.pID" trim :options="providerOptions"></b-form-select>
      </b-form-group>
      <b-form-group :label="$t('role')" label-for="roles" :label-cols="3">
        <b-form-select id="roles" v-model="editData.roles" trim :options="rolesOptions"></b-form-select>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editUserModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="updateAdminUser()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="deleteUserModal"
      :title="$t('deleteUser')"
      header-bg-variant="danger"
      ok-only
      on-stacking
    >
      <h6>{{$t('deleteUserMsg')}} {{ deleteData.username }} ？</h6>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="deleteUserModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="danger"
        @click="destroyAdminUser()"
      >{{$t('ok')}}</b-button>
    </b-modal>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
export default {
  name: "admin",
  components: {},
  data: function() {
    return {
      roles: this.$store.getters.roles,
      providerSearch: "",
      providerOptions: [{ value: "", text: this.$t("select"), disabled: true }],
      rolesOptions: [],
      createUserModal: false,
      createData: {
        displayName: "",
        username: "",
        password: "",
        phone: "",
        pID: "",
        roles: 0,
        agpID: ""
      },
      editUserModal: false,
      editData: {},
      deleteUserModal: false,
      deleteData: {},
      columns: [
        "displayName",
        "username",
        "phone",
        "providerName",
        "roles",
        "action"
      ],
      data: [],
      options: {
        headings: {
          displayName: this.$t("displayName"),
          username: this.$t("username"),
          phone: this.$t("phone"),
          providerName: this.$t("company"),
          roles: this.$t("role"),
          action: this.$t("action")
        },
        sortable: ["displayName", "username", "phone", "providerName", "roles"],
        filterable: [
          "displayName",
          "username",
          "phone",
          "providerName",
          "roles"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      }
    };
  },
  computed: {
    stateDisplayName() {
      return this.createData.displayName.length > 0;
    },
    invalidDisplayName() {
      return this.createData.displayName.length > 0 ? "" : this.$t("required");
    },
    stateUsername() {
      return this.createData.username.length > 0;
    },
    invalidUsername() {
      return this.createData.username.length > 0 ? "" : this.$t("required");
    },
    statePassword() {
      return this.createData.password.length > 0;
    },
    invalidPassword() {
      return this.createData.password.length > 0 ? "" : this.$t("required");
    },
    statePhone() {
      return this.createData.phone.length > 0;
    },
    invalidPhone() {
      return this.createData.phone.length > 0 ? "" : this.$t("required");
    },
    stateProvider() {
      return this.createData.pID.length > 0;
    },
    invalidProvider() {
      return this.createData.pID.length > 0 ? "" : this.$t("required");
    },
    stateRoles() {
      return this.createData.roles > 0;
    },
    invalidRoles() {
      return this.createData.roles > 0 ? "" : this.$t("required");
    }
  },
  mounted() {
    this.getProviderInfo();
  },
  methods: {
    getProviderInfo() {
      if (this.roles === 1) {
        this.rolesOptions = [
          { value: 0, text: this.$t("select"), disabled: true },
          { value: 1, text: this.$t("rootPermission") },
          { value: 2, text: this.$t("adminPermission") },
          { value: 3, text: this.$t("maintainPermission") },
          { value: 4, text: this.$t("clientPermission") },
          { value: 11, text: this.$t("maintainStaff") },
          { value: 12, text: this.$t("serviceStaff") }
        ];
        this.$store
          .dispatch("getProviderInfo")
          .then(res => {
            this.providerOptions = [
              { value: "", text: this.$t("select"), disabled: true }
            ];
            for (let i in res) {
              this.providerOptions.push({
                value: res[i].PID,
                text: res[i].providerName
              });
            }
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      } else {
        this.rolesOptions = [
          { value: 0, text: this.$t("select"), disabled: true },
          { value: 2, text: this.$t("adminPermission") },
          { value: 3, text: this.$t("maintainPermission") },
          { value: 4, text: this.$t("clientPermission") },
          { value: 11, text: this.$t("maintainStaff") },
          { value: 12, text: this.$t("serviceStaff") }
        ];
        this.getAdminUserByPID();
      }
    },
    getAdminUserByPID() {
      this.$store
        .dispatch("getAdminUserByPID", {
          pid: this.roles === 1 ? this.providerSearch : this.$store.getters.PID
        })
        .then(res => {
          this.data = [];
          for (let i in res) {
            this.data.push({
              auID: res[i].auID,
              displayName: res[i].displayName,
              username: res[i].username,
              pID: res[i].pID,
              providerName: res[i].providerName,
              roles: res[i].roles,
              phone: res[i].phone
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    createAdminUser() {
      this.createData = {
        displayName: "",
        username: "",
        password: "",
        phone: "",
        pID: "",
        roles: 0
      };
      this.createUserModal = true;
    },
    storeAdminUser() {
      if (
        this.stateDisplayName === true &&
        this.stateUsername === true &&
        this.statePassword === true &&
        this.statePhone === true &&
        (this.stateProvider === true || this.roles !== 1) &&
        this.stateRoles === true
      ) {
        this.$store
          .dispatch("storeAdminUser", this.createData)
          .then(res => {
            this.createUserModal = false;
            if (
              this.roles === 1 ? this.providerSearch : this.$store.getters.PID
            )
              this.getAdminUserByPID();
            this.$swal("Success", this.$t("createUsersSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    editAdminUser(prop) {
      this.editData = {
        auID: prop.auID,
        displayName: prop.displayName,
        username: prop.username,
        password: "",
        pID: prop.pID,
        roles: prop.roles,
        phone: prop.phone
      };
      this.editUserModal = true;
    },
    updateAdminUser() {
      this.$store
        .dispatch("updateAdminUser", this.editData)
        .then(res => {
          this.editUserModal = false;
          this.getAdminUserByPID();
          this.$swal("Success", this.$t("updateUsersSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    deleteAdminUser(prop) {
      this.deleteData = { username: prop.username, auID: prop.auID };
      this.deleteUserModal = true;
    },
    destroyAdminUser() {
      this.$store
        .dispatch("destroyAdminUser", this.deleteData.auID)
        .then(res => {
          this.deleteUserModal = false;
          this.getAdminUserByPID();
          this.$swal("Success", this.$t("removeUsersSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    random_password() {
      let random = Math.floor(Math.random() * 9) + 1; // random 1~9
      for (var i = 0; i < 5; i++) {
        random = random.toString() + Math.floor(Math.random() * 10).toString(); // random 0~9
      }
      this.createData.password = random;
      this.editData.password = random;
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
