import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login/login'
import Dashboard from '@/views/dashboard/dashboard'
import stationManager from '@/views/manager/station'
import spaceManager from '@/views/manager/space'
import lockManager from '@/views/manager/lock'
import bikeManager from '@/views/manager/bike'
import userManager from '@/views/manager/user'
import OrderSearch from '@/views/finance/orderSearch'
import BillingPlan from '@/views/finance/billingPlan'
import Provider from '@/views/provider/provider'
import admin from '@/views/admin/admin'
import DefaultContainer from '@/containers/DefaultContainer'
import chartList from '@/views/report/chartList'
import stationList from '@/views/report/stationList'
import spaceList from '@/views/report/spaceList'
import bikeList from '@/views/report/bikeList'
import warningSystem from '@/views/warning/warningSystem'
import personal from '@/views/admin/personal'
import maintain from '@/views/maintain/maintain'
import i18n from '@/lang/i18n'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/Login',
      name: 'Login',
      components: {
        default: Login
      }
    },
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('nav_dashboard'),
          component: Dashboard,
          meta: {
            roles: [1, 2, 3, 4]
          }
        },
        {
          path: 'provider',
          name: i18n.t('nav_provider'),
          component: Provider,
          meta: {
            roles: [1]
          }
        },
        {
          path: 'admin',
          name: i18n.t('nav_admin'),
          component: admin,
          meta: {
            roles: [1, 2]
          }
        },
        {
          path: 'personal',
          name: i18n.t('nav_personal'),
          component: personal,
          meta: {
            roles: [1, 2, 3, 4]
          }
        },
        {
          path: 'report',
          redirect: '/report/spaceList',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'chartList',
              name: i18n.t('nav_chartList'),
              component: chartList,
              meta: {
                roles: [1, 2, 3, 4]
              }
            },
            {
              path: 'stationList',
              name: i18n.t('nav_stationList'),
              component: stationList,
              meta: {
                roles: [1, 2, 3, 4]
              }
            },
            {
              path: 'spaceList',
              name: i18n.t('nav_spaceList'),
              component: spaceList,
              meta: {
                roles: [1, 2, 3, 4]
              }
            },
            {
              path: 'bikeList',
              name: i18n.t('nav_bikeList'),
              component: bikeList,
              meta: {
                roles: [1, 2, 3, 4]
              }
            }
          ]
        },
        {
          path: 'warning',
          redirect: '/warning/system',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'system',
              name: i18n.t('nav_warning'),
              component: warningSystem,
              meta: {
                roles: [1, 2, 3]
              }
            }
          ]
        },
        {
          path: 'manager',
          redirect: '/manager/station',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'station',
              name: i18n.t('nav_station'),
              component: stationManager,
              meta: {
                roles: [1, 2, 3]
              }
            },
            {
              path: 'space',
              name: i18n.t('nav_space'),
              component: spaceManager,
              meta: {
                roles: [1, 2, 3]
              }
            },
            {
              path: 'lock',
              name: i18n.t('nav_lock'),
              component: lockManager,
              meta: {
                roles: [1, 2, 3]
              }
            },
            {
              path: 'bike',
              name: i18n.t('nav_bike'),
              component: bikeManager,
              meta: {
                roles: [1, 2, 3]
              }
            }
            // {
            //   path: 'user',
            //   name: '一般用戶管理',
            //   component: userManager,
            //   meta: {
            //     roles: [1]
            //   }
            // }
          ]
        },
        // {
        //   path: 'finance',
        //   redirect: '/finance/orderSearch',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'orderSearch',
        //       name: '訂單查詢',
        //       component: OrderSearch,
        //       meta: {
        //         roles: [1, 2, 3]
        //       }
        //     },
        //     {
        //       path: 'billingPlan',
        //       name: '計費方式',
        //       component: BillingPlan,
        //       meta: {
        //         roles: [1]
        //       }
        //     }
        //   ]
        // },
        {
          path: 'maintain',
          redirect: '/maintain/maintain',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'maintain',
              name: i18n.t('nav_maintain'),
              component: maintain,
              meta: {
                roles: [1, 2, 3]
              }
            }
          ]
        }
      ]
    }
  ]
})
