import * as orderApi from '@/api/order'

const order = {
  state: {

  },
  mutations: {

  },
  actions: {
    getOrderData ({ commit, state }, searchData) {
      return new Promise((resolve, reject) => {
        orderApi.getOrderData(searchData).then((response) => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    updateOrder ({ commit }, updateData) {
      return new Promise((resolve, reject) => {
        orderApi.updateOrder(updateData).then(response => {
          if (response.code === 2000) {
            resolve()
          } else {
            reject(response.message)
          }
        })
      })
    },

    getOrderPriceSum ({ commit }) {
      return new Promise((resolve, reject) => {
        orderApi.getOrderPriceSum().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    },

    getOrderLineChartData ({ commit }) {
      return new Promise((resolve, reject) => {
        orderApi.getOrderLineChartData().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }
  }
}

export default order
