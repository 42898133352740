import request from '@/utils/request'

export function getSpaceByLSID (searchData) {
  return request({
    url: '/space/SpaceByLSID',
    method: 'get',
    params: searchData
  })
}

export function storeSpace (createData) {
  const data = {
    createData
  }
  return request({
    url: '/space/storeSpace',
    method: 'post',
    data
  })
}

export function getBindList () {
  return request({
    url: '/space/bindList',
    method: 'get'
  })
}

export function updateSpace (editData) {
  const data = {
    editData
  }
  return request({
    url: '/space/updateSpace',
    method: 'post',
    data
  })
}

export function destroySpace (LSSID) {
  const data = {
    LSSID
  }
  return request({
    url: '/space/destroySpace',
    method: 'post',
    data
  })
}

export function getDailySpaceTurnoverCount (LSSID) {
  return request({
    url: '/space/dailySpaceTurnoverCount',
    method: 'get',
    params: LSSID
  })
}
