import request from '@/utils/request'
import sha256 from 'sha256'

export function loginByUsername (username, password) {
  if (password.length > 0) password = sha256(password)
  const data = {
    username,
    password,
    deviceType: 'browser'
  }
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}

export function logout () {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}

export function getUserRoles () {
  return request({
    url: '/admin/roles',
    method: 'get'
  })
}

export function getAdminUserByPID (pid) {
  return request({
    url: 'admin/AdminUserByPID',
    method: 'get',
    params: pid
  })
}

export function getGroupPermissions () {
  return request({
    url: 'admin/GroupPermission',
    method: 'get'
  })
}

export function storeAdminUser (createData) {
  const data = {
    createData
  }
  return request({
    url: 'admin/storeAdminUser',
    method: 'post',
    data
  })
}

export function updateAdminUser (editData) {
  const data = {
    editData
  }
  return request({
    url: 'admin/updateAdminUser',
    method: 'post',
    data
  })
}

export function destroyAdminUser (auID) {
  const data = {
    auID
  }
  return request({
    url: 'admin/destroyAdminUser',
    method: 'post',
    data
  })
}

export function getPersonal () {
  return request({
    url: '/admin/personal',
    method: 'get'
  })
}

export function updatePersonal (editData) {
  const data = {
    editData
  }
  return request({
    url: 'admin/updatePersonal',
    method: 'post',
    data
  })
}
