<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-cog" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_space')}}</strong>
          </div>
          <b-row>
            <b-col cols="4" sm="5" md="5" class="text-center" v-if="roles === 1">
              <b-form-select
                v-model="providerSearch"
                :options="providerOptions"
                @change="getStationByPID(providerSearch)"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="5" md="5" class="text-center" v-if="roles === 1">
              <b-form-select v-model="stationSearch" :options="stationOptions"></b-form-select>
            </b-col>
            <b-col cols="8" sm="10" md="10" class="text-center" v-if="roles !== 1">
              <b-form-select v-model="stationSearch" :options="stationOptions"></b-form-select>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <b-button class="action-btn" variant="info" @click="getSpaceByLSID()">{{$t('search')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                @click="createSpace()"
                v-if="roles <= 2"
              >{{$t('createSpace')}}</b-button>
            </b-col>
          </b-row>
          <hr />
          <div>
            <font class="text-muted">{{ searchInfoBar }}</font>
          </div>
          <hr />
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="lockStatus" slot-scope="props">
                <p v-if="props.row.lockStatus === 'locked'">{{$t('bikeLocked')}}</p>
                <p v-if="props.row.lockStatus === 'unlocked'">{{$t('bikeUnlocked')}}</p>
                <p v-if="props.row.lockStatus === 'maintain'">{{$t('maintain')}}</p>
              </div>
              <div slot="spaceEnabled" slot-scope="props">
                <p v-if="props.row.spaceEnabled === 1">{{$t('enabled')}}</p>
                <p v-if="props.row.spaceEnabled === 0">{{$t('disabled')}}</p>
              </div>
              <div slot="action" class="text-center" slot-scope="props">
                <b-button
                  v-if="props.row.LPID !== null"
                  class="action-btn"
                  @click="lockInfo(props.row)"
                  variant="outline-primary"
                >{{$t('dataInfo')}}</b-button>
                <b-button
                  v-if="props.row.LPID === null"
                  class="action-btn"
                  variant="outline-primary"
                  :disabled="true"
                >{{$t('dataInfo')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="editSpace(props.row)"
                  variant="outline-warning"
                  v-if="roles <= 2"
                >{{$t('edit')}}</b-button>
                <b-button
                  class="action-btn"
                  @click="deleteSpace(props.row)"
                  variant="outline-danger"
                  v-if="roles <= 2"
                >{{$t('delete')}}</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="createSpaceModal"
      :title="$t('createSpace')"
      header-bg-variant="primary"
      ok-only
      no-stacking
    >
      <b-form-group :label="$t('vendorName')" label-for="PID" :label-cols="3" v-if="roles === 1">
        <b-form-select
          id="LSID"
          v-model="createData.PID"
          trim
          :options="createProviderOptions"
          @change="getCreateStationByPID()"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="$t('stationName')"
        label-for="LSID"
        :label-cols="3"
        :invalid-feedback="invalidLSID"
        :state="stateLSID"
      >
        <b-form-select
          id="LSID"
          v-model="createData.LSID"
          :state="stateLSID"
          trim
          :options="createStationOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        :label="$t('spaceName')"
        label-for="spaceName"
        :label-cols="3"
        :invalid-feedback="invalidSpaceName"
        :state="stateSpaceName"
      >
        <b-form-input
          id="spaceName"
          type="text"
          v-model="createData.spaceName"
          :state="stateSpaceName"
          trim
          placeholder="- spaceName"
        ></b-form-input>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="createSpaceModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="storeSpace()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal v-model="editSpaceModal" :title="$t('editSpace')" header-bg-variant="warning" ok-only>
      <b-form-group
        :label="$t('spaceName')"
        label-for="spaceName"
        :label-cols="3"
        :invalid-feedback="invalidEditSpaceName"
        :state="stateEditSpaceName"
      >
        <b-form-input
          id="spaceName"
          type="text"
          v-model="editData.spaceName"
          :state="stateEditSpaceName"
          trim
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('spaceEnabled')"
        label-for="spaceEnabled"
        :label-cols="3"
        :state="stateEditSpaceEnabled"
      >
        <b-form-select
          id="spaceEnabled"
          v-model="editData.spaceEnabled"
          :state="stateEditSpaceEnabled"
          trim
          :options="spaceEnabledOptions"
        ></b-form-select>
      </b-form-group>
      <b-form-group :label="$t('bindLockPileID')" label-for="lockId" :label-cols="3">
        <b-input-group>
          <b-form-input
            id="lockId"
            type="text"
            v-model="editData.lockId"
            trim
            :placeholder="$t('nobind')"
            plaintext
          ></b-form-input>
          <b-input-group-append>
            <b-button v-if="editData.LPID === null" variant="outline-primary" @click="bindList()">
              <i class="fa fa-lock"></i>
            </b-button>
            <b-button v-if="editData.LPID !== null" variant="outline-primary" @click="unbind()">
              <i class="fa fa-unlock"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="editSpaceModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="primary"
        @click="updateSpace()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="bindListModal"
      :title="$t('bindList')"
      header-bg-variant="primary"
      hide-footer
      ok-only
      on-stacking
    >
      <h6>{{$t('noBindList')}}</h6>
      <hr />
      <v-client-table :columns="bindListColumns" :data="bindListData" :options="bindListOptions">
        <div slot="lockId" slot-scope="props" @click="bind(props.row)">{{ props.row.lockId }}</div>
      </v-client-table>
    </b-modal>

    <b-modal
      v-model="deleteSpaceModal"
      :title="$t('deleteSpace')"
      header-bg-variant="danger"
      ok-only
      on-stacking
    >
      <h6>{{$t('areyouSureDeleteSpaceMsg')}} {{ deleteData.spaceName }} ？</h6>
      <p>{{$t('makeSureUnbindAlready')}}</p>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="secondary"
        @click="deleteSpaceModal=false"
      >{{$t('cancel')}}</b-button>
      <b-button
        slot="modal-footer"
        class="float-right"
        variant="danger"
        @click="destroySpace()"
      >{{$t('ok')}}</b-button>
    </b-modal>

    <b-modal
      v-model="lockInfoModal"
      :title="$t('dataInfo')"
      header-bg-variant="primary"
      size="lg"
      hide-footer
      on-stacking
    >
      <b-card class="card-accent-info">
        <div slot="header">
          <i class="fa fa-info"></i>
          {{$t('dataInfo')}}
        </div>
        <b-row>
          <b-col cols="12" sm="6">
            <p>{{$t('vendorName')}} : {{ lockInfoData.providerName }}</p>
            <p>{{$t('stationName')}} : {{ lockInfoData.stationName }}</p>
            <p>{{$t('spaceName')}} : {{ lockInfoData.spaceName }}</p>
            <p v-if="lockInfoData.spaceEnabled === 1">{{$t('spaceEnabled')}} : {{$t('enabled')}}</p>
            <p v-if="lockInfoData.spaceEnabled === 0">{{$t('spaceEnabled')}} : {{$t('disabled')}}</p>
            <p>{{$t('spaceEnabledTime')}} : {{ lockInfoData.enabledAt }}</p>
          </b-col>
          <b-col cols="12" sm="6">
            <p>{{$t('lockPileID')}} : {{ lockInfoData.lockId }}</p>
            <p>{{$t('lockPileAddress')}} : {{ lockInfoData.lockAddress }}</p>
            <p>
              {{$t('lockPileStatus')}} :
              <font
                v-if="lockInfoData.lockStatus === 'locked'"
              >{{$t('bikeLocked')}}</font>
              <font v-if="lockInfoData.lockStatus === 'unlocked'">{{$t('bikeUnlocked')}}</font>
              <font v-if="lockInfoData.lockStatus === 'maintain'">{{$t('maintain')}}</font>
            </p>
            <p>{{$t('deviceBattery')}} : {{ lockInfoData.lockBattery }}</p>
            <p>{{$t('bikeType')}} : {{ lockInfoData.bikeType }}</p>
            <p>{{$t('lockPileUpdateAt')}} : {{ lockInfoData.updateAt }}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";

export default {
  name: "space",
  components: {
    loading
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      roles: this.$store.getters.roles,
      searchInfoBar: "",
      providerSearch: "",
      providerOptions: [{ value: "", text: this.$t("allProvider") }],
      stationSearch: "",
      stationOptions: [{ value: "", text: this.$t("allStation") }],
      createProviderSearch: "",
      createProviderOptions: [
        { value: "", text: this.$t("selectProvider"), disabled: true }
      ],
      createStationOptions: [
        { value: "", text: this.$t("selectStation"), disabled: true }
      ],
      createSpaceModal: false,
      createData: { PID: "", LSID: "", spaceName: "" },
      spaceEnabledOptions: [
        { value: 1, text: this.$t("enabled") },
        { value: 0, text: this.$t("disabled") }
      ],
      editSpaceModal: false,
      editData: { spaceName: "" },
      bindListModal: false,
      deleteSpaceModal: false,
      deleteData: {},
      lockInfoModal: false,
      lockInfoData: {},
      columns: [
        "providerName",
        "stationName",
        "spaceName",
        "lockId",
        "lockStatus",
        "lockBattery",
        "spaceEnabled",
        "enabledAt",
        "action"
      ],
      data: [],
      options: {
        headings: {
          providerName: this.$t("vendorName"),
          stationName: this.$t("stationName"),
          spaceName: this.$t("spaceName"),
          lockId: this.$t("lockPileID"),
          lockStatus: this.$t("lockPileStatus"),
          lockBattery: this.$t("deviceBattery"),
          spaceEnabled: this.$t("spaceEnabled"),
          enabledAt: this.$t("spaceEnabledTime"),
          action: this.$t("action")
        },
        sortable: [
          "providerName",
          "stationName",
          "spaceName",
          "lockId",
          "lockStatus",
          "lockBattery",
          "spaceEnabled",
          "enabledAt"
        ],
        filterable: [
          "providerName",
          "stationName",
          "spaceName",
          "lockId",
          "lockStatus",
          "lockBattery",
          "spaceEnabled",
          "enabledAt"
        ],
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        }
      },
      bindListColumns: ["lockId"],
      bindListData: [],
      bindListOptions: {
        headings: {
          lockId: "鎖樁ID"
        },
        filterable: ["lockId"]
      }
    };
  },
  computed: {
    stateLSID() {
      return !(this.createData.LSID === "");
    },
    invalidLSID() {
      return !(this.createData.LSID === "") ? "" : this.$t("required");
    },
    stateSpaceName() {
      return this.createData.spaceName.length > 0;
    },
    invalidSpaceName() {
      return this.createData.spaceName.length > 0 ? "" : this.$t("required");
    },
    stateEditSpaceName() {
      return this.editData.spaceName.length > 0;
    },
    invalidEditSpaceName() {
      return this.editData.spaceName.length > 0 ? "" : this.$t("required");
    },
    stateEditSpaceEnabled() {
      return this.editData.spaceEnabled !== null;
    }
  },
  mounted: function() {
    this.getProviderInfo();
  },
  methods: {
    getProviderInfo() {
      if (this.roles === 1) {
        this.$store
          .dispatch("getProviderInfo")
          .then(res => {
            this.providerOptions = [
              { value: "", text: this.$t("allProvider") }
            ];
            this.createProviderOptions = [
              { value: "", text: this.$t("selectProvider"), disabled: true }
            ];
            for (let i in res) {
              this.providerOptions.push({
                value: res[i].PID,
                text: res[i].providerName
              });
              this.createProviderOptions.push({
                value: res[i].PID,
                text: res[i].providerName
              });
            }
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      } else {
        this.getStationByPID(this.$store.getters.PID);
      }
    },
    getStationByPID(PID) {
      this.$store
        .dispatch("getStationByPID", { PID: PID })
        .then(res => {
          this.stationOptions = [
            { value: "", text: this.$t("allStation") },
            { value: null, text: this.$t("noBindStation") }
          ];
          for (let i in res) {
            this.stationOptions.push({
              value: res[i].LSID,
              text: res[i].stationName
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getSpaceByLSID() {
      let startTime = this.$moment();
      this.show = true;
      this.$store
        .dispatch("getSpaceByLSID", {
          PID: this.roles === 1 ? this.providerSearch : this.$store.getters.PID,
          LSID: this.stationSearch === null ? "null" : this.stationSearch
        })
        .then(res => {
          this.data = [];
          for (let i in res) {
            if (res[i].updateAt !== null) {
              res[i].updateAt = this.$moment(res[i].updateAt).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            if (res[i].enabledAt !== null) {
              res[i].enabledAt = this.$moment(res[i].enabledAt).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            this.data.push(res[i]);
          }
          this.searchInfoBar =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.data.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
          this.show = false;
        })
        .catch(e => {
          this.searchInfoBar = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
          this.show = false;
        });
    },
    getCreateStationByPID() {
      if (this.createData.PID.length > 0) {
        this.$store
          .dispatch("getStationByPID", { PID: this.createData.PID })
          .then(res => {
            this.createStationOptions = [
              { value: "", text: this.$t("selectStation"), disabled: true },
              { value: null, text: this.$t("noBindStation") }
            ];
            for (let i in res) {
              this.createStationOptions.push({
                value: res[i].LSID,
                text: res[i].stationName
              });
            }
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    createSpace() {
      this.createData = { PID: "", LSID: "", spaceName: "" };
      this.createSpaceModal = true;
      if (this.roles === 1) {
        this.createStationOptions = [
          { value: "", text: this.$t("selectStation"), disabled: true }
        ];
      } else {
        this.createData.PID = this.$store.getters.PID;
        this.createStationOptions = [
          { value: "", text: this.$t("selectStation"), disabled: true }
        ];
        for (let i in this.stationOptions) {
          if (this.stationOptions[i].value !== "") {
            this.createStationOptions.push({
              value: this.stationOptions[i].value,
              text: this.stationOptions[i].text
            });
          }
        }
      }
    },
    storeSpace() {
      if (this.stateLSID === true && this.stateSpaceName === true) {
        this.$store
          .dispatch("storeSpace", this.createData)
          .then(res => {
            this.createSpaceModal = false;
            this.getSpaceByLSID();
            this.$swal("Success", this.$t("createSpaceSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    editSpace(prop) {
      this.editData = {
        LSSID: prop.LSSID,
        spaceName: prop.spaceName,
        spaceEnabled: prop.spaceEnabled,
        LPID: prop.LPID,
        lockId: prop.lockId
      };
      this.editSpaceModal = true;
    },
    bindList() {
      this.bindListData = [];
      this.$store
        .dispatch("getBindList")
        .then(res => {
          for (let i in res) {
            if (res[i].binded === 0) {
              this.bindListData.push(res[i]);
            }
          }
          this.bindListModal = true;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    bind(e) {
      this.editData.LPID = e.LPID;
      this.editData.lockId = e.lockId;
      this.bindListModal = false;
    },
    unbind() {
      this.editData.LPID = null;
      this.editData.lockId = null;
    },
    updateSpace() {
      if (this.stateEditSpaceName === true) {
        delete this.editData.lockId;
        this.$store
          .dispatch("updateSpace", this.editData)
          .then(res => {
            this.editSpaceModal = false;
            this.getSpaceByLSID();
            this.$swal("Success", this.$t("updatespaceSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    deleteSpace(prop) {
      this.deleteData = { LSSID: prop.LSSID, spaceName: prop.spaceName };
      this.deleteSpaceModal = true;
    },
    destroySpace() {
      this.$store
        .dispatch("destroySpace", this.deleteData.LSSID)
        .then(res => {
          this.deleteSpaceModal = false;
          this.getSpaceByLSID();
          this.$swal("Success", this.$t("deleteSpaceSuccess"), "success");
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    lockInfo(prop) {
      this.lockInfoData = prop;
      this.lockInfoModal = true;
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}
</style>
