<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img
          class="navbar-brand-full"
          src="img/brand/logo.svg"
          width="89"
          height="25"
          alt="Chainmeans Logo"
        />
        <img
          class="navbar-brand-minimized"
          src="img/brand/sygnet.svg"
          width="30"
          height="30"
          alt="Chainmeans Logo"
        />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">{{$t('dashboard')}}</b-nav-item>
        <b-nav-item class="px-3" to="/provider" v-show="roles === 1">{{$t('nav_provider')}}</b-nav-item>
        <b-nav-item class="px-3" to="/admin" v-show="roles <= 2">{{$t('nav_admin')}}</b-nav-item>
        <!-- <b-nav-item class="px-3">系統設定</b-nav-item> -->
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <b-dropdown variant="link" size="lg" toggle-class="text-decoration-none" no-caret>
            <template slot="button-content">
              <i class="fa fa-globe"></i>
            </template>
            <b-dropdown-item :value="defaultLanguage" @click="switchLocale(defaultLanguage)">繁體中文</b-dropdown-item>
            <b-dropdown-item
              v-for="lang in languageOptions"
              :key="lang.value"
              :value="lang.value"
              @click="switchLocale(lang.value)"
              :disabled="lang.disabled"
            >{{ lang.text }}</b-dropdown-item>
          </b-dropdown>
        </b-nav-item>
        <!--
        <b-nav-item class="d-md-down-none">
          <i class="icon-bell"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item>
        -->
        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>
      <!-- <AsideToggler class="d-none d-lg-block" />
      <AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="item"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <DefaultAside />
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://www.chainmeans.com">ShareLock Manager System</a>
        <span class="ml-1">&copy; {{new Date().getFullYear()}} Chainmeans.Ltd.</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Powered by</span>
        <a href="https://www.chainmeans.com">正旻科技有限公司</a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import nav from "@/_nav";
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  AsideToggler,
  Footer as TheFooter,
  Breadcrumb
} from "@coreui/vue";
import DefaultAside from "./DefaultAside";
import DefaultHeaderDropdownAccnt from "./DefaultHeaderDropdownAccnt";
import i18n from "@/lang/i18n";

export default {
  name: "DefaultContainer",
  components: {
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer
  },
  data() {
    return {
      nav: nav.items,
      defaultLanguage: "tw",
      languageOptions: [
        { value: "cn", text: "简体中文", disabled: true },
        { value: "en", text: "English", disabled: false },
        { value: "jp", text: "日本語", disabled: true }
      ],
      item: [],
      roles: this.$store.getters.roles
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        route => route.name || route.meta.label
      );
    }
  },
  mounted() {
    this.addItems();
  },
  methods: {
    switchLocale(e) {
      i18n.locale = e;
      localStorage.setItem("locale", e);
      location.reload();
    },
    addItems() {
      this.item = this.nav.filter(items => {
        return items.roles.includes(this.roles);
      });
    }
  }
};
</script>
