<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary">
          <div slot="header">
            <i class="fa fa-comments-o" style="margin-right: .5rem;"></i>
            <strong>{{$t('nav_maintain')}}</strong>
          </div>
          <b-row>
            <b-col>
              <b-nav tabs justified style="margin-bottom: 1rem;">
                <b-nav-item
                  :active="active === 'feedback'"
                  @click="changeTabs('feedback')"
                >{{$t('nav_customerServiceManagement')}}</b-nav-item>
                <b-nav-item
                  :active="active === 'operation'"
                  @click="changeTabs('operation')"
                >{{$t('nav_maintainManagement')}}</b-nav-item>
                <b-nav-item
                  :active="active === 'repair'"
                  @click="changeTabs('repair')"
                >{{$t('nav_repair')}}</b-nav-item>
              </b-nav>
            </b-col>
          </b-row>

          <!-- Feedback -->
          <b-row v-show="active === 'feedback'">
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select
                v-model="feedbackSearchData.feedbackType"
                :options="feedbackSelectOptions.feedbackType"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select
                v-model="feedbackSearchData.issueType"
                :options="feedbackSelectOptions.issueType"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-form-select
                v-model="feedbackSearchData.status"
                :options="feedbackSelectOptions.status"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <date-picker
                v-model="feedbackSearchData.dateSearch"
                :shortcuts="false"
                :editable="false"
                :not-after="this.$moment().format('YYYY-MM-DD')"
                range-separator="-"
                range
                width="100%"
                :lang="datePickerLanguage"
                value-type="format"
              ></date-picker>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <b-button
                class="action-btn"
                variant="info"
                @click="getFeedbackData()"
              >{{$t('search')}}</b-button>
              <b-button class="action-btn" variant="primary" @click="createFeedback()">{{$t('new')}}</b-button>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <hr />
              <div>
                <font class="text-muted">{{ searchInfoBar1 }}</font>
              </div>
              <hr />
              <div>
                <v-client-table
                  :columns="feedbackColumns"
                  :data="feedbackData"
                  :options="feedbackOptions"
                >
                  <div
                    slot="feedbackType"
                    slot-scope="props"
                  >{{ findOptions('feedback', 'feedbackType', props.row.feedbackType) }}</div>
                  <div
                    slot="issueType"
                    slot-scope="props"
                  >{{ findOptions('feedback', 'issueType', props.row.issueType) }}</div>
                  <div
                    slot="status"
                    slot-scope="props"
                  >{{ findOptions('feedback', 'status', props.row.status) }}</div>
                  <div slot="action" class="text-center" slot-scope="props">
                    <b-button
                      class="action-btn"
                      @click="feedbackInfo(props.row)"
                      variant="outline-primary"
                    >{{$t('dataInfo')}}</b-button>
                  </div>
                </v-client-table>
              </div>
            </b-col>
          </b-row>

          <b-modal
            v-model="createFeedbackModal"
            :title="$t('createFeedback')"
            header-bg-variant="primary"
            ok-only
          >
            <b-form-group
              :label="$t('feedbackFormType')"
              label-for="feedbackType"
              :label-cols="3"
              :state="stateFeedbackType"
            >
              <b-form-select
                id="feedbackType"
                v-model="createFeedbackData.feedbackType"
                :state="stateFeedbackType"
                :options="feedbackSelectOptions.feedbackType"
                disabled
                class="bg-white"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('issueType')"
              label-for="issueType"
              :label-cols="3"
              :state="stateIssueType"
            >
              <b-form-select
                id="issueType"
                v-model="createFeedbackData.issueType"
                :state="stateIssueType"
                :options="feedbackSelectOptions.issueType"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('content')"
              label-for="content"
              :label-cols="3"
              :state="stateContent"
            >
              <b-form-textarea
                id="content"
                v-model="createFeedbackData.content"
                :state="stateContent"
                placeholder="- content"
                rows="6"
                max-rows="20"
                style="overflow: auto;"
              ></b-form-textarea>
            </b-form-group>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="createFeedbackModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="storeFeedback()"
            >{{$t('ok')}}</b-button>
          </b-modal>

          <b-modal
            v-model="infoFeedbackModal"
            :title="$t('dataInfo')"
            header-bg-variant="primary"
            size="lg"
          >
            <b-card class="card-accent-secondary">
              <div slot="header">
                <i class="fa fa-comments-o"></i>
                {{$t('feedbackForm')}}
              </div>
              <b-form-group :label="$t('feedbackType')" label-for="feedbackType" :label-cols="3">
                <b-form-input
                  id="feedbackType"
                  type="text"
                  :value="infoFeedbackData.feedbackTypeInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('issueType')" label-for="issueType" :label-cols="3">
                <b-form-input
                  id="issueType"
                  type="text"
                  :value="infoFeedbackData.issueTypeInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('status')" label-for="status" :label-cols="3">
                <b-form-input
                  id="status"
                  type="text"
                  :value="infoFeedbackData.statusInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('stationName')"
                label-for="stationName"
                :label-cols="3"
                v-if="infoFeedbackData.stationName !== null"
              >
                <b-form-input
                  id="stationName"
                  type="text"
                  v-model="infoFeedbackData.stationName"
                  trim
                  placeholder="- stationName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('spaceName')"
                label-for="spaceName"
                :label-cols="3"
                v-if="infoFeedbackData.spaceName !== null"
              >
                <b-form-input
                  id="spaceName"
                  type="text"
                  v-model="infoFeedbackData.spaceName"
                  trim
                  placeholder="- spaceName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('lockId')"
                label-for="lockId"
                :label-cols="3"
                v-if="infoFeedbackData.lockId !== null"
              >
                <b-form-input
                  id="lockId"
                  type="text"
                  v-model="infoFeedbackData.lockId"
                  trim
                  placeholder="- lockId"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('handler')" label-for="displayName" :label-cols="3">
                <b-form-input
                  id="displayName"
                  type="text"
                  v-model="infoFeedbackData.displayName"
                  trim
                  placeholder="- displayName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('content')" label-for="content" :label-cols="3">
                <b-form-textarea
                  id="content"
                  v-model="infoFeedbackData.content"
                  placeholder="- content"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
            </b-card>
            <b-card class="card-accent-secondary" v-if="infoFeedbackReplyData.length > 0">
              <div slot="header">
                <i class="fa fa-comments-o"></i>
                {{$t('reply')}}
              </div>
              <b-form-group
                v-for="(r, idx) in infoFeedbackReplyData"
                :key="idx"
                :label="r.createAt"
                label-for="content"
                :label-cols="3"
              >
                <b-form-textarea
                  id="content"
                  v-model="r.content"
                  placeholder="- content"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
            </b-card>
            <template v-slot:modal-footer>
              <b-button
                class="action-btn"
                @click="editFeedback(infoFeedbackData, 1)"
                variant="success"
                v-if="infoFeedbackData.status === 0"
              >{{$t('getOrder')}}</b-button>
              <b-button
                class="action-btn"
                variant="success"
                v-if="infoFeedbackData.status !== 0"
                disabled
              >{{$t('getOrder')}}</b-button>
              <b-button
                class="action-btn"
                @click="createOperation(infoFeedbackData.FID, infoFeedbackData.LSSID, infoFeedbackData.LPID)"
                variant="primary"
                v-if="infoFeedbackData.status > 0 && infoFeedbackData.status !== 9 && infoFeedbackData.auID === auID"
              >{{$t('maintainOrder')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                v-if="infoFeedbackData.status === 0 || infoFeedbackData.status === 9 || infoFeedbackData.auID !== auID"
                disabled
              >{{$t('maintainOrder')}}</b-button>
              <b-button
                class="action-btn"
                @click="createRepair(infoFeedbackData.FID, infoFeedbackData.LSSID, infoFeedbackData.LPID)"
                variant="primary"
                v-if="infoFeedbackData.status > 0 && infoFeedbackData.status !== 9 && infoFeedbackData.auID === auID"
              >{{$t('repairOrder')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                v-if="infoFeedbackData.status === 0 || infoFeedbackData.status === 9 || infoFeedbackData.auID !== auID"
                disabled
              >{{$t('repairOrder')}}</b-button>
              <b-button
                class="action-btn"
                @click="createFeedbackReply(infoFeedbackData)"
                variant="warning"
                v-if="infoFeedbackData.status > 0 && infoFeedbackData.status !== 9 && infoFeedbackData.auID === auID"
              >{{$t('reply')}}</b-button>
              <b-button
                class="action-btn"
                variant="warning"
                v-if="infoFeedbackData.status === 0 || infoFeedbackData.status === 9 || infoFeedbackData.auID !== auID"
                disabled
              >{{$t('reply')}}</b-button>
              <b-button
                class="action-btn"
                @click="editFeedback(infoFeedbackData, 9)"
                variant="danger"
                v-if="infoFeedbackData.status === 2 && infoFeedbackData.auID === auID"
              >{{$t('end')}}</b-button>
              <b-button
                class="action-btn"
                variant="danger"
                v-if="infoFeedbackData.status !== 2 || infoFeedbackData.auID !== auID"
                disabled
              >{{$t('end')}}</b-button>
            </template>
          </b-modal>

          <b-modal
            v-model="updateFeedbackCheckModal"
            :title="$t('updateCheck')"
            size="sm"
            headerClass="p-2 border-bottom-0"
            footerClass="p-2 border-top-0"
            centered
            on-stacking
          >
            <h6>{{$t('areYouSure')}} {{ updateFeedbackData.checkMsg }} {{$t('thisFeedbackForm')}}</h6>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="updateFeedbackCheckModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="updateFeedback()"
            >{{$t('ok')}}</b-button>
          </b-modal>

          <b-modal
            v-model="createFeedbackReplyModal"
            :title="$t('replyData')"
            header-bg-variant="primary"
            ok-only
            on-stacking
          >
            <b-form-group
              :label="$t('replyContent')"
              label-for="content"
              :label-cols="3"
              :state="stateReplyContent"
            >
              <b-form-textarea
                id="content"
                v-model="createFeedbackReplyData.content"
                :state="stateReplyContent"
                placeholder="- content"
                rows="6"
                max-rows="20"
                style="overflow: auto;"
              ></b-form-textarea>
            </b-form-group>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="createFeedbackReplyModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="storeFeedbackReply()"
            >{{$t('ok')}}</b-button>
          </b-modal>
          <!-- Feedback End -->

          <!-- Operation -->
          <b-row v-show="active === 'operation'">
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select
                v-model="operationSearchData.issueType"
                :options="operationSelectOptions.issueType"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select
                v-model="operationSearchData.level"
                :options="operationSelectOptions.level"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-form-select
                v-model="operationSearchData.status"
                :options="operationSelectOptions.status"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <date-picker
                v-model="operationSearchData.dateSearch"
                :shortcuts="false"
                :editable="false"
                :not-after="this.$moment().format('YYYY-MM-DD')"
                range-separator="-"
                range
                width="100%"
                :lang="datePickerLanguage"
                value-type="format"
              ></date-picker>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <b-button
                class="action-btn"
                variant="info"
                @click="getOperationData()"
              >{{$t('search')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                @click="createOperation()"
              >{{$t('new')}}</b-button>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <hr />
              <div>
                <font class="text-muted">{{ searchInfoBar2 }}</font>
              </div>
              <hr />
              <div>
                <v-client-table
                  :columns="operationColumns"
                  :data="operationData"
                  :options="operationOptions"
                >
                  <div
                    slot="issueType"
                    slot-scope="props"
                  >{{ findOptions('operation', 'issueType', props.row.issueType) }}</div>
                  <div
                    slot="level"
                    slot-scope="props"
                  >{{ findOptions('operation', 'level', props.row.level) }}</div>
                  <div
                    slot="status"
                    slot-scope="props"
                  >{{ findOptions('operation', 'status', props.row.status) }}</div>
                  <div slot="action" class="text-center" slot-scope="props">
                    <b-button
                      class="action-btn"
                      @click="operationInfo(props.row)"
                      variant="outline-primary"
                    >{{$t('dataInfo')}}</b-button>
                  </div>
                </v-client-table>
              </div>
            </b-col>
          </b-row>

          <b-modal
            v-model="createOperationModal"
            :title="$t('createMaintainForm')"
            header-bg-variant="primary"
            ok-only
          >
            <b-form-group
              :label="$t('issueType')"
              label-for="issueType"
              :label-cols="3"
              :state="stateOperationIssueType"
            >
              <b-form-select
                id="issueType"
                v-model="createOperationData.issueType"
                :state="stateOperationIssueType"
                :options="operationSelectOptions.issueType"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('questionLevel')"
              label-for="level"
              :label-cols="3"
              :state="stateOperationLevel"
            >
              <b-form-select
                id="level"
                v-model="createOperationData.level"
                :state="stateOperationLevel"
                :options="operationSelectOptions.level"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('maintainStaff')"
              label-for="acceptAuID"
              :label-cols="3"
              :state="stateOperationAcceptAuID"
            >
              <b-form-select
                id="acceptAuID"
                v-model="createOperationData.acceptAuID"
                :state="stateOperationAcceptAuID"
                :options="operationSelectOptions.adminUser"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('maintainStation')"
              label-for="LSID"
              :label-cols="3"
              :state="stateOperationLSID"
              v-if="createOperationData.FID === null"
            >
              <b-form-select
                id="LSID"
                v-model="createOperationData.LSID"
                :state="stateOperationLSID"
                :options="operationSelectOptions.LSID"
                @change="operationGetSpaceByLSID()"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('maintainSpace')"
              label-for="LSSID"
              :label-cols="3"
              :state="stateOperationLSSID"
              v-if="createOperationData.FID === null"
            >
              <b-form-select
                id="LSSID"
                v-model="createOperationData.LSSID"
                :state="stateOperationLSSID"
                :options="operationSelectOptions.LSSID"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('content')"
              label-for="content"
              :label-cols="3"
              :state="stateOperationContent"
            >
              <b-form-textarea
                id="content"
                v-model="createOperationData.content"
                :state="stateOperationContent"
                placeholder="- content"
                rows="6"
                max-rows="20"
                style="overflow: auto;"
              ></b-form-textarea>
            </b-form-group>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="createOperationModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="storeOperation()"
            >{{$t('ok')}}</b-button>
          </b-modal>

          <b-modal
            v-model="infoOperationModal"
            :title="$t('dataInfo')"
            header-bg-variant="primary"
            size="lg"
          >
            <b-card class="card-accent-secondary">
              <div slot="header">
                <i class="fa fa-wrench"></i>
                {{$t('maintainOrder')}}
              </div>
              <b-form-group :label="$t('feedbackID')" label-for="FID" :label-cols="3">
                <b-button
                  @click="getFeedbackData(infoOperationData.FID)"
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoOperationData.FID !== null"
                >{{$t('dataInfo')}}</b-button>
                <b-button
                  disabled
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoOperationData.FID === null"
                >{{$t('dataInfo')}}</b-button>
              </b-form-group>
              <b-form-group :label="$t('issueType')" label-for="issueType" :label-cols="3">
                <b-form-input
                  id="issueType"
                  type="text"
                  :value="infoOperationData.issueTypeInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('questionLevel')" label-for="level" :label-cols="3">
                <b-form-input id="level" type="text" :value="infoOperationData.levelInfo" plaintext></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('status')" label-for="status" :label-cols="3">
                <b-form-input
                  id="status"
                  type="text"
                  :value="infoOperationData.statusInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('stationName')"
                label-for="stationName"
                :label-cols="3"
                v-if="infoOperationData.stationName !== null"
              >
                <b-form-input
                  id="stationName"
                  type="text"
                  v-model="infoOperationData.stationName"
                  trim
                  placeholder="- stationName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('spaceName')"
                label-for="spaceName"
                :label-cols="3"
                v-if="infoOperationData.spaceName !== null"
              >
                <b-form-input
                  id="spaceName"
                  type="text"
                  v-model="infoOperationData.spaceName"
                  trim
                  placeholder="- spaceName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('lockId')"
                label-for="lockId"
                :label-cols="3"
                v-if="infoOperationData.lockId !== null"
              >
                <b-form-input
                  id="lockId"
                  type="text"
                  v-model="infoOperationData.lockId"
                  trim
                  placeholder="- lockId"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('handler')" label-for="createDisplayName" :label-cols="3">
                <b-form-input
                  id="createDisplayName"
                  type="text"
                  v-model="infoOperationData.createDisplayName"
                  trim
                  placeholder="- createDisplayName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('content')" label-for="content" :label-cols="3">
                <b-form-textarea
                  id="content"
                  v-model="infoOperationData.content"
                  placeholder="- content"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                :label="$t('maintainStaff')"
                label-for="acceptDisplayName"
                :label-cols="3"
              >
                <b-form-input
                  id="acceptDisplayName"
                  type="text"
                  v-model="infoOperationData.acceptDisplayName"
                  trim
                  placeholder="-"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('content')" label-for="replyContent" :label-cols="3">
                <b-form-textarea
                  id="replyContent"
                  v-model="infoOperationData.replyContent"
                  placeholder="-"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
            </b-card>
            <template v-slot:modal-footer>
              <b-button
                class="action-btn"
                @click="createRepair(infoOperationData.FID, infoOperationData.MOID, infoOperationData.LSSID, infoOperationData.LPID)"
                variant="primary"
                v-if="infoOperationData.status === 2 || infoOperationData.status === 9"
              >{{$t('repairOrder')}}</b-button>
              <b-button
                class="action-btn"
                variant="primary"
                v-if="infoOperationData.status !== 2 && infoOperationData.status !== 9"
                disabled
              >{{$t('repairOrder')}}</b-button>
              <b-button
                class="action-btn"
                @click="editOperation(infoOperationData, 8)"
                variant="dark"
                v-if="infoOperationData.status !== 8 && infoOperationData.status !== 9"
              >{{$t('obsolete')}}</b-button>
              <b-button
                class="action-btn"
                variant="dark"
                v-if="infoOperationData.status === 8 || infoOperationData.status === 9"
                disabled
              >{{$t('obsolete')}}</b-button>
              <b-button
                class="action-btn"
                @click="editOperation(infoOperationData, 9)"
                variant="danger"
                v-if="infoOperationData.status === 2"
              >{{$t('end')}}</b-button>
              <b-button
                class="action-btn"
                variant="danger"
                v-if="infoOperationData.status !== 2"
                disabled
              >{{$t('end')}}</b-button>
            </template>
          </b-modal>

          <b-modal
            v-model="updateOperationCheckModal"
            :title="$t('updateCheck')"
            size="sm"
            headerClass="p-2 border-bottom-0"
            footerClass="p-2 border-top-0"
            centered
            on-stacking
          >
            <h6>{{$t('areYouSure')}} {{ updateOperationData.checkMsg }} {{$t('thisMaintainForm')}}</h6>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="updateOperationCheckModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="updateOperation()"
            >{{$t('ok')}}</b-button>
          </b-modal>
          <!-- Operation End -->

          <!-- Repair -->
          <b-row v-show="active === 'repair'">
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select
                v-model="repairSearchData.issueType"
                :options="repairSelectOptions.issueType"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="3" class="text-center">
              <b-form-select v-model="repairSearchData.level" :options="repairSelectOptions.level"></b-form-select>
            </b-col>
            <b-col cols="4" sm="3" md="2" class="text-center">
              <b-form-select
                v-model="repairSearchData.status"
                :options="repairSelectOptions.status"
              ></b-form-select>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <date-picker
                v-model="repairSearchData.dateSearch"
                :shortcuts="false"
                :editable="false"
                :not-after="this.$moment().format('YYYY-MM-DD')"
                range-separator="-"
                range
                width="100%"
                :lang="datePickerLanguage"
                value-type="format"
              ></date-picker>
            </b-col>
            <b-col cols="4" sm="2" md="2" class="text-center">
              <b-button class="action-btn" variant="info" @click="getRepairData()">{{$t('search')}}</b-button>
              <b-button class="action-btn" variant="primary" @click="createRepair()">{{$t('new')}}</b-button>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <hr />
              <div>
                <font class="text-muted">{{ searchInfoBar3 }}</font>
              </div>
              <hr />
              <div>
                <v-client-table
                  :columns="repairColumns"
                  :data="repairData"
                  :options="repairOptions"
                >
                  <div
                    slot="issueType"
                    slot-scope="props"
                  >{{ findOptions('repair', 'issueType', props.row.issueType) }}</div>
                  <div
                    slot="level"
                    slot-scope="props"
                  >{{ findOptions('repair', 'level', props.row.level) }}</div>
                  <div
                    slot="status"
                    slot-scope="props"
                  >{{ findOptions('repair', 'status', props.row.status) }}</div>
                  <div slot="isChanged" slot-scope="props">
                    <font v-if="props.row.isChanged === 1">{{$t('yes')}}</font>
                    <font v-if="props.row.isChanged === 0">{{$t('no')}}</font>
                  </div>
                  <div slot="action" class="text-center" slot-scope="props">
                    <b-button
                      class="action-btn"
                      @click="repairInfo(props.row)"
                      variant="outline-primary"
                    >{{$t('dataInfo')}}</b-button>
                  </div>
                </v-client-table>
              </div>
            </b-col>
          </b-row>

          <b-modal
            v-model="createRepairModal"
            :title="$t('createRepairForm')"
            header-bg-variant="primary"
            ok-only
          >
            <b-form-group
              :label="$t('issueType')"
              label-for="issueType"
              :label-cols="3"
              :state="stateRepairIssueType"
            >
              <b-form-select
                id="issueType"
                v-model="createRepairData.issueType"
                :state="stateRepairIssueType"
                :options="repairSelectOptions.issueType"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('questionLevel')"
              label-for="level"
              :label-cols="3"
              :state="stateRepairLevel"
            >
              <b-form-select
                id="level"
                v-model="createRepairData.level"
                :state="stateRepairLevel"
                :options="repairSelectOptions.level"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('serviceStaff')"
              label-for="acceptAuID"
              :label-cols="3"
              :state="stateRepairAcceptAuID"
            >
              <b-form-select
                id="acceptAuID"
                v-model="createRepairData.acceptAuID"
                :state="stateRepairAcceptAuID"
                :options="repairSelectOptions.adminUser"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('repairStation')"
              label-for="LSID"
              :label-cols="3"
              :state="stateRepairLSID"
              v-if="createRepairData.FID === null && createRepairData.MOID === null"
            >
              <b-form-select
                id="LSID"
                v-model="createRepairData.LSID"
                :state="stateRepairLSID"
                :options="repairSelectOptions.LSID"
                @change="repairGetSpaceByLSID()"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('repairSpace')"
              label-for="LSSID"
              :label-cols="3"
              :state="stateRepairLSSID"
              v-if="createRepairData.FID === null && createRepairData.MOID === null"
            >
              <b-form-select
                id="LSSID"
                v-model="createRepairData.LSSID"
                :state="stateRepairLSSID"
                :options="repairSelectOptions.LSSID"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('content')"
              label-for="content"
              :label-cols="3"
              :state="stateRepairContent"
            >
              <b-form-textarea
                id="content"
                v-model="createRepairData.content"
                :state="stateRepairContent"
                placeholder="- content"
                rows="6"
                max-rows="20"
                style="overflow: auto;"
              ></b-form-textarea>
            </b-form-group>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="createRepairModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="storeRepair()"
            >{{$t('ok')}}</b-button>
          </b-modal>

          <b-modal
            v-model="infoRepairModal"
            :title="$t('dataInfo')"
            header-bg-variant="primary"
            size="lg"
          >
            <b-card class="card-accent-secondary">
              <div slot="header">
                <i class="fa fa-wrench"></i>
                {{$t('repairOrder')}}
              </div>
              <b-form-group :label="$t('feedbackID')" label-for="FID" :label-cols="3">
                <b-button
                  @click="getFeedbackData(infoRepairData.FID)"
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoRepairData.FID !== null"
                >{{$t('dataInfo')}}</b-button>
                <b-button
                  disabled
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoRepairData.FID === null"
                >{{$t('dataInfo')}}</b-button>
              </b-form-group>
              <b-form-group :label="$t('maintainOrderID')" label-for="MOID" :label-cols="3">
                <b-button
                  @click="getOperationData(infoRepairData.MOID)"
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoRepairData.MOID !== null"
                >{{$t('dataInfo')}}</b-button>
                <b-button
                  disabled
                  variant="outline-primary"
                  class="btn-sm"
                  v-if="infoRepairData.MOID === null"
                >{{$t('dataInfo')}}</b-button>
              </b-form-group>
              <b-form-group :label="$t('issueType')" label-for="issueType" :label-cols="3">
                <b-form-input
                  id="issueType"
                  type="text"
                  :value="infoRepairData.issueTypeInfo"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('questionLevel')" label-for="level" :label-cols="3">
                <b-form-input id="level" type="text" :value="infoRepairData.levelInfo" plaintext></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('status')" label-for="status" :label-cols="3">
                <b-form-input id="status" type="text" :value="infoRepairData.statusInfo" plaintext></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('stationName')"
                label-for="stationName"
                :label-cols="3"
                v-if="infoRepairData.stationName !== null"
              >
                <b-form-input
                  id="stationName"
                  type="text"
                  v-model="infoRepairData.stationName"
                  trim
                  placeholder="- stationName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('spaceName')"
                label-for="spaceName"
                :label-cols="3"
                v-if="infoRepairData.spaceName !== null"
              >
                <b-form-input
                  id="spaceName"
                  type="text"
                  v-model="infoRepairData.spaceName"
                  trim
                  placeholder="- spaceName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group
                :label="$t('lockId')"
                label-for="lockId"
                :label-cols="3"
                v-if="infoRepairData.lockId !== null"
              >
                <b-form-input
                  id="lockId"
                  type="text"
                  v-model="infoRepairData.lockId"
                  trim
                  placeholder="- lockId"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('handler')" label-for="createDisplayName" :label-cols="3">
                <b-form-input
                  id="createDisplayName"
                  type="text"
                  v-model="infoRepairData.createDisplayName"
                  trim
                  placeholder="- createDisplayName"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('content')" label-for="content" :label-cols="3">
                <b-form-textarea
                  id="content"
                  v-model="infoRepairData.content"
                  placeholder="- content"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                :label="$t('serviceStaff')"
                label-for="acceptDisplayName"
                :label-cols="3"
              >
                <b-form-input
                  id="acceptDisplayName"
                  type="text"
                  v-model="infoRepairData.acceptDisplayName"
                  trim
                  placeholder="-"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('deviceExchange')" label-for="isChanged" :label-cols="3">
                <b-form-input
                  id="isChanged"
                  type="text"
                  v-model="infoRepairData.isChangedInfo"
                  trim
                  placeholder="-"
                  plaintext
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('content')" label-for="replyContent" :label-cols="3">
                <b-form-textarea
                  id="replyContent"
                  v-model="infoRepairData.replyContent"
                  placeholder="-"
                  max-rows="20"
                  style="overflow: auto;"
                  plaintext
                ></b-form-textarea>
              </b-form-group>
            </b-card>
            <template v-slot:modal-footer>
              <b-button
                class="action-btn"
                @click="editRepair(infoRepairData, 8)"
                variant="dark"
                v-if="infoRepairData.status !== 8 && infoRepairData.status !== 9"
              >{{$t('obsolete')}}</b-button>
              <b-button
                class="action-btn"
                variant="dark"
                v-if="infoRepairData.status === 8 || infoRepairData.status === 9"
                disabled
              >{{$t('obsolete')}}</b-button>
              <b-button
                class="action-btn"
                @click="editRepair(infoRepairData, 9)"
                variant="danger"
                v-if="infoRepairData.status === 2"
              >{{$t('end')}}</b-button>
              <b-button
                class="action-btn"
                variant="danger"
                v-if="infoRepairData.status !== 2"
                disabled
              >{{$t('end')}}</b-button>
            </template>
          </b-modal>

          <b-modal
            v-model="updateRepairCheckModal"
            :title="$t('updateCheck')"
            size="sm"
            headerClass="p-2 border-bottom-0"
            footerClass="p-2 border-top-0"
            centered
            on-stacking
          >
            <h6>{{$t('areYouSure')}} {{ updateRepairData.checkMsg }} {{$t('thisRepairForm')}}</h6>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="secondary"
              @click="updateRepairCheckModal=false"
            >{{$t('cancel')}}</b-button>
            <b-button
              slot="modal-footer"
              class="float-right"
              variant="primary"
              @click="updateRepair()"
            >{{$t('ok')}}</b-button>
          </b-modal>
          <!-- Repair End -->
        </b-card>
      </b-col>
    </b-row>

    <loading :show="show" :label="label" :overlay="overlay">
      <GridLoader slot="loading-spinner" color="#f5a623" :size="10" style="display:inline-block;"></GridLoader>
    </loading>
  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: 0.25rem !important;
}
.VueTables__limit-field select {
  margin-left: 0.25rem !important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from "vue-full-loading";
import DatePicker from "vue2-datepicker";

export default {
  name: "maintain",
  components: {
    loading,
    DatePicker
  },
  data: function() {
    return {
      show: false,
      label: "Loading...",
      overlay: true,
      auID: this.$store.getters.auID,
      searchInfoBar1: "",
      searchInfoBar2: "",
      searchInfoBar3: "",
      datePickerLanguage: {
        days: [
          this.$t("sun"),
          this.$t("mon"),
          this.$t("tue"),
          this.$t("wed"),
          this.$t("thu"),
          this.$t("fri"),
          this.$t("sat")
        ],
        months: [
          this.$t("jan"),
          this.$t("feb"),
          this.$t("mar"),
          this.$t("apr"),
          this.$t("may"),
          this.$t("jun"),
          this.$t("jul"),
          this.$t("aug"),
          this.$t("sep"),
          this.$t("oct"),
          this.$t("nov"),
          this.$t("dec")
        ],
        placeholder: {
          date: this.$t("select"),
          dateRange: this.$t("select")
        }
      },
      active: "feedback",
      reg: RegExp(/^(99|[0-9])$/),
      // Feedback
      feedbackSearchData: {
        feedbackType: "",
        issueType: "",
        status: "",
        dateSearch: [
          this.$moment().format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD")
        ]
      },
      feedbackSelectOptions: {
        feedbackType: [
          { value: "", text: this.$t("allFeedbackType") },
          { value: 1, text: this.$t("opinion") },
          { value: 2, text: this.$t("question") },
          { value: 3, text: this.$t("addManually") }
        ],
        issueType: [
          { value: "", text: this.$t("allissueType") },
          { value: 1, text: this.$t("cantParking") },
          { value: 2, text: this.$t("cantGetting") },
          { value: 3, text: this.$t("mobilePayment") },
          { value: 4, text: this.$t("appIssue") },
          { value: 5, text: this.$t("broken") },
          { value: 99, text: this.$t("other") }
        ],
        status: [
          { value: "", text: this.$t("allStatus") },
          { value: 0, text: this.$t("unprocessed") },
          { value: 1, text: this.$t("processing") },
          { value: 2, text: this.$t("replyed") },
          { value: 9, text: this.$t("end") }
        ]
      },
      feedbackColumns: [
        "feedbackType",
        "issueType",
        "displayName",
        "status",
        "createAt",
        "action"
      ],
      feedbackData: [],
      feedbackOptions: {
        headings: {
          feedbackType: this.$t("feedbackType"),
          issueType: this.$t("issueType"),
          displayName: this.$t("handler"),
          status: this.$t("status"),
          createAt: this.$t("createAt"),
          action: this.$t("action")
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        },
        sortable: [
          "feedbackType",
          "issueType",
          "displayName",
          "status",
          "createAt"
        ],
        filterable: [
          "feedbackType",
          "issueType",
          "displayName",
          "status",
          "createAt"
        ]
      },
      createFeedbackModal: false,
      createFeedbackData: { feedbackType: "", issueType: "", content: "" },
      updateFeedbackCheckModal: false,
      updateFeedbackData: {},
      infoFeedbackModal: false,
      infoFeedbackData: {},
      infoFeedbackReplyData: [],
      createFeedbackReplyModal: false,
      createFeedbackReplyData: { FID: "", content: "" },
      // Feedback End
      // Operation
      operationSearchData: {
        issueType: "",
        level: "",
        status: "",
        dateSearch: [
          this.$moment().format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD")
        ]
      },
      operationSelectOptions: {
        issueType: [
          { value: "", text: this.$t("allQuestionLevel") },
          { value: 1, text: this.$t("repairIssueTypeMsg1") },
          { value: 2, text: this.$t("repairIssueTypeMsg2") },
          { value: 3, text: this.$t("repairIssueTypeMsg3") },
          { value: 4, text: this.$t("repairIssueTypeMsg4") },
          { value: 5, text: this.$t("repairIssueTypeMsg5") },
          { value: 6, text: this.$t("repairIssueTypeMsg6") },
          { value: 7, text: this.$t("repairIssueTypeMsg7") },
          { value: 8, text: this.$t("repairIssueTypeMsg8") },
          { value: 99, text: this.$t("other") }
        ],
        level: [
          { value: "", text: this.$t("allQuestionLevel") },
          { value: 1, text: this.$t("repairLevel1") },
          { value: 2, text: this.$t("repairLevel2") },
          { value: 3, text: this.$t("repairLevel3") },
          { value: 4, text: this.$t("repairLevel4") },
          { value: 5, text: this.$t("repairLevel5") }
        ],
        status: [
          { value: "", text: this.$t("allStatus") },
          { value: 0, text: this.$t("unprocessed") },
          { value: 1, text: this.$t("processing") },
          { value: 2, text: this.$t("confirmOnSite") },
          { value: 8, text: this.$t("obsolete") },
          { value: 9, text: this.$t("end") }
        ],
        adminUser: [{ value: "", text: this.$t("select"), disabled: true }],
        LSID: [{ value: "", text: this.$t("select"), disabled: true }],
        LSSID: [{ value: "", text: this.$t("select"), disabled: true }]
      },
      operationColumns: [
        "issueType",
        "level",
        "createDisplayName",
        "status",
        "acceptDisplayName",
        "createAt",
        "action"
      ],
      operationData: [],
      operationOptions: {
        headings: {
          issueType: this.$t("issueType"),
          level: this.$t("questionLevel"),
          createDisplayName: this.$t("handler"),
          status: this.$t("handler"),
          acceptDisplayName: this.$t("maintainStaff"),
          createAt: this.$t("createAt"),
          action: this.$t("action")
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        },
        sortable: [
          "issueType",
          "level",
          "createDisplayName",
          "status",
          "acceptDisplayName",
          "createAt"
        ],
        filterable: [
          "issueType",
          "level",
          "createDisplayName",
          "status",
          "acceptDisplayName",
          "createAt"
        ]
      },
      createOperationModal: false,
      createOperationData: {
        FID: null,
        issueType: "",
        level: "",
        content: "",
        acceptAuID: "",
        LPID: "",
        LSSID: "",
        LSID: ""
      },
      updateOperationCheckModal: false,
      updateOperationData: {},
      infoOperationModal: false,
      infoOperationData: {},
      // Operation End
      // Repair
      repairSearchData: {
        issueType: "",
        level: "",
        status: "",
        dateSearch: [
          this.$moment().format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD")
        ]
      },
      repairSelectOptions: {
        issueType: [
          { value: "", text: this.$t("allQuestionLevel") },
          { value: 1, text: this.$t("repairIssueTypeMsg1") },
          { value: 2, text: this.$t("repairIssueTypeMsg2") },
          { value: 3, text: this.$t("repairIssueTypeMsg3") },
          { value: 4, text: this.$t("repairIssueTypeMsg4") },
          { value: 5, text: this.$t("repairIssueTypeMsg5") },
          { value: 6, text: this.$t("repairIssueTypeMsg6") },
          { value: 7, text: this.$t("repairIssueTypeMsg7") },
          { value: 8, text: this.$t("repairIssueTypeMsg8") },
          { value: 99, text: this.$t("other") }
        ],
        level: [
          { value: "", text: this.$t("allQuestionLevel") },
          { value: 1, text: this.$t("repairLevel1") },
          { value: 2, text: this.$t("repairLevel2") },
          { value: 3, text: this.$t("repairLevel3") },
          { value: 4, text: this.$t("repairLevel4") },
          { value: 5, text: this.$t("repairLevel5") }
        ],
        status: [
          { value: "", text: this.$t("allStatus") },
          { value: 0, text: this.$t("unprocessed") },
          { value: 1, text: this.$t("processing") },
          { value: 2, text: this.$t("confirmOnSite") },
          { value: 3, text: this.$t("repaired") },
          { value: 8, text: this.$t("obsolete") },
          { value: 9, text: this.$t("end") }
        ],
        adminUser: [{ value: "", text: this.$t("select"), disabled: true }],
        LSID: [{ value: "", text: this.$t("select"), disabled: true }],
        LSSID: [{ value: "", text: this.$t("select"), disabled: true }]
      },
      repairColumns: [
        "issueType",
        "level",
        "createDisplayName",
        "status",
        "acceptDisplayName",
        "isChanged",
        "createAt",
        "action"
      ],
      repairData: [],
      repairOptions: {
        headings: {
          issueType: this.$t("issueType"),
          level: this.$t("questionLevel"),
          createDisplayName: this.$t("handler"),
          status: this.$t("handler"),
          acceptDisplayName: this.$t("serviceStaff"),
          isChanged: this.$t("deviceExchange"),
          createAt: this.$t("createAt"),
          action: this.$t("action")
        },
        sortIcon: {
          base: "fa",
          is: "fa-sort",
          up: "fa-sort-asc",
          down: "fa-sort-desc"
        },
        sortable: [
          "issueType",
          "level",
          "createDisplayName",
          "status",
          "acceptDisplayName",
          "isChanged",
          "createAt"
        ],
        filterable: [
          "issueType",
          "level",
          "createDisplayName",
          "status",
          "acceptDisplayName",
          "isChanged",
          "createAt"
        ]
      },
      createRepairModal: false,
      createRepairData: {
        FID: null,
        MOID: null,
        issueType: "",
        level: "",
        content: "",
        acceptAuID: "",
        LPID: "",
        LSSID: "",
        LSID: ""
      },
      updateRepairCheckModal: false,
      updateRepairData: {},
      infoRepairModal: false,
      infoRepairData: {}
      // Repair End
    };
  },
  computed: {
    // Feedback
    stateFeedbackType() {
      return this.reg.test(this.createFeedbackData.feedbackType);
    },
    stateIssueType() {
      return this.reg.test(this.createFeedbackData.issueType);
    },
    stateContent() {
      return this.createFeedbackData.content.length > 0;
    },
    stateReplyContent() {
      return this.createFeedbackReplyData.content.length > 0;
    },
    // Feedback End
    // Operation
    stateOperationIssueType() {
      return this.reg.test(this.createOperationData.issueType);
    },
    stateOperationLevel() {
      return this.reg.test(this.createOperationData.level);
    },
    stateOperationAcceptAuID() {
      return this.createOperationData.acceptAuID.length > 0;
    },
    stateOperationLSID() {
      return this.createOperationData.LSID.length > 0;
    },
    stateOperationLSSID() {
      return this.createOperationData.LSSID.length > 0;
    },
    stateOperationContent() {
      return this.createOperationData.content.length > 0;
    },
    // Operation End
    // Repair
    stateRepairIssueType() {
      return this.reg.test(this.createRepairData.issueType);
    },
    stateRepairLevel() {
      return this.reg.test(this.createRepairData.level);
    },
    stateRepairAcceptAuID() {
      return this.createRepairData.acceptAuID.length > 0;
    },
    stateRepairLSID() {
      return this.createRepairData.LSID.length > 0;
    },
    stateRepairLSSID() {
      return this.createRepairData.LSSID.length > 0;
    },
    stateRepairContent() {
      return this.createRepairData.content.length > 0;
    }
    // Repair End
  },
  mounted() {
    this.getAdminUserByPID();
    this.getStationByPID();
  },
  methods: {
    changeTabs(e) {
      this.active = e;
    },
    findOptions(tab, arr, val) {
      let idx;
      switch (tab) {
        case "feedback":
          switch (arr) {
            case "feedbackType":
              idx = this.feedbackSelectOptions.feedbackType.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.feedbackSelectOptions.feedbackType[idx].text;
            case "issueType":
              idx = this.feedbackSelectOptions.issueType.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.feedbackSelectOptions.issueType[idx].text;
            case "status":
              idx = this.feedbackSelectOptions.status.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.feedbackSelectOptions.status[idx].text;
            default:
              return "unknown";
          }
        case "operation":
          switch (arr) {
            case "issueType":
              idx = this.operationSelectOptions.issueType.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.operationSelectOptions.issueType[idx].text;
            case "level":
              idx = this.operationSelectOptions.level.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.operationSelectOptions.level[idx].text;
            case "status":
              idx = this.operationSelectOptions.status.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.operationSelectOptions.status[idx].text;
            case "LPID":
              idx = this.operationSelectOptions.LSSID.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.operationSelectOptions.LSSID[idx].LPID;
            default:
              return "unknown";
          }
        case "repair":
          switch (arr) {
            case "issueType":
              idx = this.repairSelectOptions.issueType.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.repairSelectOptions.issueType[idx].text;
            case "level":
              idx = this.repairSelectOptions.level.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.repairSelectOptions.level[idx].text;
            case "status":
              idx = this.repairSelectOptions.status.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.repairSelectOptions.status[idx].text;
            case "LPID":
              idx = this.repairSelectOptions.LSSID.findIndex(function(
                item,
                index,
                array
              ) {
                return item.value === val;
              });
              return this.repairSelectOptions.LSSID[idx].LPID;
            default:
              return "unknown";
          }
        default:
          return "unknown";
      }
    },
    getAdminUserByPID() {
      this.operationSelectOptions.adminUser = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.repairSelectOptions.adminUser = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.$store
        .dispatch("getAdminUserByPID", { pid: this.$store.getters.PID })
        .then(res => {
          for (let i in res) {
            if (res[i].roles === 11 && res[i].wsPunchIn === 1) {
              this.operationSelectOptions.adminUser.push({
                value: res[i].auID,
                text:
                  res[i].displayName +
                  " (" +
                  (res[i].wsConnecting === 1
                    ? this.$t("connected")
                    : this.$t("unconnected")) +
                  ")"
              });
            } else if (res[i].roles === 12 && res[i].wsPunchIn === 1) {
              this.repairSelectOptions.adminUser.push({
                value: res[i].auID,
                text:
                  res[i].displayName +
                  " (" +
                  (res[i].wsConnecting === 1
                    ? this.$t("connected")
                    : this.$t("unconnected")) +
                  ")"
              });
            }
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    getStationByPID() {
      this.operationSelectOptions.LSID = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.repairSelectOptions.LSID = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.$store
        .dispatch("getStationByPID", { PID: this.$store.getters.PID })
        .then(res => {
          for (let i in res) {
            this.operationSelectOptions.LSID.push({
              value: res[i].LSID,
              text: res[i].stationName
            });
            this.repairSelectOptions.LSID.push({
              value: res[i].LSID,
              text: res[i].stationName
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    // Feedback
    getFeedbackData(FID) {
      let startTime = this.$moment();
      this.$store
        .dispatch(
          "getFeedbackData",
          FID && FID.length > 0 ? { FID: FID } : this.feedbackSearchData
        )
        .then(res => {
          for (let i in res) {
            res[i].createAt = this.$moment(res[i].createAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          FID && FID.length > 0
            ? this.feedbackInfo(res[0])
            : (this.feedbackData = res);
          this.searchInfoBar1 =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.feedbackData.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
        })
        .catch(e => {
          this.searchInfoBar1 = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
        });
    },
    createFeedback() {
      this.createFeedbackData = { feedbackType: 3, issueType: "", content: "" };
      this.createFeedbackModal = true;
    },
    storeFeedback() {
      if (this.stateFeedbackType && this.stateIssueType && this.stateContent) {
        this.$store
          .dispatch("storeFeedback", this.createFeedbackData)
          .then(res => {
            this.createFeedbackModal = false;
            this.getFeedbackData();
            this.$swal("Success", this.$t("createSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    feedbackInfo(prop) {
      this.infoFeedbackData = prop;
      this.infoFeedbackData.feedbackTypeInfo = this.findOptions(
        "feedback",
        "feedbackType",
        this.infoFeedbackData.feedbackType
      );
      this.infoFeedbackData.issueTypeInfo = this.findOptions(
        "feedback",
        "issueType",
        this.infoFeedbackData.issueType
      );
      this.infoFeedbackData.statusInfo = this.findOptions(
        "feedback",
        "status",
        this.infoFeedbackData.status
      );
      this.$store
        .dispatch("getFeedbackReplyData", { FID: prop.FID })
        .then(res => {
          for (let i in res) {
            res[i].createAt = this.$moment(res[i].createAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.infoFeedbackReplyData = res;
          this.infoFeedbackModal = true;
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    editFeedback(prop, status) {
      let checkMsg =
        status === 1
          ? this.$t("getOrder")
          : status === 9
          ? this.$t("end")
          : null;
      if (checkMsg !== null) {
        this.updateFeedbackData = {
          checkMsg: checkMsg,
          FID: prop.FID,
          status: status
        };
        this.updateFeedbackCheckModal = true;
      }
    },
    updateFeedback() {
      this.$store
        .dispatch("updateFeedback", this.updateFeedbackData)
        .then(res => {
          this.updateFeedbackCheckModal = false;
          this.infoFeedbackModal = false;
          this.getFeedbackData();
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    createFeedbackReply(prop) {
      this.createFeedbackReplyData = { FID: prop.FID, content: "" };
      this.createFeedbackReplyModal = true;
    },
    storeFeedbackReply() {
      if (this.stateReplyContent) {
        this.$store
          .dispatch("storeFeedbackReply", this.createFeedbackReplyData)
          .then(res => {
            this.createFeedbackReplyModal = false;
            this.updateFeedbackData = {
              FID: this.createFeedbackReplyData.FID,
              status: 2
            };
            this.updateFeedback();
            this.$swal("Success", this.$t("replySuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    // Feedback End
    // Operation
    getOperationData(MOID) {
      let startTime = this.$moment();
      this.$store
        .dispatch(
          "getOperationData",
          MOID && MOID.length > 0 ? { MOID: MOID } : this.operationSearchData
        )
        .then(res => {
          for (let i in res) {
            res[i].createAt = this.$moment(res[i].createAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          MOID && MOID.length > 0
            ? this.operationInfo(res[0])
            : (this.operationData = res);
          this.searchInfoBar2 =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.operationData.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
        })
        .catch(e => {
          this.searchInfoBar2 = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
        });
    },
    createOperation(FID, LSSID, LPID) {
      this.createOperationData = {
        FID: FID && FID.length > 0 ? FID : null,
        issueType: "",
        level: "",
        content: "",
        acceptAuID: "",
        LPID: LPID && LPID.length > 0 ? LPID : "",
        LSSID: LSSID && LSSID.length > 0 ? LSSID : "",
        LSID: ""
      };
      this.createOperationModal = true;
    },
    storeOperation() {
      if (
        this.stateOperationIssueType &&
        this.stateOperationLevel &&
        this.stateOperationAcceptAuID &&
        this.stateOperationLSSID &&
        this.stateOperationContent
      ) {
        this.$store
          .dispatch("storeOperation", this.createOperationData)
          .then(res => {
            this.createOperationModal = false;
            this.getOperationData();
            this.$swal("Success", this.$t("createSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    operationInfo(prop) {
      this.infoOperationData = prop;
      this.infoOperationData.issueTypeInfo = this.findOptions(
        "operation",
        "issueType",
        this.infoOperationData.issueType
      );
      this.infoOperationData.levelInfo = this.findOptions(
        "operation",
        "level",
        this.infoOperationData.issueType
      );
      this.infoOperationData.statusInfo = this.findOptions(
        "operation",
        "status",
        this.infoOperationData.status
      );
      this.infoOperationModal = true;
    },
    editOperation(prop, status) {
      let checkMsg =
        status === 8
          ? this.$t("obsolete")
          : status === 9
          ? this.$t("end")
          : null;
      if (checkMsg !== null) {
        this.updateOperationData = {
          checkMsg: checkMsg,
          MOID: prop.MOID,
          status: status
        };
        this.updateOperationCheckModal = true;
      }
    },
    updateOperation() {
      this.$store
        .dispatch("updateOperation", this.updateOperationData)
        .then(res => {
          this.updateOperationCheckModal = false;
          this.infoOperationModal = false;
          this.getOperationData();
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    operationGetSpaceByLSID() {
      this.operationSelectOptions.LSSID = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.$store
        .dispatch("getSpaceByLSID", { LSID: this.createOperationData.LSID })
        .then(res => {
          for (let i in res) {
            this.operationSelectOptions.LSSID.push({
              value: res[i].LSSID,
              text: res[i].spaceName + " (" + res[i].lockId + ")",
              LPID: res[i].LPID
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    // Operation End
    // Repair
    getRepairData() {
      let startTime = this.$moment();
      this.$store
        .dispatch("getRepairData", this.repairSearchData)
        .then(res => {
          for (let i in res) {
            res[i].createAt = this.$moment(res[i].createAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          }
          this.repairData = res;
          this.searchInfoBar3 =
            this.$t("lastSearchAt") +
            this.$moment().format("YYYY-MM-DD HH:mm:ss") +
            this.$t("total") +
            this.repairData.length +
            this.$t("records") +
            "（" +
            this.$t("searchTime") +
            this.$moment().diff(startTime) / 1000 +
            this.$t("second") +
            "）";
        })
        .catch(e => {
          this.searchInfoBar3 = this.$t("searchInfoErrorMsg");
          this.$swal("Error", e, "error");
        });
    },
    createRepair(FID, MOID, LSSID, LPID) {
      this.createRepairData = {
        FID: FID && FID.length > 0 ? FID : null,
        MOID: MOID && MOID.length > 0 ? MOID : null,
        issueType: "",
        level: "",
        content: "",
        acceptAuID: "",
        LPID: LPID && LPID.length > 0 ? LPID : "",
        LSSID: LSSID && LSSID.length > 0 ? LSSID : "",
        LSID: ""
      };
      this.createRepairModal = true;
    },
    storeRepair() {
      if (
        this.stateRepairIssueType &&
        this.stateRepairLevel &&
        this.stateRepairAcceptAuID &&
        this.stateRepairLSSID &&
        this.stateRepairContent
      ) {
        this.createRepairData.LPID = this.findOptions(
          "repair",
          "LPID",
          this.createRepairData.LSSID
        );
        this.$store
          .dispatch("storeRepair", this.createRepairData)
          .then(res => {
            this.createRepairModal = false;
            this.getRepairData();
            this.$swal("Success", this.$t("createSuccess"), "success");
          })
          .catch(e => {
            this.$swal("Error", e, "error");
          });
      }
    },
    repairInfo(prop) {
      this.infoRepairData = prop;
      this.infoRepairData.issueTypeInfo = this.findOptions(
        "repair",
        "issueType",
        this.infoRepairData.issueType
      );
      this.infoRepairData.levelInfo = this.findOptions(
        "repair",
        "level",
        this.infoRepairData.issueType
      );
      this.infoRepairData.statusInfo = this.findOptions(
        "repair",
        "status",
        this.infoRepairData.status
      );
      this.infoRepairData.isChangedInfo =
        this.infoRepairData.isChanged === 1 ? this.$t("yes") : this.$t("no");
      this.infoRepairModal = true;
    },
    editRepair(prop, status) {
      let checkMsg =
        status === 8
          ? this.$t("obsolete")
          : status === 9
          ? this.$t("end")
          : null;
      if (checkMsg !== null) {
        this.updateRepairData = {
          checkMsg: checkMsg,
          MRID: prop.MRID,
          status: status
        };
        this.updateRepairCheckModal = true;
      }
    },
    updateRepair() {
      this.$store
        .dispatch("updateRepair", this.updateRepairData)
        .then(res => {
          this.updateRepairCheckModal = false;
          this.infoRepairModal = false;
          this.getRepairData();
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    },
    repairGetSpaceByLSID() {
      this.repairSelectOptions.LSSID = [
        { value: "", text: this.$t("select"), disabled: true }
      ];
      this.$store
        .dispatch("getSpaceByLSID", { LSID: this.createRepairData.LSID })
        .then(res => {
          for (let i in res) {
            this.repairSelectOptions.LSSID.push({
              value: res[i].LSSID,
              text: res[i].spaceName + " (" + res[i].lockId + ")",
              LPID: res[i].LPID
            });
          }
        })
        .catch(e => {
          this.$swal("Error", e, "error");
        });
    }
    // Repair End
  }
};
</script>
