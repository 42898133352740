<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="12">
        <b-card class="card-accent-secondary" >
          <div slot="header">
            <i class="fa fa-search" style="margin-right: .5rem;"></i>
            <strong>訂單管理</strong>
          </div>
          <b-row>
            <b-col cols="6" sm="2" md="3" class="text-center">
              <b-form-input
              v-model="searchData.orderId"
              type="text"
              trim
              placeholder="- 訂單編號"></b-form-input>
            </b-col>
            <b-col cols="6" sm="2" md="2" class="text-center">
              <b-form-select
              v-model="searchData.type"
              :options="orderTypeOptions"></b-form-select>
            </b-col>
            <b-col cols="5" sm="3" md="3" class="text-center">
              <b-form-input
              v-model="searchData.startAt"
              type="date"></b-form-input>
            </b-col>
            <b-col cols="5" sm="3" md="3" class="text-center">
              <b-form-input
              v-model="searchData.endAt"
              type="date"></b-form-input>
            </b-col>
            <b-col cols="2" sm="2" md="1" class="text-center">
              <b-button class="action-btn" variant="info" @click="getOrderData()">搜尋</b-button>
            </b-col>
          </b-row>
          <hr>
          <div>
            <v-client-table :columns="columns" :data="data" :options="options">
              <div slot="username" slot-scope="props">
                {{ props.row.username }}
              </div>
              <div slot="type" slot-scope="props">
                <p v-if="props.row.type === 'storage'">保管</p>
                <p v-if="props.row.type === 'rent'">租借</p>
              </div>
              <div slot="lockId" slot-scope="props">
                {{ props.row.endLockId }}
              </div>
              <div slot="orderStatus" slot-scope="props">
                <p v-if="props.row.orderStatus === 'waitInitial'">待初始</p>
                <p v-if="props.row.orderStatus === 'billing'">計費中</p>
                <p v-if="props.row.orderStatus === 'waitPayment'">未付款</p>
                <p v-if="props.row.orderStatus === 'paid'">已付款</p>
                <p v-if="props.row.orderStatus === 'obsolete'">作廢</p>
              </div>
              <div slot="endAt" slot-scope="props">
                {{ $moment(props.row.endAt).format('YYYY-MM-DD HH:mm:ss') }}
              </div>
              <div slot="action" slot-scope="props" class="text-center">
                <b-button class="action-btn" @click="orderInfo(props.row)" variant="outline-primary">詳細資料</b-button>
                <b-button class="action-btn" @click="editOrder(props.row)" variant="outline-warning" v-if="roles <= 2" disabled>編輯</b-button>
              </div>
            </v-client-table>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="editOrderModal" title="編輯訂單" header-bg-variant="warning" ok-only>
      <b-form-group
        label="訂單編號"
        label-for="orderId"
        :label-cols="3">
        <b-form-input id="orderId" type="text" v-model="editData.orderId" trim plaintext></b-form-input>
      </b-form-group>
      <b-form-group
        label="訂單狀態"
        label-for="orderStatus"
        :label-cols="3">
        <b-form-select id="orderStatus" v-model="editData.orderStatus" trim :options="orderStatusOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="計費方式"
        label-for="BPID"
        :label-cols="3">
        <b-form-select id="BPID" v-model="editData.BPID" trim :options="BPIDOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="訂單金額"
        label-for="orderPrice"
        :label-cols="3">
        <b-form-input id="orderPrice" type="number" v-model="editData.orderPrice" trim></b-form-input>
      </b-form-group>
      <b-button slot="modal-footer" class="float-right" variant="secondary" @click="editOrderModal=false">取消</b-button>
      <b-button slot="modal-footer" class="float-right" variant="primary" @click="updateOrder()">確定</b-button>
    </b-modal>

    <b-modal v-model="orderInfoModal" title="詳細資料" header-bg-variant="primary" size="lg" hide-footer on-stacking>
      <b-card class="card-accent-info" >
        <div slot="header">
          <i class='fa fa-info'></i> 詳細資料
        </div>
        <b-row>
          <b-col cols="12" sm="6">
            <p>訂單編號 : {{orderInfoData.orderId}}</p>
            <p>用戶帳號 : {{orderInfoData.username}}</p>
            <p>訂單類型 :
              <font v-if="orderInfoData.type === 'storage'">保管</font>
              <font v-if="orderInfoData.type === 'rent'">租借</font>
            </p>
            <p>訂單狀態 :
              <font v-if="orderInfoData.orderStatus === 'billing'">計費中</font>
              <font v-if="orderInfoData.orderStatus === 'waitPayment'">未付款</font>
              <font v-if="orderInfoData.orderStatus === 'paid'">已付款</font>
              <font v-if="orderInfoData.orderStatus === 'obsolete'">作廢</font>
            </p>
            <p>訂單成立時間 : {{orderInfoData.startAt}}</p>
          </b-col>
          <b-col cols="12" sm="6">
            <p>計費方式 : {{orderInfoData.planName}}</p>
            <p>訂單金額 : {{orderInfoData.orderPrice}}</p>
            <p>車輛類型 : {{orderInfoData.bikeType}}</p>
            <p>車輛標籤ID : {{orderInfoData.bikeTagId}}</p>
            <p>訂單結束時間 : {{orderInfoData.endAt}}</p>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <loading
      :show="show"
      :label="label"
      :overlay="overlay">
      <GridLoader slot="loading-spinner"
                  color="#f5a623"
                  :size=10
                  style="display:inline-block;"
                  >
      </GridLoader>
    </loading>

  </div>
</template>

<style>
.VueTables__child-row-toggler {
  width: 10px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}
.VueTables__search-field {
  display: flex;
}
.VueTables__limit-field {
  display: flex;
}
.VueTables__search-field input {
  margin-left: .25rem!important;
}
.VueTables__limit-field select {
  margin-left: .25rem!important;
}
.action-btn {
  margin: 0 0.25rem;
}
</style>

<script>
import loading from 'vue-full-loading'

export default {
  name: 'orderSearch',
  components: {
    loading
  },
  data: function () {
    return {
      show: false,
      label: 'Loading...',
      overlay: true,
      roles: this.$store.getters.roles,
      searchData: { orderId: '', type: '', startAt: this.$moment().subtract(6, 'days').format('YYYY-MM-DD'), endAt: this.$moment().format('YYYY-MM-DD') },
      orderInfoModal: false,
      orderInfoData: {},
      orderTypeOptions: [
        { value: '', text: '全部類型' },
        { value: 'storage', text: '保管車輛' },
        { value: 'rent', text: '租借車輛' }
      ],
      editOrderModal: false,
      editData: {},
      orderStatusOptions: [
        { value: 'billing', text: '計費中' },
        { value: 'waitPayment', text: '未付款' },
        { value: 'paid', text: '已付款' },
        { value: 'obsolete', text: '作廢' }
      ],
      BPIDOptions: [],
      columns: [ 'orderId', 'username', 'type', 'orderStatus', 'orderPrice', 'startAt', 'action' ],
      data: [],
      options: {
        headings: {
          'orderId': '訂單編號',
          'username': '用戶帳號',
          'type': '訂單類型',
          'orderStatus': '訂單狀態',
          'orderPrice': '訂單金額',
          'startAt': '訂單成立時間',
          'action': '動作'
        },
        sortable: [ 'orderId', 'username', 'type', 'orderStatus', 'orderPrice', 'startAt' ],
        filterable: ['orderId', 'username', 'type', 'orderStatus', 'orderPrice', 'startAt'],
        sortIcon: {
          base: 'fa',
          is: 'fa-sort',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc'
        }
      }
    }
  },
  mounted: function () {
    this.getBPID()
  },
  methods: {
    getBPID () {
      this.$store.dispatch('getBPID').then(res => {
        this.BPIDOptions = []
        for (let i in res) {
          this.BPIDOptions.push({
            value: res[i].BPID,
            text: res[i].planName
          })
        }
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    },
    getOrderData () {
      this.show = true
      this.$store.dispatch('getOrderData', this.searchData).then((res) => {
        this.data = []
        for (let i in res) {
          res[i].startAt = this.$moment(res[i].startAt).format('YYYY-MM-DD HH:mm:ss')
          res[i].endAt = this.$moment(res[i].endAt).format('YYYY-MM-DD HH:mm:ss')
          this.data.push(res[i])
        }
        this.show = false
      }).catch((e) => {
        this.show = false
        this.$swal('Error', e, 'error')
      })
    },
    orderInfo (prop) {
      this.orderInfoData = prop
      this.orderInfoModal = true
    },
    editOrder (prop) {
      this.editData = prop
      this.editOrderModal = true
    },
    updateOrder () {
      this.$store.dispatch('updateOrder', { OID: this.editData.OID, orderStatus: this.editData.orderStatus, BPID: this.editData.BPID, orderPrice: this.editData.orderPrice }).then(res => {
        this.editOrderModal = false
        this.getOrderData()
        this.$swal('Success', '訂單更新成功', 'success')
      }).catch((e) => {
        this.$swal('Error', e, 'error')
      })
    }
  }
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }
</style>
