import * as warnApi from '@/api/warn'

const bike = {
  state: {

  },
  mutations: {

  },
  actions: {
    getWarnList ({ commit }) {
      return new Promise((resolve, reject) => {
        warnApi.getWarnList().then(response => {
          if (response.code === 2001) {
            resolve(response.data)
          } else {
            reject(response.message)
          }
        })
      })
    }

  }
}

export default bike
